import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Assume you have an action creator for saving goals
import { useModal } from '../../context/Modal';
import { ThunkSetGoals } from '../../store/goals';
import { MakeButton } from '../utils';
import './linkGoalsModal.css'
import { ThunkSetDictionaries } from '../../store/goals';
function LinkGoalsModal() {
    const dispatch = useDispatch();
    const { closeModal } = useModal();
    const [phase,setPhase]=useState('phase1')
    const personalGoals= useSelector((state) => state.goals.personalGoals);
    const groupGoals= useSelector((state) => state.goals.groupGoals);
    const [personalGroupDict,setPersonalGroupDict]=useState({})
    const [GroupPersonalDict,setGroupPersonalDict]= useState({})
    const [personalLocked,setPersonalLocked]= useState([])
    const [groupLocked,setGroupLocked]=useState([])
    const [personalSelected,setPersonalSelected]=useState('')
    const [groupSelected,setGroupSelected]=useState('')

    const handleSubmit = async (e) => {
        e.preventDefault();


        // Assume you have a function to dispatch goal setting action
        // dispatch(setGoals({ typeGoals, standardLimit, highQualLimit, customGoal3Name, customGoal3Limit, customGoal4Name, customGoal4Limit, customGoal5Name, customGoal5Limit }));
        closeModal();
    };

    // const setSoftware = () => {
    //     setTypeGoals("Personal")
    //     setStandardLimit(10);
    //     setHighQualLimit(15)
    //     setCustomGoal3Name('OutReach')
    //     setCustomGoal3Limit(15)
    //     setCustomGoal4Name('DSA')
    //     setCustomGoal4Limit(5)
    //     setCustomGoal5Name('Events')
    //     setCustomGoal5Limit(2)
    // }

    return (
        <>{phase==='phase1'? (<div id='fullLinkGoalsWrapper'>
        <div id='headerLinkGoals'>
            <h1>Link Your Goals</h1>
            </div>
            <div id='explanationBoxWrapper'>
                <div id='explanationBox'>
                    <p>Because we allow custom naming of goals,</p>
                    <p>It's easiest to manually link any connections</p>
                    <p>between your personal goals and any group goals.</p>
                    <br/>
                    <p>Everything could be Connected, or nothing could be connected</p>
                    <p>It depends on your circumstance.</p>
                    <p>When you are ready click NEXT</p>
                </div>

            </div>
            <div id='buttonWrapperLink'>
                <div id='buttonWrapperInner'>
                <MakeButton
                                                        onClick={()=>{setPhase('phase2')}}
                                                        text='Next'
                                                        fontSize='1.4vw'
                                                    />
                </div>
                </div>
            </div>): (
                <div id='fullLinkGoalsWrapper'>
                    <div id='top20'>
                        <div id='center20'>
                            <div id='center201'>
                            <p>Please Select a column from the left</p>
                            <p>Then the corresponding column to the right</p>
                            <p>press "Link" to establish a connection</p>
                            <p>Repeat for all connections, then press submit.</p>
                            </div>
                            <div id='center202'>
                              <div id='center203'>
                                <p>If there are no connections</p>
                                <p>Just Press Submit.</p>
                              </div>
                            </div>
                        </div>
                    </div>


                    <div id='bottomWrapz'>
                    <div id='bottom70'>

                        {personalGroupDict[3] && personalGroupDict[3]=='3' && (

<img id='horTop'src={`${process.env.PUBLIC_URL}/images/arrows-horizontal-thmfy.svg`} />
                        )}
                        {personalGroupDict[3] && personalGroupDict[3]=='4' && (

<img id='diagTop1'src={`${process.env.PUBLIC_URL}/images/arrows-corner-thmfy.svg`} />
                        )}
                        {personalGroupDict[3] && personalGroupDict[3]=='5' && (

<img id='diagTop2'src={`${process.env.PUBLIC_URL}/images/arrows-corner-thmfy.svg`} />
                        )}

{personalGroupDict[4] && personalGroupDict[4]=='3' && (

<img id='diagMid1'src={`${process.env.PUBLIC_URL}/images/arrows-corner-thmfy.svg`} />
                        )}
                        {personalGroupDict[4] && personalGroupDict[4]=='4' && (

<img id='horMid'src={`${process.env.PUBLIC_URL}/images/arrows-horizontal-thmfy.svg`} />
                        )}

{personalGroupDict[4] && personalGroupDict[4]=='5' && (

<img id='diagMid2'src={`${process.env.PUBLIC_URL}/images/arrows-corner-thmfy.svg`} />
                        )}

{personalGroupDict[5] && personalGroupDict[5]=='3' && (

<img id='diagBot1'src={`${process.env.PUBLIC_URL}/images/arrows-corner-thmfy.svg`} />
                        )}
                        {personalGroupDict[5] && personalGroupDict[5]=='4' && (

<img id='diagBot2'src={`${process.env.PUBLIC_URL}/images/arrows-corner-thmfy.svg`} />
                        )}
                        {personalGroupDict[5] && personalGroupDict[5]=='5' && (

<img id='horBot'src={`${process.env.PUBLIC_URL}/images/arrows-horizontal-thmfy.svg`} />
                        )}
                    <div className='oneOfTwoBoxes'>
                    <div className='topIdentifier'>
                    Personal Goals
                    </div>
                    <div onClick={(e)=>{setPersonalSelected("3")}} className={`oneOfIdent ${personalLocked.includes("3")?'lockedMG':personalSelected==="3"?'selectedMG':''}`}>
                        {personalGoals?.customGoal3Name}

                    </div>
                    <div onClick={(e)=>{setPersonalSelected("4")}} className={`oneOfIdent ${personalLocked.includes("4")?'lockedMG':personalSelected==="4"?'selectedMG':''}`}>
                    {personalGoals?.customGoal4Name}

                    </div>
                    <div onClick={(e)=>{setPersonalSelected("5")}} className={`oneOfIdent ${personalLocked.includes("5")?'lockedMG':personalSelected==="5"?'selectedMG':''}`}>
                    {personalGoals?.customGoal5Name}

                    </div>


                    </div>
                    <div className='oneOfTwoBoxes'>
                    <div className='topIdentifier'>
                    Group Goals
                    </div>
                    <div onClick={(e)=>{setGroupSelected("3")}} className={`oneOfIdent ${groupLocked.includes("3")?'lockedMG':groupSelected==="3"?'selectedMG':''}`}>
                        {groupGoals?.customGoal3Name}

                    </div>
                    <div onClick={(e)=>{setGroupSelected("4")}} className={`oneOfIdent ${groupLocked.includes("4")?'lockedMG':groupSelected==="4"?'selectedMG':''}`}>
                    {groupGoals?.customGoal4Name}

                    </div>
                    <div onClick={(e)=>{setGroupSelected("5")}} className={`oneOfIdent ${groupLocked.includes("5")?'lockedMG':groupSelected==="5"?'selectedMG':''}`}>
                    {groupGoals?.customGoal5Name}

                    </div>
                    </div>
                    </div>
                    </div>
                    <div id='bottomButtonzLink'>
                    <div className='w-[20%] h-[100%]'>
                        <MakeButton
                                                        onClick={()=>{
                                                            setPersonalSelected('');
                                                            setGroupSelected('');
                                                            setPersonalLocked([]);
                                                            setGroupLocked([]);

                                                            setPersonalGroupDict({});
                                                            setGroupPersonalDict({});
                                                        }}
                                                        text='Clear'
                                                        fontSize='1.4vw'
                                                    />
                        </div>
                        <div className='w-[20%] h-[100%]'>
                        <MakeButton
                                                        onClick={()=>{
                                                            setPersonalLocked([...personalLocked,personalSelected])
                                                            setGroupLocked([...groupLocked,groupSelected])
                                                            let tempPersGroup= {...personalGroupDict}
                                                            tempPersGroup[personalSelected]=groupSelected
                                                            setPersonalGroupDict(tempPersGroup)
                                                            let tempGroupPers= {...GroupPersonalDict}
                                                            tempGroupPers[groupSelected]=personalSelected
                                                            setGroupPersonalDict(tempGroupPers)

                                                            console.log('OUR PERS TO GROUP DICT LOOKS LIKE',tempPersGroup)
                                                            console.log('our Group to Pers Dict looks like',tempGroupPers)

                                                            setPersonalSelected('')
                                                            setGroupSelected('')
                                                        }}
                                                        text='Link'
                                                        fontSize='1.4vw'
                                                    />
                        </div>
                        <div className='w-[20%] h-[100%]'>

                        <MakeButton
                                                        onClick={async()=>{
                                                            // setPersonalLocked([...personalLocked,personalSelected])
                                                            // setGroupLocked([...groupLocked,groupSelected])
                                                            // let tempPersGroup= {...personalGroupDict}
                                                            // tempPersGroup[personalSelected]=groupSelected
                                                            // setPersonalGroupDict(tempPersGroup)
                                                            // let tempGroupPers= {...GroupPersonalDict}
                                                            // tempGroupPers[groupSelected]=personalSelected
                                                            // setGroupPersonalDict(tempGroupPers)

                                                            // console.log('OUR PERS TO GROUP DICT LOOKS LIKE',tempPersGroup)
                                                            // console.log('our Group to Pers Dict looks like',tempGroupPers)
                                                            await dispatch(ThunkSetDictionaries({GroupPersonalDict,personalGroupDict}))
                                                            setPersonalSelected('')
                                                            setGroupSelected('')
                                                            setGroupLocked([])
                                                            setPersonalLocked([])
                                                            closeModal()
                                                        }}
                                                        text='Submit'
                                                        fontSize='1.4vw'
                                                    />
                        </div>



                    </div>

            </div>
        )}



        </>
    );
}

export default LinkGoalsModal;
