import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Switch from 'react-switch';
import { useDispatch } from 'react-redux';
import { useModal } from '../../context/Modal';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './counterHome.css';
import '../Counter/counter.css';
import { useSharedSetters } from '../../context/SharedSetters';
import { MakeButton } from '../utils';
import { ThunkSendCounter } from '../../store/goals';
class Counter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.initialValue || 0,
            direction: 'increment',
            animationKey: Math.random(),
        };
    }

    increment = () => {
        this.setState((prevState) => ({
            value: prevState.value + 1,
            direction: 'increment',
            animationKey: Math.random(),
        }), () => this.props.onCounterChange(this.state.value));
    };

    decrement = () => {
        this.setState((prevState) => ({
            value: prevState.value - 1,
            direction: 'decrement',
            animationKey: Math.random(),
        }), () => this.props.onCounterChange(this.state.value));
    };

    render() {
        const { title } = this.props;
        const { value, animationKey } = this.state;
        const transitionClassNames = this.state.direction === 'increment' ? 'ctr-increment' : 'ctr-decrement';

        return (
            <>
                <div className="counter">
                    <div className="innerCounter">{title}</div>
                    <div className="outerCounter">
                        <TransitionGroup component="span" className="transitionGroup">
                            <CSSTransition key={animationKey} classNames={transitionClassNames} timeout={{ enter: 300, exit: 500 }}>
                                <span className="counter__value">{value}</span>
                            </CSSTransition>
                        </TransitionGroup>
                    </div>
                </div>
                <div className='botInnerCounter'>
                    <div id='botInnerC1' onClick={this.decrement}>
                        <button>-</button>
                    </div>
                    <div id='botInnerC2' onClick={this.increment}>
                        <button>+</button>
                    </div>
                </div>
            </>
        );
    }
}

function CounterHome() {
    const { selectedGoals, setSelectedGoals } = useSharedSetters()
    const personalGoals = useSelector((state) => state.goals.personalGoals);
    const groupGoals = useSelector((state) => state.goals.groupGoals);
    const goalType = selectedGoals === 'Personal' ? personalGoals : groupGoals;
    const [standardValue, setStandardValue] = useState(goalType.standard ||0);
    const [highQualValue, setHighQualValue] = useState(goalType.highQual || 0);
    const [customGoal3, setCustomGoal3] = useState(goalType.customGoal3 || 0);
    const [customGoal4, setCustomGoal4] = useState(goalType.customGoal4 || 0);
    const [customGoal5, setCustomGoal5] = useState(goalType.customGoal5 || 0);
    const [resetKey, setResetKey] = useState(0);
    const dispatch=useDispatch()

    const handleStandardChange = (newCounterValue) => {
        setStandardValue(newCounterValue);
    };
    const handleHighQualChange = (newCounterValue) => {
        setHighQualValue(newCounterValue);
    };
    const handleCustomGoal3Change = (newCounterValue) => {
        setCustomGoal3(newCounterValue);
    };
    const handleCustomGoal4Change = (newCounterValue) => {
        setCustomGoal4(newCounterValue);
    };
    const handleCustomGoal5Change = (newCounterValue) => {
        setCustomGoal5(newCounterValue);
    };

    const submitCounter=async()=>{
        if ((selectedGoals==='Personal' & !personalGoals?.id) || (selectedGoals==='Group' & !groupGoals?.id)){
            alert('You dont have those goals silly.   go to the dropdown to set them!')
        }
        else if ((customGoal3===null) ||(customGoal4===null) || (customGoal5===null) || (standardValue===null) || highQualValue ===null){
            alert('Something went wrong.')
        }
        else {
        let sendPackage={standardValue,highQualValue,customGoal3,customGoal4,customGoal5,selectedGoals}

        await dispatch(ThunkSendCounter(sendPackage))
    }

    }

    const clearCounters = () => {
        // Reset each counter value to its initial state
        setStandardValue(personalGoals.standard);
        setHighQualValue(personalGoals.highQual);
        setCustomGoal3(personalGoals.customGoal3);
        setCustomGoal4(personalGoals.customGoal4);
        setCustomGoal5(personalGoals.customGoal5);
        setResetKey(prevKey => prevKey + 1);
    };

    return (

        <div id='wrapperCounterHome'>

            <div id='persOrGroup'>
                <div id='smallerPersOrGroup'>
                    <label id='toggleLabel' className={`flex cursor-pointer select-none items-center `}>
                        <div id='toggleLeftSide' className={selectedGoals === "Personal" ? 'personalToggle' : 'notSelectedToggle'}>
                            Personal
                        </div>

                        <div className='relative' id='daddyDiv'>
                            <input
                                type='checkbox'
                                checked={selectedGoals === 'Personal'}
                                onChange={() => setSelectedGoals(selectedGoals === "Personal" ? "Group" : "Personal")}
                                className='sr-only'
                            />
                            <div id='toggleCenter'
                                className={`box block h-20 w-14 rounded-full ${selectedGoals === "Group" ? 'bg-primaryz' : 'bg-darkz'}`}
                            ></div>
                            <div
                                id='ourMover' className={`rounded-full bg-white transition ${selectedGoals === "Group" ? "translate-x-[135%]" : ''}`}
                            ></div>
                        </div>
                        <div id='toggleLeftSide' className={selectedGoals === "Group" ? 'personalToggle' : 'notSelectedToggle'}>
                            Group
                        </div>
                    </label>
                </div>
            </div>

            <div className='chWrapper'>
            {((selectedGoals === 'Personal' && personalGoals?.id) ||
  (selectedGoals === 'Group' && groupGoals?.id))?(
                <div id='chWrapperOuterInner'>
                <div id='chWrapperInner'>

                <div className="App"  key={`standard-${resetKey}`}>
                    <div id='justCounter'>
                        <Counter title="Standard" onCounterChange={handleStandardChange} initialValue={standardValue}/>
                    </div>
                </div>
                <div className="App"  key={`highQual-${resetKey}`}>
                    <div id='justCounter'>
                        <Counter title="High-Quality" onCounterChange={handleHighQualChange} initialValue={highQualValue}/>
                    </div>
                </div>
                <div className="App" key={`customGoal3-${resetKey}`}>
                    <div id='justCounter'>
                        <Counter title={goalType.customGoal3Name} onCounterChange={handleCustomGoal3Change} initialValue={customGoal3}/>
                    </div>
                </div>
                <div className="App" key={`customGoal4-${resetKey}`}>
                    <div id='justCounter'>
                        <Counter title={goalType.customGoal4Name} onCounterChange={handleCustomGoal4Change} initialValue={customGoal4}/>
                    </div>
                </div>
                <div className="App" key={`customGoal5-${resetKey}`}>
                    <div id='justCounter'>
                        <Counter title={goalType.customGoal5Name} onCounterChange={handleCustomGoal5Change} initialValue={customGoal5}/>
                    </div>
                </div>


                </div>
                </div>
               ): (
<div id='errorDiv'>
                    <div id='errorDivInner'>
                        <div id='edi1'>
                    <p>No {selectedGoals} goals found.</p>
                    <p>Feel free to set these</p>
                    <p>from the dropdown menu.</p>
                    </div>
                        </div>
     </div>
                )}
                 <div id='counterHomeButtonWrapper'>
                <div className='chb1'>
                <MakeButton
                                                    onClick={clearCounters}
                                                    text='Clear'
                                                    fontSize='1.2vw'
                                                />

                </div>
                <div className='chb1'>

                <MakeButton
                                                    onClick={submitCounter}
                                                    text='Submit'
                                                    fontSize='1.2vw'
                                                />
                </div>
            </div>

            </div>
        </div>
    );
}

export default CounterHome;
