

let arrayData
let objData


const nameOurCategoryCaps='OFFER'

const nameOfCategory= 'Offer'




const SET_OFFER = `indeeds/SET_${nameOurCategoryCaps}`;
const MAKE_OFFER = `indeeds/MAKE_${nameOurCategoryCaps}`;
const MOVE_OFFER = `indeeds/MOVE_${nameOurCategoryCaps}`
const CLEAR_OFFER = `indeeds/CLEAR_${nameOurCategoryCaps}`;
// Action creators

export const clearOffer = () => ({
    type: CLEAR_OFFER,
});
const getOffers = (incomingData) => {
    const allIds= Object.keys(incomingData)



    return {
    type: SET_OFFER,
    payload: {allIds,incomingData},
}};


export const actionMoveOffer = (incomingData) => {






    return {

    type: MOVE_OFFER,
    payload: incomingData.id,
}};


export const makeOffer = (incomingData)=>{

    return {

        type: MAKE_OFFER,
        payload: {id: incomingData.id,incomingData:incomingData}
    }

}


const initialState = { all_offer: [],offers: {} };





export const ThunkGetOffers = () => async (dispatch) => {
    const response = await fetch('/api/jobs/get-something', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },

        body: JSON.stringify({key:'Offer'})
    });
    if (response.ok) {
        const data = await response.json();
        dispatch(getOffers(data.allDynamics));
    } else {

        console.error('Failed to fetch Indeed jobs');
    }
};

// Reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {

        case SET_OFFER:
            return { ...state, all_offer: action.payload.allIds, offers: action.payload.incomingData };

        case MAKE_OFFER:
            let newState={...state}
            let copy={...newState}
            if (!newState.all_offer.includes(action.payload.id)) {
                copy.all_offer = [action.payload.id,...newState.all_offer];
            }

            copy.offers= {...newState.offers, [action.payload.id]:action.payload.incomingData}
            return copy
        case MOVE_OFFER:
            let newState2={...state}

            let newVar= newState2.all_offer.filter((ele)=>ele!=action.payload)
            newState2.all_offer=newVar
            let newObj= {}
            for (let ele of newState2.all_offer){
                newObj[ele]=newState2.offers[ele]
            }
            newState2.offers=newObj

            return newState2

        case CLEAR_OFFER:
            return { ...initialState };

        default:
            return state;
    }
}
