import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { MainContainer, ChatContainer, MessageList, Message, MessageInput } from '@chatscope/chat-ui-kit-react';
import { useSocket } from "../../context/SocketContext.js";
import { ThunkOnlySendMessage, actionMessageReceived } from '../../store/goals';
import { ThunkGetLiveMessages } from '../../store/goals';
import './chatStyling.css'

const ChatComponent = () => {
    const socket = useSocket();
    const dispatch = useDispatch();
    const messageList = useSelector(state => state.goals.live.messages);
    const sessionUser = useSelector(state => state.session.user);
    const Group_goal = useSelector(state => state.goals.groupGoals);




    useEffect(()=>{
        const fetchAll=async()=>{
            await dispatch(ThunkGetLiveMessages(Group_goal.group_goal_id))
        }
        fetchAll()
    },[dispatch,Group_goal])
    useEffect(() => {
        // Check if socket and group ID are available to setup message listener
        if (socket && Group_goal.group_goal_id) {
            // Function to handle messages received in this room
            const handleMessageReceived = (data) => {
                dispatch(actionMessageReceived(data));
            };

            // Setup listener for messages received
            socket.on('message_received', handleMessageReceived);

            // Cleanup function to remove the message listener
            return () => {
                socket.off('message_received', handleMessageReceived);
            };
        }
    }, [socket, Group_goal.group_goal_id, dispatch]);  // Re-run when groupId changes

    const handleSendMessage = async(messageContent) => {
        const messageData = {
            message: messageContent,
            sentTime: new Date().toISOString(),
            sender: sessionUser.username
        };
        // Emit the message to the server, specifying the current group's ID
        socket.emit("send_message", messageData, Group_goal.group_goal_id);
       let test= await dispatch(ThunkOnlySendMessage({message: messageData,group_goal_id:Group_goal.group_goal_id}))
       console.log('I GUESS WE SENT THE MESSAGE DAWG',test)
    };

    return (
        <div id='chatWrapper'>
            <MainContainer>
                <ChatContainer>

                <MessageList>
    {messageList.map((ele, index) => {
        // Determine if the current element's sender is different from the previous element's sender
        const isDifferentSender = index === 0 || messageList[index].sender !== messageList[index - 1].sender;

        // Assign class based on whether the message is from the session user or not
        const messageClass = ele.sender === sessionUser.username ? 'myMessage' : 'otherMessage';

        return (
            <div key={index} className={`messageWrapper ${messageClass}`}>
                {/* Only render this div if isDifferentSender is true */}
                {isDifferentSender && (
                    <div id={ele.sender === sessionUser.username ? 'headerMessage' : 'headerMessageRec'}>
                        {ele.sender}
                    </div>
                )}
                <Message model={{
                    message: ele.message,
                    sentTime: ele.sentTime,
                    direction: sessionUser.username === ele.sender ? "outgoing" : "incoming",
                    sender: ele.sender
                }} />
            </div>
        );
    })}
</MessageList>



                    <MessageInput placeholder="Type message here" onSend={handleSendMessage}/>
                </ChatContainer>
            </MainContainer>
        </div>
    );
};

export default ChatComponent;
