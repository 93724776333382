import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDrop } from 'react-dnd';
import { MakeButton } from "../utils";
import AppliedCard from "../AppliedCard";
import isMobile from "is-mobile";
// import { ThunkGetWaitings as ThunkGetRejecteds } from "../../store/waiting";
// import { ThunkGetRejecteds as ThunkGetNext_Steps } from "../../store/rejected";
import { ThunkGetNext_Steps } from "../../store/next_step";
const Next_Step = ({ setToggleDetailsMobile, changeCardFromTo }) => {
    const NextStepsBase = useSelector(state => state.next_steps);
    const [update, setUpdate] = useState(false);
    const dispatch = useDispatch();
    const actual = useSelector(state => state.next_steps.next_steps);
    const [, dropRef] = useDrop({
        accept: 'JOB_CARD',
        drop: async (item, monitor) => {
            await changeCardFromTo(item, 'Next_Step');
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    });

    useEffect(() => {
        const fetchData = async () => {
          await dispatch(ThunkGetNext_Steps());
        };
        fetchData();
      }, [dispatch]);

    useEffect(() => {

    }, [update]);

    console.log('INSIDE OF WAITING APPLIED.  IN HERE APPLIED HIGHQUALJOBS IS', NextStepsBase);

    return (

        <>
            <div className='headerEachCat7'>
                <div id={isMobile() ? 'hone' : 'honeMain6'}>

                    <div className='absFolderText'>
              Next Step
            </div>

         <img id='fourthTab' src={`${process.env.PUBLIC_URL}/images/turqoiseTab5.png`}/>


                    </div>
            </div>
            <div id='wrapperNewJobs2' className='scrollable-without-scrollbar'>
                <div ref={dropRef} className="drop-area">
                    {NextStepsBase.all_next_step.length > 0 ? (
                        <ul id='allOurJobs2'>
                            {NextStepsBase.all_next_step.map((job, index) => (
                                <AppliedCard key={job} actual={actual} job={job} setToggleDetailsMobile={setToggleDetailsMobile} source='Next_Step' />
                            ))}
                        </ul>
                    ) : (
                        <p>No jobs found</p>
                    )}
                </div>
            </div>
        </>
    );
};

export default Next_Step;
