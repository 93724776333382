

let arrayData
let objData


const nameOurCategoryCaps='WAITING'

const nameOfCategory= 'Waiting'




const SET_WAITING = `indeeds/SET_${nameOurCategoryCaps}`;
const MAKE_WAITING = `indeeds/MAKE_${nameOurCategoryCaps}`;
const MAKE_WAITING_END = `indeeds/MAKE_WAITINGz_${nameOurCategoryCaps}`;
const MOVE_WAITING = `indeeds/MOVE_${nameOurCategoryCaps}`
const CLEAR_WAITING = `indeeds/CLEAR_${nameOurCategoryCaps}`;
// Action creators
export const clearWaiting = () => ({
    type: CLEAR_WAITING,
});
const getWaitings = (incomingData) => {
    const allIds= Object.keys(incomingData)



    return {
    type: SET_WAITING,
    payload: {allIds,incomingData},
}};


export const actionMoveWaiting = (incomingData) => {






    return {

    type: MOVE_WAITING,
    payload: incomingData.id,
}};
export const actionWhatNow = (incomingData)=>{
    console.log('VERY IMPORTANT AS OF NOW IN ACTION WH AT NOW INCOMING DATA IS',incomingData)

    return {

        type: MAKE_WAITING_END,
        payload: {id: incomingData.new_job.id,job:incomingData.new_job}
    }

}

export const makeWaiting = (incomingData)=>{

    return {

        type: MAKE_WAITING,
        payload: {id: incomingData.id,incomingData:incomingData}
    }

}


const initialState = { all_waiting: [],waitings: {} };


export const ThunkMakeWaiting = (waitingInfo) => async (dispatch) => {
    let data;
    try {
        const response = await fetch("/api/interact/make-local-waiting", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(waitingInfo),
        });

        data = await response.json(); // Always parse the response to JSON

        if (!response.ok) {
            // Optionally, handle the error status appropriately.
            // For example, you might want to dispatch a different action depending on the error.
            // Dispatch an error action or update the state with the error received from the server.
            console.error("Server-side error:", data);
        } else {
            // Dispatch the action with the received data
            dispatch(actionWhatNow(data));
        }
    } catch (error) {
        // This catch block is for catching exceptions thrown from fetch or JSON parsing.
        // You could consider having a more generic error structure here if you want to update the UI accordingly.
        console.error("Error fetching data:", error);
        data = { error: "An unexpected error occurred. Please try again." };
    }

    return data; // Return the data or error information.
};


export const ThunkGetWaitings = () => async (dispatch) => {
    const response = await fetch('/api/jobs/get-something', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },

        body: JSON.stringify({key:'Waiting'})
    });
    if (response.ok) {
        const data = await response.json();
        dispatch(getWaitings(data.allDynamics));
    } else {

        console.error('Failed to fetch Indeed jobs');
    }
};

// Reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {

        case SET_WAITING:
            return { ...state, all_waiting: action.payload.allIds, waitings: action.payload.incomingData };
            // const initialState = { all_waiting: [],waitings: {} };
        case MAKE_WAITING_END:
            let {id,job}=action.payload
            let newState4={...state}
            let copy_all= [id,...newState4.all_waiting]
            let copy_waitings= newState4.waitings
            copy_waitings[id]=job
            newState4.all_waiting=copy_all
            newState4.waitings=copy_waitings

            return newState4
        case MAKE_WAITING:
            let newState={...state}
            let copy={...newState}
            if (!newState.all_waiting.includes(action.payload.id)) {
                copy.all_waiting = [action.payload.id,...newState.all_waiting];
            }

            copy.waitings= {...newState.waitings, [action.payload.id]:action.payload.incomingData}
            return copy
        case MOVE_WAITING:
            let newState2={...state}

            let newVar= newState2.all_waiting.filter((ele)=>ele!=action.payload)
            newState2.all_waiting=newVar
            let newObj= {}
            for (let ele of newState2.all_waiting){
                newObj[ele]=newState2.waitings[ele]
            }
            newState2.waitings=newObj

            return newState2

        case CLEAR_WAITING:
            return { ...initialState };

        default:
            return state;
    }
}
