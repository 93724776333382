// constants
const SET_HIGHQUALS = 'indeeds/SET_HIGHQUAL';
const MAKE_HIGHQUAL = 'indeeds/MAKE_HIGHQUAL';
const MOVE_HIGHQUAL = 'indeeds/MOVE_HIGHQUAL'
const CLEAR_HIGHQUALS = 'indeeds/CLEAR_HIGHQUALS';

// Action creators
export const clearHighQuals = () => ({
    type: CLEAR_HIGHQUALS,
});
const getHighQuals = (incomingData) => {
    const allIds= Object.keys(incomingData)



    return {
    type: SET_HIGHQUALS,
    payload: {allIds,highQualIncoming: incomingData},
}};

export const actionMoveHighQual = (incomingData) => {
    // const allIds= Object.keys(jobData)





    return {
    type: MOVE_HIGHQUAL,
    payload: incomingData.id,
}};

export const makeHighQual = (incomingData)=>{

    return {
        type: MAKE_HIGHQUAL,
        payload: {id: incomingData.id,incomingData:incomingData}
    }

}

const initialState = { allHighQuals: [],highQuals: {} };




export const ThunkGetHighQuals = () => async (dispatch) => {
    const response = await fetch('/api/jobs/get-something', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        //key will always be one of the following
        //['Kwey','Standard', 'High-Quality',"Waiting",'Rejected', 'Next Step', 'Offer Accepted']
        body: JSON.stringify({key:'High-Quality'})
    });
    if (response.ok) {
        const data = await response.json();
        dispatch(getHighQuals(data.allDynamics));
    } else {

        console.error('Failed to fetch Indeed jobs');
    }
};

// Reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_HIGHQUALS:
            return { ...state, allHighQuals: action.payload.allIds, highQuals: action.payload.highQualIncoming };
        case MAKE_HIGHQUAL:
            let newState={...state}
            let copy={...newState}
            if (!newState.allHighQuals.includes(action.payload.id)) {
                copy.allHighQuals = [action.payload.id,...newState.allHighQuals];
            }
            copy.highQuals= {...newState.highQuals, [action.payload.id]:action.payload.incomingData}
            return copy
        case MOVE_HIGHQUAL:
            let newState2={...state}
            let newVar= newState2.allHighQuals.filter((ele)=>ele!=action.payload)
            newState2.allHighQuals=newVar
            let newObj= {}

            for (let ele of newState2.allHighQuals){
                newObj[ele]=newState2.highQuals[ele]
            }
            newState2.highQuals=newObj

            return newState2

        case CLEAR_HIGHQUALS:
                return { ...initialState };

        default:
            return state;
    }
}
