
const showDetails = (job) => {

    //  const ulAllJobs=document.querySelector('#allOurJobs')
    //  ulAllJobs.style.display='none'



    const allJobCards = document.querySelectorAll('.newJobCard')

    for (let ele of allJobCards) {
        ele.style.display = 'none'
    }

    const particularJobCard = document.querySelector(`.newJobCard.newJobCard-${job}`)
    const children = particularJobCard.children;

    for (let ele of children) {
        ele.style.display = 'none'
    }

    const ourGood1 = particularJobCard.querySelector('.detailsNew')
    ourGood1.style.display = 'flex'
    const titleJob = particularJobCard.querySelector('.titleJob')
    titleJob.style.display = 'none'
    particularJobCard.style.display = 'flex'

    const ourDetails = document.querySelector(`.detailsNew-${job}`)
    ourDetails.style.display = 'flex'
    ourDetails.style.width = '100%'
    ourDetails.style.height = '100%'

}


const showDetailsDesktop = (job) => {

    //  const ulAllJobs=document.querySelector('#allOurJobs')
    //  ulAllJobs.style.display='none'



    // const allJobCards = document.querySelectorAll('.newJobCard')

    // for (let ele of allJobCards) {
    //     ele.style.display = 'none'
    // }

    const particularJobCard = document.querySelector(`.newJobCard.newJobCard-${job}`)
    const children = particularJobCard.children;

    // for (let ele of children) {
    //     ele.style.display = 'none'
    // }

    const ourGood1 = particularJobCard.querySelector('.detailsNew')
    const ourGood1Contents= ourGood1.innerText
    // const titleJob = particularJobCard.querySelector('.titleJob')
    // titleJob.style.display = 'none'
    // particularJobCard.style.display = 'flex'

    const divToGrab= document.querySelector('#tbiAll')
    divToGrab.style.display='none'

    const divToAppendTo=document.querySelector('#topBlockInfo')
    divToAppendTo.innerText=ourGood1Contents;

    // const ourDetails = document.querySelector(`.detailsNew-${job}`)
    // ourDetails.style.display = 'flex'
    // ourDetails.style.width = '100%'
    // ourDetails.style.height = '100%'

}



const restoreNormal = () => {
    // Uncomment and adjust if you want to restore the #allOurJobs element
    // const ulAllJobs = document.querySelector('#allOurJobs');
    // ulAllJobs.style.display = ''; // Assuming the default was not 'none'

    const allJobCards = document.querySelectorAll('.newJobCard');
    for (let ele of allJobCards) {
        ele.style.display = 'block'; // Resetting to default, which commonly is 'block' or ''
        // Assuming you want to reset all children's display property as well
        const children = ele.children;
        for (let child of children) {
            child.style.display = 'flex'; // Reset to default
        }
    }

    // Assuming .detailsNew elements were the only ones specifically set to 'flex'
    const allDetailsNew = document.querySelectorAll('.detailsNew');
    for (let detail of allDetailsNew) {
        detail.style.display = 'none'; // Reset to default
        // Reset any other styles you may have changed
        // detail.style.width = '';
        // detail.style.height = '';
    }

    // If you had multiple .detailsNew-{index} elements and they were all modified
    // const allIndexedDetailsNew = document.querySelectorAll('[class^="detailsNew-"]');
    // for (let detail of allIndexedDetailsNew) {
    //     detail.style.display = ''; // Reset to default
    //     detail.style.width = '';
    //     detail.style.height = '';
    // }

    // Add any additional resets for other elements or styles modified in showDetails
};




export { showDetails, restoreNormal,showDetailsDesktop }
