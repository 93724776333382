import React, { useState, useEffect } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import './SlotMachine.css';
import { useSharedSetters } from '../../context/SharedSetters';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

const SlotMachine = ({ options }) => {
  // const [currentIndex, updateCurrentIndex] = useState(0);
  const { viewOfSite,updateViewOfSite,calendarView,updateCalendarView,overlay,updateOverlay,currentIndex,updateCurrentIndex,raceView,updateRaceView } = useSharedSetters();
  const history = useHistory();
  const sessionUser = useSelector(state => state.session.user);

  //THIS IS THE VERSION WITH SITE COMING FIRST
  // useEffect(() => {
  //   const transitionEffects = async () => {
  //     const selectedOption = options[currentIndex];

  //     if (selectedOption === 'Calendar' && !calendarView) {
  //       // Activate overlay when switching to Calendar
  //       updateOverlay(true);

  //       await delay(200);
  //       updateCalendarView(true)
  //       updateOverlay(false);
  //       await delay(500);

  //       // await delay(500); // Wait for 2 seconds
  //        // Now hide the overlay
  //       ; // Update view to Calendar here
  //     } else if (selectedOption !== 'Calendar' && calendarView) {
  //       // Handle switching off of Calendar
  //       updateCalendarView(false); // Make sure to update this state as needed when leaving Calendar
  //     } else if (selectedOption === 'Counter' && viewOfSite !== "Counter") {
  //       updateViewOfSite("Counter");
  //     } else if (selectedOption === 'Site') {

  //       updateViewOfSite("site");
  //       updateCalendarView(false);
  //     }
  //   };

  //   transitionEffects().catch(console.error); // Execute the async function within useEffect
  // }, [currentIndex, options, calendarView, updateCalendarView, viewOfSite, updateViewOfSite, updateOverlay, overlay]);


  useEffect(() => {
    const transitionEffects = async () => {
      const selectedOption = options[currentIndex];

      if (selectedOption === 'Calendar' && !calendarView) {
        // Activate overlay when switching to Calendar
        updateRaceView(false)
        updateOverlay(true);

        await delay(200);
        updateCalendarView(true)
        updateOverlay(false);
        await delay(500);

        // await delay(500); // Wait for 2 seconds
         // Now hide the overlay
        ; // Update view to Calendar here
      } else if (selectedOption !== 'Calendar' && calendarView) {
        // Handle switching off of Calendar
        updateCalendarView(false); // Make sure to update this state as needed when leaving Calendar
      } else if (selectedOption === 'Counter' && viewOfSite !== "Counter") {
        updateViewOfSite("Counter");
      }
      else if (selectedOption ==='Race' && !raceView){
        updateRaceView(true)
      }


      // else if (selectedOption === 'Site') {

      //   updateViewOfSite("site");
      //   updateCalendarView(false);
      // }
    };

    transitionEffects().catch(console.error); // Execute the async function within useEffect
  }, [currentIndex, options, calendarView, updateCalendarView, viewOfSite, updateViewOfSite, updateOverlay, overlay]);
  const handleClick = () => {
    updateCurrentIndex(prevIndex => (prevIndex + 1) % options.length);
  };

  return (
    <div className="slot-machine" onClick={handleClick}>
      <TransitionGroup className="transition-group">
        <CSSTransition
          key={options[currentIndex]}
          timeout={500}
          classNames="slide"
        >
          <div className="option">
            {options[currentIndex]}
          </div>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default SlotMachine;
