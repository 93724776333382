import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/session";
import OpenModalButton from "../OpenModalButton";
import LoginFormModal from "../LoginFormModal";
import SignupFormModal from "../SignupFormModal";
import GoalSettingModal from "../SetGoalsModal";
import LinkGoalsModal from "../LinkGoalsModal";
import './Navigation.css'
import UseFriendsGoals from "../addFriendsGoals";
import RaceToFinish from "../RaceToFinish";
import EditGoals from "../EditGoals";
import { useModal } from "../../context/Modal";
import { useSharedSetters } from '../../context/SharedSetters';
import { clearGoals } from "../../store/goals";
import { clearHighQuals } from "../../store/highqualApps";
import { clearJobs } from "../../store/jobs";
import { clearNext_Step } from "../../store/next_step";
import { clearOffer } from "../../store/offer";
import { clearRejected } from "../../store/rejected";
import { clearStandard } from "../../store/standardApps";
import { clearWaiting } from "../../store/waiting";
import { clearCalendar } from "../../store/calendar";
import PublicGoals from "../PublicGoals";

function ProfileButton({ user }) {
  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState(false);
  const groups = useSelector((state) => state.goals)
  const personalGoals = useSelector((state) => state.goals.personalGoals)
  const groupGoals = useSelector((state) => state.goals.groupGoals)
  const { personalToGroup, groupToPersonal } = groups;
  const { viewOfSite, updateViewOfSite, calendarView, updateCalendarView, overlay, updateOverlay, updateCurrentIndex,raceView,updateRaceView,audio,updateAudio } = useSharedSetters();
  const sessionUser = useSelector(state => state.session.user)
  const ulRef = useRef();
  const { setModalContent, setModalStyle } = useModal()
  const isEmpty = (obj) => Object.keys(obj).length === 0 && obj.constructor === Object;

  const openMenu = () => {
    if (showMenu) return;
    setShowMenu(true);
  };

  useEffect(() => {
    if (!showMenu) return;

    const closeMenu = (e) => {
      if (!ulRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener("click", closeMenu);

    return () => document.removeEventListener("click", closeMenu);
  }, [showMenu]);

  const handleLogout = (e) => {
    e.preventDefault();

    dispatch(logout());
    dispatch(clearGoals());
    dispatch(clearHighQuals());
    dispatch(clearJobs());
    dispatch(clearNext_Step());
    dispatch(clearOffer());
    dispatch(clearRejected());
    dispatch(clearStandard());
    dispatch(clearWaiting());
    dispatch(clearCalendar());
    updateCalendarView(false)
    updateRaceView(false)
    updateViewOfSite('Counter')
    updateCurrentIndex(0)
  };

  const ulClassName = "profile-dropdown" + (showMenu ? "" : " hidden");
  const closeMenu = () => setShowMenu(false);

  console.log('IN OUR NAVIGATION COMPONENT Groups IS', groups)

  return (
    <>
      <div className='w-[40%] h-full'></div>
      <button onClick={openMenu} id='tryer'>
        <img id='dogButton' src={`${process.env.PUBLIC_URL}/images/cuteDogCircle.png`} />
      </button>
      <ul id='forAlexTG' className={ulClassName} ref={ulRef}>
        <div id="backgroundContainer">

          <img id='bgFile9' src={`${process.env.PUBLIC_URL}/images/clipboardForSite.png`} />
        </div>
        {user ? (
          <>
          <li>
            <div id='containerUnmuted'>
            <img id='unmuted'
            src={audio?`${process.env.PUBLIC_URL}/images/volume-high-ionGREEN.png`:`${process.env.PUBLIC_URL}/images/volume-mute-ionGREENred.png`}
            onClick={(e)=>{
              if (audio){
                updateAudio(false)
              }
              else {
                updateAudio(true)}
            }}
            alt="Unmuted" /></div>

            </li>
            <li>{user.username}</li>
            {/* <li>{user.email}</li> */}

            {!personalGoals?.id && (
              <li onClick={(e) => { closeMenu() }}>

                <OpenModalButton
                  buttonText="Set Personal Goals"
                  onItemClick={closeMenu}
                  modalComponent={<GoalSettingModal formType={'Personal'} />}
                /></li>


            )}
            {personalGoals?.id && !groupGoals?.id && (
              <>
                <li onClick={(e) => { closeMenu() }}>

                  <OpenModalButton
                    buttonText="Set Group Goals"
                    onItemClick={closeMenu}
                    modalComponent={<GoalSettingModal formType={'Group'} setOrEdit='Set' />}
                    modalStyle={{ backgroundColor: 'var(--cGray)' }}

                  /></li>
                  <li onClick={(e) => { closeMenu() }}>

<OpenModalButton
  buttonText="Add Public Goals"
  onItemClick={closeMenu}
  modalComponent={<PublicGoals />}
  modalStyle={{ backgroundColor: 'var(--cGray)' }}

/></li>
                <li onClick={(e) => { closeMenu() }}>
                  <OpenModalButton
                    buttonText="Add Friends Goals"
                    onItemClick={closeMenu}
                    modalComponent={<UseFriendsGoals />}
                    modalStyle={{ width: '44%', height: '53%', overflow: 'auto', borderRadius: '2em' }} />
                </li></>)}

            {(personalGoals?.id || groupGoals?.id) && (
              <li onClick={(e) => { closeMenu() }}>
                <OpenModalButton
                  buttonText="Edit/Delete Goals"
                  onItemClick={closeMenu}
                  modalComponent={<EditGoals />}
                  modalStyle={{ width: '77%', height: '94%', overflow: 'auto', borderRadius: '2em' }}
                /></li>
            )}

            {/* {groupGoals?.id && (
              <li onClick={(e) => { closeMenu() }}>
                <OpenModalButton
                  buttonText="View Race"
                  onItemClick={closeMenu}
                  modalComponent={<RaceToFinish />}
                  modalStyle={{ width: '77%', height: '94%', overflow: 'auto', borderRadius: '2em' }}
                /></li>


            )} */}


            {groups?.groupGoals?.user_id && (!personalToGroup || isEmpty(personalToGroup)) && (
              <li onClick={(e) => { closeMenu() }}>
                <OpenModalButton
                  buttonText="Link Goals"
                  onItemClick={closeMenu}
                  modalComponent={<LinkGoalsModal />}
                /></li>
            )}

            {groupGoals?.id && (groupGoals?.id === groupGoals.group_goal_id) && (
              <li>
                Share Id: {groupGoals.id}
              </li>
            )}

            <li>
              <button onClick={handleLogout}>Log Out</button>
            </li>
          </>
        ) : (
          <>
            <li onClick={(e) => { closeMenu() }}>
              <OpenModalButton
                buttonText="Log In"
                onItemClick={closeMenu}
                modalComponent={<LoginFormModal />}
                modalStyle={{ width: '44%', height: '53%', overflow: 'auto', borderRadius: '2em' }}
              />
            </li>
            <li onClick={(e) => { closeMenu() }}>
              <OpenModalButton
                buttonText="Sign Up"
                onItemClick={closeMenu}
                modalComponent={<SignupFormModal />}
              />
            </li>
            <li>

            </li>
          </>
        )}
      </ul>
    </>
  );
}

export default ProfileButton;
