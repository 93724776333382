import React, { useState } from "react";
import { login } from "../../store/session";
import { useDispatch } from "react-redux";
import { useModal } from "../../context/Modal";
import GoogleAuthButton from "../GoogleAuthButton";
import { MakeButton } from "../utils";
import ErrorsModal from "../ErrorsModal";
import "./LoginForm.css";

function LoginFormModal() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const { closeModal } = useModal();
  const [waiting, setWaiting] = useState(false);


  const validate = () => {
    let validationErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || !emailRegex.test(email) || email.length > 69) {
      validationErrors.email = "Please enter a valid email address under 70 characters.";
    }

    if (password.length < 6 || password.length > 64) {
      validationErrors.password = "Password must be 6-64 characters long.";
    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
};

  const handleSubmit = async (e) => {
    // e.preventDefault();
    if (!validate()) return;
    const data = await dispatch(login(email, password));
    console.log('DATA ISSSSSSSS',data)
    if (data.errors) {
      setErrors(data.errors);
    } else {
      setErrors({})
      closeModal();
    }
  };

  const handleDemoUserClick = async () => {
    setEmail("demo@aa.io");
    setPassword("password");

    setWaiting(true);
    const data = await dispatch(login("demo@aa.io", "password"));
    setWaiting(false);
    if (data.errors) {
      setErrors(data.errors);
    } else {
      setErrors({})
      closeModal();
    }
  };

  return (

    <div id="loginWrapper">
      {Object.keys(errors).length >= 1 && (
      <ErrorsModal errors={errors} setErrors={setErrors} formType={"Login"}   />)}
      {waiting && (
                <img id='isLoading2' src={`${process.env.PUBLIC_URL}/images/loading.gif`} />
            )}
      <div id='loginHeader'>Log In</div>
      <div id='horizontalLoginWrapper'>
        <div id='storeyTime'>
        <img id='ospace' src={`${process.env.PUBLIC_URL}/images/ospace.gif`} />
        </div>
      <form onSubmit={handleSubmit} id='zach'>
        <ul>
          {/* {errors.map((error, idx) => (
            <li key={idx}>{error}</li>
          ))} */}
        </ul>
        <div className='loginFormz'>
        <label>
          Email</label>
          <div className='loginFormz2'>
          <input
            type="text"
            id='lastCall'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          </div>

        </div>

        <div className='loginFormz'>
        <label>
          Password </label>
          <div className='loginFormz2'>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          </div>
        </div>
        </form>
        </div>
        <div id='loginButtonz'>
            <div className='w-[30%] h-full'>
        <MakeButton
                                                        onClick={(e)=>{
                                                          handleSubmit()
                                                        }}
                                                        text='Log In'
                                                        fontSize='1vw'
                                                    />
                                                    </div>
        {/* <button type="submit">Log In</button> */}
        <div className='lemonVape'>
        <GoogleAuthButton setWaiting={setWaiting} signUp={true} /></div>
        {/* <div className='w-[30%] h-full'>

        <MakeButton
                                                        onClick={handleDemoUserClick}
                                                        text='Demo User'
                                                        fontSize='1vw'
                                                    />


        </div> */}

        </div>


      </div>

  );
}

export default LoginFormModal;
