

let arrayData
let objData


const nameOurCategoryCaps='NEXT_STEP'

const nameOfCategory= 'Next_Step'




const SET_NEXT_STEP = `indeeds/SET_${nameOurCategoryCaps}`;
const MAKE_NEXT_STEP = `indeeds/MAKE_${nameOurCategoryCaps}`;
const MOVE_NEXT_STEP = `indeeds/MOVE_${nameOurCategoryCaps}`
const CLEAR_NEXT_STEP = `indeeds/CLEAR_${nameOurCategoryCaps}`;
// Action creators
const getNext_steps = (incomingData) => {
    const allIds= Object.keys(incomingData)



    return {
    type: SET_NEXT_STEP,
    payload: {allIds,incomingData},
}};

export const clearNext_Step = () => ({
    type: CLEAR_NEXT_STEP,
});
export const actionMoveNext_Step = (incomingData) => {






    return {

    type: MOVE_NEXT_STEP,
    payload: incomingData.id,
}};


export const makeNext_Step = (incomingData)=>{

    return {

        type: MAKE_NEXT_STEP,
        payload: {id: incomingData.id,incomingData:incomingData}
    }

}


const initialState = { all_next_step: [],next_steps: {} };




export const ThunkGetNext_Steps = () => async (dispatch) => {
    const response = await fetch('/api/jobs/get-something', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },

        body: JSON.stringify({key:'Next_Step'})
    });
    if (response.ok) {
        const data = await response.json();
        dispatch(getNext_steps(data.allDynamics));
    } else {

        console.error('Failed to fetch Indeed jobs');
    }
};

// Reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {

        case SET_NEXT_STEP:
            return { ...state, all_next_step: action.payload.allIds, next_steps: action.payload.incomingData };

        case MAKE_NEXT_STEP:
            let newState={...state}
            let copy={...newState}
            if (!newState.all_next_step.includes(action.payload.id)) {
                copy.all_next_step = [action.payload.id,...newState.all_next_step];
            }

            copy.next_steps= {...newState.next_steps, [action.payload.id]:action.payload.incomingData}
            return copy
        case MOVE_NEXT_STEP:
            let newState2={...state}

            let newVar= newState2.all_next_step.filter((ele)=>ele!=action.payload)
            newState2.all_next_step=newVar
            let newObj= {}
            for (let ele of newState2.all_next_step){
                newObj[ele]=newState2.next_steps[ele]
            }
            newState2.next_steps=newObj

            return newState2
        case CLEAR_NEXT_STEP:
                return { ...initialState };

        default:
            return state;
    }
}
