import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useModal } from '../../context/Modal';
import { ThunkSetGoals } from '../../store/goals';
import { MakeButton } from '../utils';
import './makeEvent.css'
import { ThunkNewCalendarEvent,actionMakeNewEventLocalRedux } from '../../store/calendar';

function NewEvent({ all_calendars, name_to_id }) {
    const dispatch = useDispatch();
    const { closeModal } = useModal();

    const [eventName, setEventName] = useState('');
    const [eventDescription, setEventDescription] = useState('');
    const [eventLocation, setEventLocation] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [timeZone, setTimeZone] = useState(''); // Added useState for timeZone
    const [selectedCalendar, setSelectedCalendar] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        const eventStartDateTime = `${startDate}T${startTime}:00-07:00`;
        const eventEndDateTime = `${endDate}T${endTime}:00-07:00`;

        const event = {
            start: {
                dateTime: eventStartDateTime,
                timeZone: timeZone,
            },
            end: {
                dateTime: eventEndDateTime,
                timeZone: timeZone,
            },
        };

        const reduxEvent=  {
            start: eventStartDateTime,
            end:  eventEndDateTime,
        };

        if (eventName) {event['summary'] = eventName;
                        reduxEvent['title']=eventName}

        if (eventDescription) event['description'] = eventDescription;
        if (eventLocation) event['location'] = eventLocation;

        const ourId= name_to_id[selectedCalendar]

        await dispatch(ThunkNewCalendarEvent({ event, ourId, reduxEvent, selectedCalendar }))
        // dispatch(actionMakeNewEventLocalRedux({reduxEvent,selectedCalendar}))
        closeModal();
    };

    const clearFields = () => {
        setEventName('');
        setEventDescription('');
        setEventLocation('');
        setStartDate('');
        setEndDate('');
        setStartTime('');
        setEndTime('');
        setTimeZone(''); // Clear timezone
        setSelectedCalendar('')
    };

    return (
        <>
            <div id='headerSetGoalsME'>
                New Event
            </div>
            <div id='requiredME'>Required:</div>

            <form id='ourFormME' onSubmit={handleSubmit}>
            <div className='taylorFormME'>
                    <label>Calendar:</label>
                    <select value={selectedCalendar} onChange={(e) => setSelectedCalendar(e.target.value)} className='calendarPick'>
                        <option value="">Select a Calendar</option>
                        {all_calendars.map((cal, index) => (
                            <option key={index} value={cal}>{cal}</option>
                        ))}
                    </select>
                </div>
            <div className='taylorFormME'>
                    <label>Timezone:</label>
                    <select value={timeZone} onChange={(e) => setTimeZone(e.target.value)} className='timeZonePick'>
        <option value="">Select a Timezone</option>
        <option value="America/New_York">Eastern Time (ET)</option>
        <option value="America/Chicago">Central Time (CT)</option>
        <option value="America/Denver">Mountain Time (MT)</option>
        <option value="America/Phoenix">Mountain Time (Arizona, no DST)</option>
        <option value="America/Los_Angeles">Pacific Time (PT)</option>
        <option value="Europe/London">Europe/London</option>
        <option value="Asia/Tokyo">Asia/Tokyo</option>
    </select>
                </div>
                <div className='taylorFormME'>
                    <label>Start Date:</label>
                    <input type="date" className='datesPick' value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                </div>
                <div className='taylorFormME'>
                    <label>End Date:</label>
                    <input type="date" className='datesPick' value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                </div>
                <div className='taylorFormME'>
                    <label>Start Time:</label>
                    <input type="time" className='timePick' value={startTime} onChange={(e) => setStartTime(e.target.value)} />
                </div>
                <div className='taylorFormME'>
                    <label>End Time:</label>
                    <input type="time" className='timePick' value={endTime} onChange={(e) => setEndTime(e.target.value)} />
                </div>
                <div id='requiredME'>Optional:</div>
                <div className='taylorFormME'>
                    <label>Name of Event:</label>
                    <input type="text" value={eventName} onChange={(e) => setEventName(e.target.value)} />
                </div>
                <div className='taylorFormME'>
                    <label>Description:</label>
                    <input type="text" value={eventDescription} onChange={(e) => setEventDescription(e.target.value)} />
                </div>
                <div className='taylorFormME'>
                    <label>Location:</label>
                    <input type="text" value={eventLocation} onChange={(e) => setEventLocation(e.target.value)} />
                </div>

                <div id='bottleOfFormME'>
                    <div id='customButton2ME'>
                        <MakeButton onClick={clearFields} text='clear' fontSize='1.4vw' />
                    </div>
                    <div id='customButton2ME'>
                        <MakeButton type='Submit' text='Submit' fontSize='1.4vw' />
                    </div>
                </div>
            </form>
        </>
    );
}

export default NewEvent;
