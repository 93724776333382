
import React, { createContext, useContext, useState } from 'react';

const SharedSetters = createContext();

export const useSharedSetters = () => useContext(SharedSetters);

export const SharedSettersContextProvider = ({ children }) => {
    const [newGroupMade, setNewGroupMade] = useState(false);
    const [viewOfSite, setViewOfSite] = useState('site');
    const [selectedGoals,updateSelectedGoals] = useState('Personal')
    const [calendarView,setCalendarView]= useState(false)
    const [overlay,setOverlay]=useState(false)
    const [currentIndex, setCurrentIndex] = useState(0);
    const [raceView,setRaceView] = useState(false)
    const [audio,setAudio] =useState(false)

    const updateAudio = newValue => {
        setAudio(newValue);
    };
    const updateRaceView = newValue => {
        setRaceView(newValue);
    };
    const updateCurrentIndex = newValue => {
        setCurrentIndex(newValue);
    };
    const updateOverlay = newValue => {
        setOverlay(newValue);
    };
    const updateCalendarView = newValue => {
        setCalendarView(newValue);
    };
    const updateNewGroupMade = newValue => {
        setNewGroupMade(newValue);
    };
    const updateViewOfSite = newValue => { // Added update function for viewOfSite
        setViewOfSite(newValue);
    };
    const setSelectedGoals = newValue => { // Added update function for viewOfSite
        updateSelectedGoals(newValue);
    };


    // Add more update functions as needed

    return (
        <SharedSetters.Provider value={{ newGroupMade, updateNewGroupMade,viewOfSite,updateViewOfSite,selectedGoals,setSelectedGoals,calendarView,updateCalendarView,overlay,updateOverlay,currentIndex,updateCurrentIndex,raceView,updateRaceView,audio,updateAudio }}>
            {children}
        </SharedSetters.Provider>
    );
};
