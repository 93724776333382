import React, { useState, useEffect } from 'react';
import './timercss.css'
function TimeDisplay() {
  // Initial time set to 111 minutes and 0 seconds
  const [time, setTime] = useState({
    minutes: 111,
    seconds: 0,
  });

  // Function to increment time by one second
  const tick = () => {
    setTime(({ minutes, seconds }) => {
      if (seconds === 59) {
        return { minutes: minutes + 1, seconds: 0 };
      }
      return { minutes, seconds: seconds + 1 };
    });
  };

  // Effect hook to set up an interval
  useEffect(() => {
    const timerId = setInterval(tick, 1000); // Increment every second

    // Clean up the interval on component unmount
    return () => clearInterval(timerId);
  }, []);

  // Format time for display
  const formatTime = ({ minutes, seconds }) => {
    return `${String(minutes).padStart(3, '0')} ${String(seconds).padStart(2, '0')}`;
  };

  return (
    <>
    <div className='mainWrapperzy'>
    <div className='wrapperzy'>

        <div id='timeBox1'>
      <div id='timebox1inner'>{formatTime(time)}</div>
      </div>

    </div>
    <div className='wrapperzy'>

    <div id='timeBox2'>
  <h2>{formatTime(time)}</h2>
  </div>
</div>
</div>
</>
  );
}

export default TimeDisplay;
