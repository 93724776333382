import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useModal } from "../../context/Modal";
import { signUp } from "../../store/session";
import GoogleAuthButton from "../GoogleAuthButton";
import { MakeButton } from "../utils";
import ErrorsModal from "../ErrorsModal";

import "./SignupForm.css";

function SignupFormModal() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const { closeModal } = useModal();
  const [waiting, setWaiting] = useState(false);
  const [googleOrTrad,setGoogleOrTrad]=useState('Google')

  const validate = () => {
    let validationErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || !emailRegex.test(email) || email.length > 69) {
      validationErrors.email = "Please enter a valid email address under 70 characters.";
    }
    if (username.length < 4 || username.length > 20) {
      validationErrors.username = "Username must be 4-20 characters long.";
    }
    if (password.length < 6 || password.length > 64) {
      validationErrors.password = "Password must be 6-64 characters long.";
    }
    if (password !== confirmPassword) {
      validationErrors.confirmPassword = "Passwords must match.";
    }
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
};

  const handleSubmit = async (e) => {
    // e.preventDefault();
    if (!validate()) return;
    const data = await dispatch(signUp(username, email, password));
    console.log('IN OUR SIGNUP FORM MODAL DATA IS',data)
    if (data.errors) {
      setErrors(data.errors);
    } else {
      closeModal();
    }
  };

  return (
    <div id="loginWrapperXL">
      {Object.keys(errors).length >= 1 && (
        <ErrorsModal errors={errors} setErrors={setErrors} formType={"Signup"} />
      )}
      {waiting && (
        <img id='isLoading2XL' src={`${process.env.PUBLIC_URL}/images/loading.gif`} />
      )}
      {/* <div id='loginHeaderXL'>Sign Up</div> */}
	  {/* <div id="signupExplain">
		<div id='sue1'>
			<p>Sign Up Traditionally,</p>
			<p>Or sign up with Google.</p>
			<p id='specSue'>Note: Signing Up with google will allow you to interact with your calendars</p>
		</div>
	  </div> */}
	  <div id='persOrGroupXL'>
    <div id='smallerPersOrGroupXL'>
        <label id='toggleLabelXL' className={`flex cursor-pointer select-none items-center `}>
            <div id='toggleLeftSideXL' className={googleOrTrad === "Google" ? 'personalToggleXL' : 'notSelectedToggleXL'}>
                Google
            </div>

            <div className='relative' id='daddyDivXL'>
                <input
                    type='checkbox'
                    checked={googleOrTrad === 'Google'}
                    onChange={() => setGoogleOrTrad(googleOrTrad === "Google" ? "Trad" : "Google")}
                    className='sr-onlyXL'
                />
                <div id='toggleCenterXL'
                    className={`box block h-20 w-14 rounded-full ${googleOrTrad === "Trad" ? 'bg-primaryzXL' : 'bg-darkzXL'}`}
                ></div>
                <div
                    id='ourMoverXL' className={`rounded-full bg-white transition ${googleOrTrad === "Trad" ? "translate-x-[135%]" : ''}`}
                ></div>
            </div>
            <div id='toggleRightSideXL' className={googleOrTrad === "Trad" ? 'personalToggleXL' : 'notSelectedToggleXL'}>
                Verify Email
            </div>
        </label>
    </div>
</div>
      <div id='horizontalLoginWrapperXL'>
        <div id='storeyTimeXL'>
          <img id='ospaceXL' src={`${process.env.PUBLIC_URL}/images/ospace.gif`} />
        </div>
        <form onSubmit={handleSubmit} id='zachXL'>

        {googleOrTrad==='Trad'? (
            <>
                      <div className='loginFormzXL'>
            <label>Email</label>
            <div className='loginFormz2XL'>
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Valid email (max 70 characters)"
                required
              />
            </div>
          </div>

          <div className='loginFormzXL'>
            <label>Username</label>
            <div className='loginFormz2XL'>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="(4-20 characters)"
                required
              />
            </div>
          </div>

          <div className='loginFormzXL'>
            <label>Password</label>
            <div className='loginFormz2XL'>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="(6-64 characters)"
                required
              />
            </div>
          </div>

          <div className='loginFormzXL'>
            <label>Confirm Password</label>
            <div className='loginFormz2XL'>
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder='passwords must match'
                required
              />
            </div>
            </div>

            </>
          ):(<div id='sup1'>
            <div id='sup2'>
              <p>Just Hit the Signup button below</p>
              <p>You will be prompted to </p>
              <p>Change Your Name </p>
              <p>After Signing in</p>
            </div>


            </div>)}



        </form>
      </div>
      <div id='loginButtonzXL'>
        <div className={googleOrTrad==='Google'?'buttGoogle':'notGoogle'}>
          {googleOrTrad==='Trad'?(
            <MakeButton
            onClick={handleSubmit}
            text='Sign Up'
            fontSize='1vw'
            />
          ): <GoogleAuthButton setWaiting={setWaiting} signup={true} />}

        </div>
      </div>
    </div>
  );
}

export default SignupFormModal;
