import React, { useState } from "react";
import { login } from "../../store/session";
import { useDispatch } from "react-redux";
import { useModal } from "../../context/Modal";
import GoogleAuthButton from "../GoogleAuthButton";
import { MakeButton } from "../utils";
import "./addFriendsGoals.css";
import { ThunkGetFriendsGoals } from "../../store/goals";
import { useSharedSetters } from "../../context/SharedSetters";
import ErrorsModal from "../ErrorsModal";

function UseFriendsGoals() {
  const dispatch = useDispatch();
  const { closeModal } = useModal();
  const [goalId, setGoalId] = useState('');
  const [password,setPassword] = useState('')
  const {updateNewGroupMade}= useSharedSetters()
  const [errors,setErrors]=useState({})

  const validateForm = () => {
    let newErrors = {};

    if (!goalId || goalId <= 0) {
      newErrors.goalId = "Goal ID must be a number greater than 0.";
    }

    if (!password || password.length < 6 || password.length > 64) {
      newErrors.password = "Password must be between 6 and 64 characters.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();

    if (!validateForm()) return;
    const returnObj= {
        goalId,password
    }

    let test=await dispatch(ThunkGetFriendsGoals(returnObj))
    if (test.errors){
      setErrors(test.errors)
    }
    else if (test){
    closeModal();
    updateNewGroupMade(true)
    }
    else {
    alert('Something unexpected happened')
    }
  };



  return (

    <div id="loginWrapperFG">
      {Object.keys(errors).length >= 1 && (
      <ErrorsModal errors={errors} setErrors={setErrors} formType={'Login'}   />)}

      <div id='loginHeaderFG'>Add Friends Goal</div>
      <div id='horizontalLoginWrapperFG'>
        <div id='storeyTimeFG'>
        <img id='ospaceFG' src={`${process.env.PUBLIC_URL}/images/iDontWantYou.gif`} />
        </div>
      <form onSubmit={handleSubmit} id='zachFG'>
        <ul>
        </ul>
        <div className='loginFormzFG'>
        <label>
          Share Id</label>
          <div className='loginFormz2FG'>
          <input
            type="number"
            id='lastCallFG'
            value={goalId}
            onChange={(e) => setGoalId(e.target.value)}
            required
          />
          </div>

        </div>

        <div className='loginFormzFG'>
        <label>
          Password </label>
          <div className='loginFormz2FG'>
          <input
            type="text"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          </div>
        </div>
        </form>
        </div>
        <div id='loginButtonzFG'>
            <div className='w-[30%] h-full'>
        <MakeButton
                                                        type="button"
                                                        onClick={handleSubmit}
                                                        text='Add Goal'
                                                        fontSize='1vw'
                                                    />
                                                    </div>



        </div>


      </div>

  );
}

export default UseFriendsGoals;
