import React from 'react';
import './overlay.css';

const Overlay = ({ isVisible }) => {
    return (
        <div
            className="overlay"
            style={{ opacity: isVisible ? 1 : 0 }}
        ></div>
    );
};

export default Overlay;
