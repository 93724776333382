import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useModal } from "../../context/Modal";
import { MakeButton } from "../utils";
import ErrorsModal from "../ErrorsModal";
import { ThunkMakeWaiting } from "../../store/waiting";
import "./makeWaiting.css";

function MakeWaiting({ setModalContent }) {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [company, setCompany] = useState("");
  const [notes, setNotes] = useState("");
  const [errors, setErrors] = useState({});
  const { closeModal } = useModal();
  const [waiting, setWaiting] = useState(false);

  const validate = () => {
    let validationErrors = {};

    // Check if the company field is empty
    if (!company) {
      validationErrors.company = "Company field is required.";
    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    if (!validate()) return;
    const retObj= {
      title,company,notes
    }
    const data = await dispatch(ThunkMakeWaiting(retObj));
    if (data.errors) {
      setErrors(data.errors);
    } else {
      setErrors({});
      closeModal();
    }
  };

  // const handleDemoUserClick = async () => {
  //   setTitle("demo@aa.io");
  //   setCompany("password");

  //   setWaiting(true);
  //   const data = await dispatch(login("demo@aa.io", "password"));
  //   setWaiting(false);
  //   if (data.errors) {
  //     setErrors(data.errors);
  //   } else {
  //     setErrors({});
  //     closeModal();
  //   }
  // };

  return (
    <div id="loginWrapperWTG">
      {Object.keys(errors).length >= 1 && (
        <ErrorsModal errors={errors} setErrors={setErrors} formType={"Login"} />
      )}
      {waiting && (
        <img id="isLoading2WTG" src={`${process.env.PUBLIC_URL}/images/loading.gif`} />
      )}
      <div id="loginHeaderWTG">Add A Waiting</div>
      <div id="horizontalLoginWrapperWTG">
        <form onSubmit={handleSubmit} id="zachWTG">
          <ul>{/* Errors display */}</ul>
          <div className="loginFormzWTG">
            <label>Title</label>
            <div className="loginFormz2WTG">
              <input
                type="text"
                id="lastCallWTG"
                value={title}
                placeholder="Optional"
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
          </div>

          <div className="loginFormzWTG">
            <label>Company *</label>
            <div className="loginFormz2WTG">
              <input
                type="text"
                value={company}
                placeholder="Required"
                onChange={(e) => setCompany(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="loginFormzWTG">
            <label>Description/Notes</label>
            <div className="loginFormz2WTG">
              <input
                type="text"
                value={notes}
                placeholder="Optional"
                onChange={(e) => setNotes(e.target.value)}
              />
            </div>
          </div>
        </form>
      </div>
      <div id="loginButtonzWTG">
        <div className="w-[30%] h-full">
          <MakeButton
            onClick={(e) => {
              // Clear the form fields
              setTitle("");
              setCompany("");
              setNotes("");
            }}
            text="Clear"
            fontSize="1vw"
          />
        </div>
        <div className="w-[30%] h-full">
          <MakeButton onClick={handleSubmit} text="Add Card" fontSize="1vw" />
        </div>
      </div>
    </div>
  );
}

export default MakeWaiting;
