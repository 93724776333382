import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { MultiBackend } from 'react-dnd-multi-backend';
import {HTML5toTouch} from 'rdndmb-html5-to-touch'; // Preset to automatically switch between HTML5 and Touch backends
import Homepage from './components/Homepage';
import Navigation from './components/Navigation';
import { authenticate } from './store/session';
// import CustomDragLayer from './components/customDragLayer';
import { Route, Switch } from "react-router-dom";
import { SocketProvider } from './context/SocketContext';
import MyCalendar from './components/Calendar';
import TimeDisplay from './components/timerComponent';
import PrivacyPolicy from './components/PrivacyPolicy'


function App() {
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);


  useEffect(() => {

    dispatch(authenticate()).then(() => setIsLoaded(true));
  }, [dispatch]);

  return (

    <>

      <Navigation isLoaded={isLoaded} />
      {isLoaded && (
        <DndProvider backend={MultiBackend} options={HTML5toTouch}>
          {/* <CustomDragLayer /> */}
          <Switch>
            <Route exact path='/'>
              <Homepage />
            </Route>
            <Route exact path='/calendar'>
              <MyCalendar />
            </Route>

            <Route exact path='/Privacy'>
              <PrivacyPolicy />
            </Route>

          </Switch>
        </DndProvider>
      )}

    </>

  );
}

export default App;
