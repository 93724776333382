import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDrop } from 'react-dnd';
import { MakeButton } from "../utils";
import AppliedCard from "../AppliedCard";
import isMobile from "is-mobile";
import { ThunkGetHighQuals } from "../../store/highqualApps";

const HighQualApplied = ({ setToggleDetailsMobile, changeCardFromTo }) => {
    const appliedHighQualJobs = useSelector(state => state.highQuals);
    const [update, setUpdate] = useState(false);
    const dispatch = useDispatch();
    const actual = useSelector(state => state.highQuals.highQuals);
    const [, dropRef] = useDrop({
        accept: 'JOB_CARD',
        drop: async (item, monitor) => {
            await changeCardFromTo(item, 'High-Quality');
            const didDrop = monitor.didDrop();
            if (!didDrop) {
                return;
            }
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    });

    useEffect(() => {

    }, [update]);

    useEffect(() => {
        const fetchData = async () => {
          await dispatch(ThunkGetHighQuals());

        };
        fetchData();
      }, [dispatch]);

    console.log('INSIDE OF HIGHQUAL APPLIED.  IN HERE APPLIED HIGHQUALJOBS IS', appliedHighQualJobs);

    return (
        <>
            <div className='headerEachCat'>

            <div className='absFolderText'>
              High-Qual
            </div>
                <div id={isMobile() ? 'hone' : 'honeMain'}>
                <img
          id='thirdTab' src={`${process.env.PUBLIC_URL}/images/GreenTabLighter3.png`}/>

                    {/* High Quality */}

                    </div>
            </div>
            <div id='wrapperNewJobsShortened' className='scrollable-without-scrollbar'>
                <div ref={dropRef} className="drop-area">
                    {appliedHighQualJobs.allHighQuals.length > 0 ? (
                        <ul id='allOurJobs2'>
                            {appliedHighQualJobs.allHighQuals.map((job, index) => (
                                <AppliedCard key={job} actual={actual} job={job} setToggleDetailsMobile={setToggleDetailsMobile} source='High-Quality' />
                            ))}
                        </ul>
                    ) : (
                        <p>No jobs found</p>
                    )}
                </div>
            </div>
        </>
    );
};

export default HighQualApplied;
