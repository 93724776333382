import React from 'react';
import { useModal } from '../../context/Modal';

function OpenModalButton({
  modalComponent, // component to render inside the modal
  buttonText, // text of the button that opens the modal
  onButtonClick, // optional: callback function that will be called once the button that opens the modal is clicked
  onModalClose, // optional: callback function that will be called once the modal is closed
  modalStyle
}) {
  const { setModalContent, setOnModalClose,setModalStyle } = useModal();

  const onClick = () => {
    if (onModalClose) setOnModalClose(() => {
      // Reset modal style if needed here as well, but better to handle in closeModal
      setModalStyle({});
      onModalClose();
    });
    if (modalStyle) setModalStyle(modalStyle); // Set the modal's custom style
    setModalContent(modalComponent);
    if (onButtonClick) onButtonClick();
  };

  return (
    <button onClick={onClick}>{buttonText}</button>
  );
}

export default OpenModalButton;
