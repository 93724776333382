

let arrayData
let objData


const nameOurCategoryCaps='REJECTED'

const nameOfCategory= 'Rejected'



const CLEAR_REJECTED = `indeeds/CLEAR_${nameOurCategoryCaps}`;
const SET_REJECTED = `indeeds/SET_${nameOurCategoryCaps}`;
const MAKE_REJECTED = `indeeds/MAKE_${nameOurCategoryCaps}`;
const MOVE_REJECTED = `indeeds/MOVE_${nameOurCategoryCaps}`

// Action creators
const getRejecteds = (incomingData) => {
    const allIds= Object.keys(incomingData)



    return {
    type: SET_REJECTED,
    payload: {allIds,incomingData},
}};

export const clearRejected = () => ({
    type: CLEAR_REJECTED,
});
export const actionMoveRejected = (incomingData) => {






    return {

    type: MOVE_REJECTED,
    payload: incomingData.id,
}};


export const makeRejected = (incomingData)=>{

    return {

        type: MAKE_REJECTED,
        payload: {id: incomingData.id,incomingData:incomingData}
    }

}


const initialState = { all_rejected: [],rejecteds: {} };




export const ThunkGetRejecteds = () => async (dispatch) => {
    const response = await fetch('/api/jobs/get-something', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },

        body: JSON.stringify({key:'Rejected'})
    });
    if (response.ok) {
        const data = await response.json();
        dispatch(getRejecteds(data.allDynamics));
    } else {

        console.error('Failed to fetch Indeed jobs');
    }
};

// Reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {

        case SET_REJECTED:
            return { ...state, all_rejected: action.payload.allIds, rejecteds: action.payload.incomingData };

        case MAKE_REJECTED:
            let newState={...state}
            let copy={...newState}
            if (!newState.all_rejected.includes(action.payload.id)) {
                copy.all_rejected = [action.payload.id,...newState.all_rejected];
            }

            copy.rejecteds= {...newState.rejecteds, [action.payload.id]:action.payload.incomingData}
            return copy
        case MOVE_REJECTED:
            let newState2={...state}

            let newVar= newState2.all_rejected.filter((ele)=>ele!=action.payload)
            newState2.all_rejected=newVar
            let newObj= {}
            for (let ele of newState2.all_rejected){
                newObj[ele]=newState2.rejecteds[ele]
            }
            newState2.rejecteds=newObj

            return newState2

        case CLEAR_REJECTED:
            return { ...initialState };

        default:
            return state;
    }
}
