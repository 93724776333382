// constants
const SET_STANDARDS = 'indeeds/SET_STANDARD';
const MAKE_STANDARD = 'indeeds/MAKE_STANDARD';
const MOVE_STANDARD = 'indeeds/MOVE_STANDARD_TO_JOB'
const CLEAR_STANDARD = 'indeeds/CLEAR_STANDARD';
// Action creators

export const clearStandard = () => ({
    type: CLEAR_STANDARD,
});
const getStandards = (standards) => {
    const allIds= Object.keys(standards)



    return {
    type: SET_STANDARDS,
    payload: {allIds,standards},
}};

export const actionMoveStandard = (incomingData) => {
    // const allIds= Object.keys(jobData)





    return {
    type: MOVE_STANDARD,
    payload: incomingData.id,
}};

export const makeStandard = (standard)=>{

    return {
        type: MAKE_STANDARD,
        payload: {id: standard.id,standard:standard}
    }

}

const initialState = { allStandards: [],standards: {} };




export const ThunkGetStandards = () => async (dispatch) => {
    const response = await fetch('/api/jobs/get-something', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        //key will always be one of the following
        //['Kwey','Standard', 'High-Quality',"Waiting",'Rejected', 'Next Step', 'Offer Accepted']
        body: JSON.stringify({key:'Standard'})
    });
    if (response.ok) {
        const data = await response.json();
        dispatch(getStandards(data.allDynamics));
    } else {

        console.error('Failed to fetch Indeed jobs');
    }
};

// Reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_STANDARDS:
            return { ...state, allStandards: action.payload.allIds, standards: action.payload.standards };
        case MAKE_STANDARD:
            let newState={...state}
            let copy={...newState}
            if (!newState.allStandards.includes(action.payload.id)) {
                copy.allStandards = [action.payload.id,...newState.allStandards];
            }
            copy.standards= {...newState.standards, [action.payload.id]:action.payload.standard}
            return copy
        case MOVE_STANDARD:
            let newState2={...state}
            let newVar= newState2.allStandards.filter((ele)=>ele!=action.payload)
            newState2.allStandards=newVar
            let newObj= {}

            for (let ele of newState2.allStandards){
                newObj[ele]=newState2.standards[ele]
            }
            newState2.standards=newObj

            return newState2

        case CLEAR_STANDARD:
            return { ...initialState };
        default:
            return state;
    }
}
