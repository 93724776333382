import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useModal } from "../../context/Modal";
import { useDispatch } from 'react-redux';
import { MakeButton } from '../utils';
import './firstLogin.css'
import { ThunkChangeUserName, ThunkSecondTimer } from '../../store/session';

function FirstLogin() {
    // State hooks
    const sessionUser = useSelector((state) => state.session.user);
    const [state, setState] = useState(null); // Placeholder state, adjust as needed
    const [phase,setPhase] = useState('one')
    const [morph,setMorph] =useState(false)
    // Ref hook for any DOM references you might need
    const someRef = useRef(null);

    // Redux dispatch hook
    const dispatch = useDispatch();
    const { closeModal } = useModal();
    const [newUserName,setNewUserName]=useState('')

    // Effect hook for side effects
    useEffect(() => {
        if (sessionUser && sessionUser.first_time && sessionUser.username.includes('newbie_')){
            setPhase('zero')
        }


    }, [sessionUser]); // Including dispatch in the dependency array if it's used within the effect

    return (
        <div id='wrapperFirstTime'>
            <div id='headerFirst'>
                <div id='sweetSpotFirst'>
                    Welcome
                </div>
            </div>
            {phase==='one'? (
                <div id='ftMainBody'>
                <div id='mbw'>
                    <div id='bigHead'>
                        A few Things to Know:
                    </div>
                    <div id='listUl'>
                      <div className='listULH'>
                        <div className='ULHNUM'>
                            1
                        </div>
                        <div className='wrapCAPS'>
                        <div className='ULHTOP'>
                            The Green Box up Top can and should be clicked
                        </div>
                        <div className='ULHBOT'>
                            It's how you navigate the site
                        </div>
                        </div>
                      </div>
                      <div className='listULH'>
                        <div className='ULHNUM'>
                            2
                        </div>
                        <div className='wrapCAPS'>
                        <div className='ULHTOP smaller'>
                            You will Need to sign up with Google To Operate the Calendar
                        </div>
                        <div className='ULHBOT'>
                            It's a handy feature.
                        </div>
                        </div>
                      </div>
                      {/* <div className='listULH'>
                        <div className='ULHNUM'>
                            3
                        </div>
                        <div className='wrapCAPS'>
                        <div className='ULHTOPNEW smaller'>
                            <p>A: Make Personal Goals.</p>
                            <p>B: Make or add a Friend's goals </p>
                            <p>C: Link Goals that are shared between the two</p>
                            <p>D: Click 'view race' to compete and chat.</p>
                        </div>

                        </div>
                      </div> */}
                    </div>
                </div>
            </div>
            ):phase==='two'?( <div id='ftMainBody'>
            <div id='mbw'>
                <div id='bigHead'>
                    Site Flow:
                </div>
                <div id='listUl3'>
                  <div className='listULH3'>
                    <div className='ULHNUM3'>
                        A
                    </div>
                    <div className='wrapCAPS'>
                    <div className='ULHTOP3'>
                        Set up Personal Goals
                    </div>
                    <div className='ULHBOT3'>
                        It's in the drop-down menu.
                    </div>
                    </div>
                  </div>
                  <div className='listULH3'>
                    <div className='ULHNUM3'>
                        B
                    </div>
                    <div className='wrapCAPS'>
                    <div className='ULHTOP3 '>
                        Set up Group Goals, or add a friend's goals.
                    </div>
                    <div className='ULHBOT3'>
                        Also in the drop-down menu.
                    </div>
                    </div>
                  </div>
                  <div className='listULH3'>
                    <div className='ULHNUM3'>
                        C
                    </div>
                    <div className='wrapCAPS'>
                    <div className='ULHTOP3 '>
                        Link your goals.
                    </div>
                    <div className='ULHBOT3'>
                        Also in the drop-down menu.
                    </div>
                    </div>
                  </div>
                  <div className='listULH3'>
                    <div className='ULHNUM3'>
                        D
                    </div>
                    <div className='wrapCAPS'>
                    <div className='ULHTOP3 '>
                       Track your progress with the counter.
                    </div>
                    <div className='ULHBOT3'>
                        Click the green bar up top.
                    </div>
                    </div>
                  </div>
                  <div className='listULH3'>
                    <div className='ULHNUM3'>
                        E
                    </div>
                    <div className='wrapCAPS'>
                    <div className='ULHTOP3 '>
                       See how you stack up in "Race view".
                    </div>
                    <div className='ULHBOT3'>
                        Green bar up top.
                    </div>
                    </div>
                  </div>
                  {/* <div className='listULH'>
                    <div className='ULHNUM'>
                        3
                    </div>
                    <div className='wrapCAPS'>
                    <div className='ULHTOPNEW smaller'>
                        <p>A: Make Personal Goals.</p>
                        <p>B: Make or add a Friend's goals </p>
                        <p>C: Link Goals that are shared between the two</p>
                        <p>D: Click 'view race' to compete and chat.</p>
                    </div>

                    </div>
                  </div> */}
                </div>
            </div>
        </div>):phase==='zero'?(
            <div id='ftMainBody'>
            <div id='mbw'>
                <div id='bigHead3'>
                    Greetings {`"${sessionUser.username}"`}
                </div>
                <div id='expUN'>
                    <div id='expUN1'>
                        {!morph? (
                            <>
                            <p>Let's change that username</p>
                        <p>Shall we?</p>
                            </>
                        ):<><p>Username Changed</p></>}

                        </div>
                    </div>
                    <div id='mainBodyZero'>
                    <div id='mbz1'>

                        <div id='mbz2'> Username:</div>
                        <div id='mbz3'>
                        <form id='heyo'>
                        <input id='heyo2' type='text' value={newUserName} onChange={(e)=>setNewUserName(e.target.value)} placeholder='max 20 characters'>

                        </input>

                        </form>
                        </div>
                    </div>
                    </div>
                    <div id='zeroFooter'>
                    <div id='zf1'>
                    {!morph?(<MakeButton
    onClick={async(e) => {
       let test=await dispatch(ThunkChangeUserName({"newName":newUserName}))
       if (test && test.success){
        setMorph(true)
       }
    }}
    text='Set Name'
    fontSize='1vw'
/>):<div id='succ'>Success</div>}
                    </div>
                    </div>

            </div>
        </div>
        ):null}

            <div id='footerFirstLogin'>
                <div id='footerLoginButton'>
                {(phase==='zero' && !morph)?null:(<MakeButton
    onClick={async(e) => {
        if (phase === 'zero') {
            setPhase('one');
        } else if (phase === 'one') {
            setPhase('two');
        }
        else if (phase==='two'){
           let test2= await dispatch(ThunkSecondTimer())
           if (test2.success){
            closeModal()
           }

        }
    }}
    text={phase==='two'?'Close':'Next'}
    fontSize='1vw'
/>)}
                </div>
            </div>
        </div>
    );
}

export default FirstLogin;
