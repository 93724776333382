import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDrop } from 'react-dnd';
import { MakeButton } from "../utils";
// import AppliedGoal from "../AppliedCard";
import AppliedGoal from "../AppliedGoal";
import isMobile from "is-mobile";
import { ThunkGetWaitings } from "../../store/waiting";
import { ThunkGetGoalCardsNumber } from "../../store/goals";

const GoalApplied4 = ({ setToggleDetailsMobile,changeGoalFromTo,source,header,selectedGoals }) => {
    const goal4base = useSelector(state => state.goals[4]);
    const [update, setUpdate] = useState(false);
    const dispatch = useDispatch();
    const actual = useSelector(state => state.goals?.[4]['by_id']);
    // const dynamicHeightBase= 100/goal3base?.all_ids.length
    // const dynamicHeight= dynamicHeightBase>15? `${dynamicHeightBase}%`:
    const [, dropRef] = useDrop({
        accept: 'GOAL_CARD',
        drop: async (item, monitor) => {
            await changeGoalFromTo(item, header);
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    });

    useEffect(() => {
        const fetchData = async () => {
            const sendPackage={"number": 4,"selectedGoals": selectedGoals}
          await dispatch(ThunkGetGoalCardsNumber(sendPackage));
        };
        fetchData();
      }, [dispatch]);

    useEffect(() => {

    }, [update]);

    console.log('LETS SEE WHAT GOAL3BASE IS INSIDE OF GOALAPPLIED', goal4base);
    console.log('LETS SEE WHAT BY IDS IS',goal4base.by_id)
    return (
        <>
            <div className='headerEachCat'>
            <div id={isMobile() ? 'hone' : 'honeMain7'}>

<div className='absFolderText'>
{header}
</div>
<img id='fourthTab' src={`${process.env.PUBLIC_URL}/images/turqoiseTab.png`}/>



</div>
            </div>
            <div id='wrapperNewJobs2'>
                <div ref={dropRef} className="drop-area">
                    {goal4base.all_ids.length > 0 ? (
                        <ul id='allOurGoals2'>
                            {goal4base.all_ids.map((job, index) => (
                                <AppliedGoal key={job} actual={actual} job={job} setToggleDetailsMobile={setToggleDetailsMobile} source={header} />
                            ))}
                        </ul>
                    ) : (
                        <p>No jobs found</p>
                    )}
                </div>
            </div>
        </>
    );
};

export default GoalApplied4;
