// constants
// Define your action type constants here, for example:
const SET_GOALS = 'goals/SET_GOALS';
const ADD_GOAL = 'goals/ADD_GOAL';
const UPDATE_GOALS = 'goals/UPDATE_GOALS';
const MAKE_NEW_GOAL = 'goals/MAKE_NEW_GOAL';
const GET_GOALS_NUMBER = 'goals/GET_GOALS_NUMBER'
const GET_ALL_GOAL_CARDS = 'goals/GET_ALL_GOAL_CARDS'
const SET_GOAL_FROM_TO = 'goals/SET_GOAL_FROM_TO'
const SET_DICTIONARIES = 'goals/SET_DICTIONARIES'
const GET_FRIENDS_GOALS = 'goals/GET_FRIENDS_GOALS'
const GET_ALL_LIVE_DATA = 'goals/GET_ALL_LIVE_DATA'
const UPDATE_SPECIFIC_LIVE = 'goals/UPDATE_SPECIFIC_LIVE'
const MESSAGE_RECEIVED = 'goals/MESSAGE_RECEIVED'
const GET_LIVE_MESSAGES = 'goal/GET_LIVE_MESSAGES'
const DELETE_SINGLE_GOAL = 'goal/DELETE_SINGLE_GOAL'
const SEND_COUNTER = 'goal/SEND_COUNTER'
const EDIT_GOALS = 'goal/EDIT_GOALS'
const CLEAR_GOALS = 'goals/CLEAR_GOALS';
const DELETE_GOAL_INSTANCE = 'goals/DELETE_GOAL_INSTANCE'
const DESTROY_GROUP = 'goals/DESTROY_GROUP_AND_DICTIONARIES'
const GET_GOALS = 'goals/GET_GOALS_Z'
const GET_PUBLIC= 'goals/GET_PUBLIC_GOALS'

// Action creators
// Define your action creators here, for example:
export const clearGoals = () => ({
    type: CLEAR_GOALS,
});
export const actionSendCounter = (data) => {

    console.log('GOOD NEWS BRO.  WE ARE IN OUR ACTION SEND COUNTER ACTION I OUR STORE')
    console.log('in here goals isssssss', data)
    let personalGoals = null
    let groupGoals = null
    if (data.goalToUpdate.typeGoals === 'Personal') {
        personalGoals = data.goalToUpdate
    }
    else {
        groupGoals = data.goalToUpdate
    }

    if (data.secondGoalToUpdate) {
        if (data.secondGoalToUpdate.typeGoals === 'Group') {
            groupGoals = data.secondGoalToUpdate
        }
        else {
            personalGoals = data.secondGoalToUpdate
        }
    }

    return {
        type: SEND_COUNTER,
        payload: { personalGoals, groupGoals }
    };
};
export const actionDestroyGroup = (goal_clone) => {

    console.log('GOOD NEWS BRO.  WE ARE IN OUR UPDATE SPECIFIC LIVE ACTION I OUR STORE')
    console.log('in here goals isssssss', goal_clone)

    return {
        type: DESTROY_GROUP,
        payload: goal_clone
    };
};

const actionGetPublic = (public_goals) => {

    console.log('GOOD NEWS BRO.  WE ARE IN OUR UPDATE SPECIFIC LIVE ACTION I OUR STORE')
    console.log('in here goals isssssss', public_goals)

    return {
        type: GET_PUBLIC,
        payload: public_goals
    };
};

export const actionMessageReceived = (message) => {

    console.log('GOOD NEWS BRO.  WE ARE IN OUR UPDATE SPECIFIC LIVE ACTION I OUR STORE')
    console.log('in here goals isssssss', message)

    return {
        type: MESSAGE_RECEIVED,
        payload: message
    };
};

export const actionUpdateSpecificLive = (goals) => {

    console.log('GOOD NEWS BRO.  WE ARE IN OUR UPDATE SPECIFIC LIVE ACTION I OUR STORE')
    console.log('in here goals isssssss', goals)
    let goalId = goals.goal.id
    let goal = goals.goal
    return {
        type: UPDATE_SPECIFIC_LIVE,
        payload: { goalId, goal }
    };
};


export const actionGetAllLiveData = (goals) => {

    console.log("WE ARE INSIDE ACTION GET ALL LIVE DATA. IN HERE GOALS IS", goals)

    return {
        type: GET_ALL_LIVE_DATA,
        payload: goals,
    };
};
export const actionGetFriendsGoals = (goals) => {



    return {
        type: GET_FRIENDS_GOALS,
        payload: goals,
    };
};

export const actionDeleteSingleGoal = (goals) => {


    console.log('INSIDE OF ACTION DELETE SINGLE GOAL GOALS IS***************************************************************************************************************************', goals)

    return {
        type: DELETE_SINGLE_GOAL,
        payload: goals,
    };
};


export const actionUpdateGoals = (goals) => {
    let obj = {}

    for (let ele of goals) {
        if (ele.typeGoals === "Personal") {
            obj.Personal = ele
        }
        else {
            obj.Group = ele
        }
    }
    return {
        type: UPDATE_GOALS,
        payload: obj,
    };
};

const actionGetAllGoalCards = (goals) => {
    // Logic to prepare payload, if needed
    console.log('INSIDE OF MY ACTION GET ALL GOAL CARDS GOALS IS', goals)

    // let returnObj={3: {"all_ids": [],"by_id":{}},4:{"all_ids": [],"by_id":{}},5: {"all_ids": [],"by_id":{}}}
    let testArray3 = []
    let returnObj3 = {}
    let testArray4 = []
    let returnObj4 = {}
    let testArray5 = []
    let returnObj5 = {}
    for (let ele of goals[3]) {
        testArray3.push(ele['id'])
        returnObj3[ele['id']] = ele
    }
    for (let ele of goals[4]) {
        testArray4.push(ele['id'])
        returnObj4[ele['id']] = ele
    }
    for (let ele of goals[5]) {
        testArray5.push(ele['id'])
        returnObj5[ele['id']] = ele
    }
    return {
        type: GET_ALL_GOAL_CARDS,
        payload: { testArray3, testArray4, testArray5, returnObj3, returnObj4, returnObj5 }
    };
};
export const actionSetDictionaries = (goals) => {
    // Logic to prepare payload, if needed

    console.log('HERE IN OUR REDUCER  BRO FOR AFCTIONSETGOALFROMTO GOALS IS', goals)
    return {
        type: SET_DICTIONARIES,
        payload: goals,
    };
};

export const actionSetGoalFromTo = (goals) => {
    // Logic to prepare payload, if needed

    console.log('HERE IN OUR REDUCER  BRO FOR AFCTIONSETGOALFROMTO GOALS IS', goals)
    return {
        type: SET_GOAL_FROM_TO,
        payload: goals,
    };
};

const actionGetLiveMessages = (goals) => {
    // Logic to prepare payload, if needed

    console.log('INSIDE OUR ACTION GET LIVE MESSAGES OUR STUFF IS', goals)
    return {
        type: GET_LIVE_MESSAGES,
        payload: goals,
    };
};
const actionSetGoals = (goals) => {
    // Logic to prepare payload, if needed

    console.log('INSIDE OUR ACTION SET GOAL GOALS Is', goals)
    return {
        type: ADD_GOAL,
        payload: goals,
    };
};

// const actionGetGoals = (goals) => {
//     // Logic to prepare payload, if needed

//     console.log('INSIDE OUR ACTION SET GOAL GOALS Is', goals)
//     return {
//         type: ADD_GOAL,
//         payload: goals,
//     };
// };

const actionGetGoalsNumber = (goals) => {
    // Logic to prepare payload, if needed
    console.log('INSIDE OF OUR ACTION GET GOALS NUMBER GOALS LOOKS LIKE', goals)
    let testArray = []
    for (let ele of goals.goalCards) {
        testArray.push(ele.id)
    }
    let returnObj = {}

    for (let ele of goals.goalCards) {
        returnObj[ele['id']] = ele
    }
    // let all_ids= Object.keys(goals.goalCards)
    let all_ids = testArray
    let by_id = returnObj
    let ourNum = parseInt(goals.number)
    return {
        type: GET_GOALS_NUMBER,
        payload: { all_ids, by_id, ourNum }
    };
};

const actionMakeNewGoal = (goals) => {
    console.log('*******************************IN ACTION MAKENEW GOAL OUR DATA LOOKS LIKE', goals)

    console.log('************************** newgoals is', goals.newGoals)
    return {
        type: MAKE_NEW_GOAL,
        payload: goals,
    };
};
const actionEditGoals = (goals) => {
    // Logic to prepare payload, if needed
    console.log('WE ARE INSIDE OF OUR ACTION TO GET ALL GOALS AND IN H ERE DATA IS', goals)
    return {
        type: EDIT_GOALS,
        payload: goals,
    };
};

const actionDeleteGoals = (goals) => {
    // Logic to prepare payload, if needed
    console.log('WE ARE INSIDE OF OUR ACTION TO GET ALL GOALS AND IN H ERE DATA IS', goals)
    return {
        type: DELETE_GOAL_INSTANCE,
        payload: goals,
    };
};


const actionGetGoals = (goals) => {
    // Logic to prepare payload, if needed
    console.log('WE ARE INSIDE OF OUR ACTION TO GET ALL GOALS AND IN H ERE DATA IS', goals)
    return {
        type: GET_GOALS,
        payload: goals,
    };
};
/*
export const addGoal = (goal) => {
    // Logic for adding a new goal
    return {
        type: ADD_GOAL,
        payload: goal,
    };
};

export const updateGoal = (goalId, updates) => {
    // Logic for updating a goal
    return {
        type: UPDATE_GOAL,
        payload: { goalId, updates },
    };
};
*/

export const ThunkGetAllGoalCards = (selectedGoals) => async (dispatch) => {
    try {
        const response = await fetch('/api/goals/get-all-goalcards', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ selectedGoals }), // Assuming you need to send the selectedGoals type
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();

        // Assuming you have an action creator similar to actionGetGoalsNumber for handling all goal cards
        dispatch(actionGetAllGoalCards(data)); // Make sure to define actionGetAllGoalCards action creator
    } catch (error) {
        console.error("Error fetching all goal cards:", error);
    }
};


export const ThunkGetLiveMessages = (goalId) => async (dispatch) => {
    try {
        const response = await fetch("/api/goals/get-live-messages", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ goalId }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();

        // Dispatch the action with the received data
        // Ensure `actionSetGoals` is defined and correctly imported
        dispatch(actionGetLiveMessages(data));

        // This line is assuming `actionSetGoals` is your action creator
        // Adjust it according to your actual action creator's name and expected payload structure
    } catch (error) {
        console.error("Error fetching data:", error);
    }
};

export const ThunkNewGoal = (goalsObj) => async (dispatch) => {
    try {
        const response = await fetch("/api/goals/new-goal-made", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(goalsObj),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();

        // Dispatch the action with the received data
        // Ensure `actionSetGoals` is defined and correctly imported
        dispatch(actionMakeNewGoal(data));

        // This line is assuming `actionSetGoals` is your action creator
        // Adjust it according to your actual action creator's name and expected payload structure
    } catch (error) {
        console.error("Error fetching data:", error);
    }
};

export const ThunkGetPublicGoals = () => async (dispatch) => {
    const response = await fetch('/api/goals/get-public-goals', {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    if (response.ok) {
        const data = await response.json();
        dispatch(actionGetPublic(data));
    } else {
        // Handle errors or empty data as needed
        console.error('Failed to fetch Indeed jobs');
    }
};

export const ThunkGetGoals = () => async (dispatch) => {
    const response = await fetch('/api/goals/get-all-goals', {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    if (response.ok) {
        const data = await response.json();
        dispatch(actionGetGoals(data));
    } else {
        // Handle errors or empty data as needed
        console.error('Failed to fetch Indeed jobs');
    }
};

export const ThunkGetGoalCardsNumber = (number) => async (dispatch) => {
    try {
        const response = await fetch('/api/goals/get-goalcards-by-number', {
            method: 'POST', // Specify the method
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(number), // Send the number in the request body
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        // Dispatch an action to update the state with the received goal cards
        // Ensure `actionGetGoals` or a more appropriately named action is defined and correctly imported
        dispatch(actionGetGoalsNumber(data));
    } catch (error) {
        console.error("Error fetching goal cards:", error);
    }
};

export const ThunkSetDictionaries = (goalsObj) => async (dispatch) => {
    try {
        const response = await fetch("/api/goals/set-dictionaries", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(goalsObj),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();

        // Dispatch the action with the received data
        // Ensure `actionSetGoals` is defined and correctly imported
        dispatch(actionSetDictionaries(data));

        // This line is assuming `actionSetGoals` is your action creator
        // Adjust it according to your actual action creator's name and expected payload structure
    } catch (error) {
        console.error("Error fetching data:", error);
    }
};
// export const ThunkGetFriendsLiveDataSpecific = (retObj) => async (dispatch) => {
//     const response = await fetch('/api/goals/get-friends-live-data-specific', {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//         },

//         body: JSON.stringify(retObj)
//     });
//     if (response.ok) {
//         const data = await response.json();
//         dispatch(actionGetAllLiveData(data));
//     } else {

//         console.error('Failed to fetch Indeed jobs');
//     }
// };


export const ThunkGetFriendsLiveData = (retObj) => async (dispatch) => {
    const response = await fetch('/api/goals/get-friends-live-data', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },

        // body: JSON.stringify(retObj)
    });
    if (response.ok) {
        const data = await response.json();
        dispatch(actionGetAllLiveData(data));
    } else {

        console.error('Failed to fetch Indeed jobs');
    }
};


export const ThunkGetFriendsGoals = (retObj) => async (dispatch) => {
    const response = await fetch('/api/goals/get-friends-goals', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(retObj)
    });

    const data = await response.json();

    if (response.ok) {
        dispatch(actionGetFriendsGoals(data));
    } else {
        console.error('Failed to fetch friend\'s goals', data);
        // Optionally, dispatch an error action here if you have one.
    }

    return data; // This ensures data is returned regardless of response status.
};
// {message: messageData,group_goal_id:Group_goal.group_goal_id}
export const ThunkOnlySendMessage = (counterObj) => async (dispatch) => {
    try {
        const response = await fetch("/api/goals/just-send-message", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(counterObj),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return

        // const data = await response.json();

        // Dispatch the action with the received data
        // Ensure `actionSetGoals` is defined and correctly imported
        // dispatch(actionSendCounter(data));

        // This line is assuming `actionSetGoals` is your action creator
        // Adjust it according to your actual action creator's name and expected payload structure
    } catch (error) {
        console.error("Error fetching data:", error);
    }

};

export const ThunkSendCounter = (counterObj) => async (dispatch) => {
    try {
        const response = await fetch("/api/goals/send-counter", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(counterObj),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();

        // Dispatch the action with the received data
        // Ensure `actionSetGoals` is defined and correctly imported
        dispatch(actionSendCounter(data));

        // This line is assuming `actionSetGoals` is your action creator
        // Adjust it according to your actual action creator's name and expected payload structure
    } catch (error) {
        console.error("Error fetching data:", error);
    }

};
export const ThunkDeleteGoals = (goalsObj) => async (dispatch) => {
    try {
        const response = await fetch("/api/goals/delete-goals", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(goalsObj),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();

        // Dispatch the action with the received data
        // Ensure `actionSetGoals` is defined and correctly imported
        dispatch(actionDeleteGoals(data));

        // This line is assuming `actionSetGoals` is your action creator
        // Adjust it according to your actual action creator's name and expected payload structure
    } catch (error) {
        console.error("Error fetching data:", error);
    }
};
export const ThunkEditGoals = (goalsObj) => async (dispatch) => {
    let data;
    try {
        const response = await fetch("/api/goals/edit-goals", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(goalsObj),
        });

        data = await response.json(); // Always parse the response to JSON

        if (!response.ok) {
            // Optionally, handle the error status appropriately.
            // For example, you might want to dispatch a different action depending on the error.
            // Dispatch an error action or update the state with the error received from the server.
            console.error("Server-side error:", data);
        } else {
            // Dispatch the action with the received data
            dispatch(actionEditGoals(data));
        }
    } catch (error) {
        // This catch block is for catching exceptions thrown from fetch or JSON parsing.
        // You could consider having a more generic error structure here if you want to update the UI accordingly.
        console.error("Error fetching data:", error);
        data = { error: "An unexpected error occurred. Please try again." };
    }

    return data; // Return the data or error information.
};

export const ThunkSetGoals = (goalsObj) => async (dispatch) => {
    let data;
    try {
        const response = await fetch("/api/goals/set-goals", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(goalsObj),
        });

        data = await response.json(); // Always try to parse the JSON

        if (!response.ok) {
            // Optionally, handle the error status appropriately.
            // For example, you might want to dispatch a different action depending on the error.
            // Dispatch an error action or update the state with the error received from the server.
            console.error("Server-side error:", data);
        } else {
            // Dispatch the action with the received data
            dispatch(actionSetGoals(data.goal));
        }
    } catch (error) {
        // This catch block is for catching exceptions thrown from fetch or JSON parsing.
        // You could consider having a more generic error structure here if you want to update the UI accordingly.
        console.error("Error fetching data:", error);
        data = { error: "An unexpected error occurred. Please try again." };
    }

    return data; // Return the data or error information.
};


const initialState = {
    personalGoals: {}, groupGoals: {}, 3: { all_ids: [], by_id: {} }, 4: { all_ids: [], by_id: {} }, 5: { all_ids: [], by_id: {}, }, groupToPersonal: {}, personalToGroup: {}, live: { by_id: {}, all_ids: [], messages: [] },public_goals: []
};

// Reducer
export default function reducer(state = initialState, action) {
    let newState
    switch (action.type) {
        // Handle your action types here, for example:
        case GET_PUBLIC:
            newState={...state}
            newState.public_goals=action.payload.public_goals
            return newState
        case DESTROY_GROUP:
            newState={...state}
            newState.groupGoals={}
            newState.groupToPersonal={}
            newState.personalToGroup={}
            return newState
        case DELETE_GOAL_INSTANCE:
            newState = { ...state }
            if (action.payload.typeGoals === 'Personal') {
                newState.personalGoals = {}
            }
            else {
                newState.groupGoals = {}
                let copy={...newState.live}
                copy.by_id={}
                copy.all_ids=[]
                copy.messages=[]
                newState.live=copy

            }

            newState.personalToGroup = action.payload.personalToGroup;
            newState.groupToPersonal = action.payload.groupToPersonal
            return newState
        case EDIT_GOALS:
            newState = { ...state }
            if (action.payload.typeGoals === 'Personal') {
                newState.personalGoals = action.payload.goal
            }
            else {
                newState.groupGoals = action.payload.goal
            }

            newState.personalToGroup = action.payload.personalToGroup;
            newState.groupToPersonal = action.payload.groupToPersonal
            return newState
        case SEND_COUNTER:
            let { personalGoals, groupGoals } = action.payload
            newState = { ...state }
            if (personalGoals) {
                newState.personalGoals = personalGoals
            }
            if (groupGoals) {
                newState.groupGoals = groupGoals
            }
            return newState
        case GET_LIVE_MESSAGES:
            newState = { ...state }
            newState.live.messages = action.payload
            return newState
        case MESSAGE_RECEIVED:
            newState = { ...state }
            let oldArray = [...newState.live.messages]
            oldArray.push(action.payload)
            newState.live.messages = oldArray
            return newState
        case UPDATE_SPECIFIC_LIVE:
            let { goalId, goal } = action.payload;
            newState = { ...state }
            let copy = [...newState.live.all_ids]

            newState.live.by_id[goalId] = goal
            newState.live.all_ids = copy

            return newState
        case GET_ALL_LIVE_DATA:
            newState = { ...state }
            newState.live.by_id = action.payload.by_id;
            newState.live.all_ids = action.payload.all_ids
            return newState
        case GET_FRIENDS_GOALS:
            newState = { ...state }
            newState.groupGoals = action.payload
            return newState
        case SET_DICTIONARIES:
            let { groupToPersonal, personalToGroup, Group, Personal } = action.payload
            newState = { ...state }
            newState.groupToPersonal = groupToPersonal;
            newState.personalToGroup = personalToGroup
            newState.personalGoals = Personal
            newState.groupGoals = Group
            return newState
        case DELETE_SINGLE_GOAL: {
            let { baseCustomGoal, goals, target, transformCustomGoal } = action.payload
            console.log('ORIGINALLY BASE CUSTOM GOAL IS', baseCustomGoal)
            newState = { ...state }
            baseCustomGoal = parseInt(baseCustomGoal)
            console.log('AFTER PARSE INTING BASE CUSTOM GOAL WE GET', baseCustomGoal)
            console.log('IM IN THE REDUCER IM TRYING TO ACCESS NEWSTATE AT BASECUSTOM GOAL LETS JUST DO NEWSTATE BASE CUSTOM GOAL', newState)
            let newArray = newState[baseCustomGoal]['all_ids']
            let newArray2 = newArray.filter((ele) => ele !== parseInt(target.id))
            newState[baseCustomGoal]['all_ids'] = newArray2
            delete newState[baseCustomGoal]['by_id'][parseInt(target.id)]

            return newState
        }
        case SET_GOAL_FROM_TO: {
            let { baseCustomGoal, goals, target, transformCustomGoal } = action.payload
            newState = { ...state }
            baseCustomGoal = parseInt(baseCustomGoal)
            transformCustomGoal = parseInt(transformCustomGoal)
            let newArray = newState[baseCustomGoal]['all_ids']
            let newArray2 = newArray.filter((ele) => ele !== parseInt(target.id))
            newState[baseCustomGoal]['all_ids'] = newArray2

            delete newState[baseCustomGoal]['by_id'][parseInt(target.id)]

            let expandedArray = newState[transformCustomGoal]['all_ids']
            let expandedArray2 = [parseInt(target.id), ...expandedArray]
            newState[transformCustomGoal]['all_ids'] = expandedArray2
            newState[transformCustomGoal]['by_id'][parseInt(target.id)] = target

            return newState
        }
        case GET_ALL_GOAL_CARDS:
            let { testArray3, testArray4, testArray5, returnObj3, returnObj4, returnObj5 } = action.payload
            newState = { ...state }
            newState[3]['all_ids'] = testArray3
            newState[3]['by_id'] = returnObj3;
            newState[4]['all_ids'] = testArray4
            newState[4]['by_id'] = returnObj4;
            newState[5]['all_ids'] = testArray5
            newState[5]['by_id'] = returnObj5;
            return newState

        case GET_GOALS_NUMBER:
            // {all_ids,by_id,ourNum}
            newState = { ...state }
            let { all_ids, by_id, ourNum } = action.payload
            newState[ourNum]['all_ids'] = all_ids
            newState[ourNum]['by_id'] = by_id
            return newState


        case MAKE_NEW_GOAL:
            let newGoals = action.payload.newGoals
            let newGoalsSecondary = action.payload.newGoalsSecondary

            let parsedNum = parseInt(action.payload.customGoalId)
            newState = { ...state }
            if (newGoals.typeGoals === 'Personal') {
                newState.personalGoals = newGoals

                if (newGoalsSecondary) {
                    newState.groupGoals = newGoalsSecondary
                }

            }
            else {
                newState.groupGoals = newGoals

                if (newGoalsSecondary) {
                    newState.personalGoals = newGoalsSecondary
                }

            }
            let oldNumberInstance = newState[parsedNum]
            let oldAllIds = newState[parsedNum].all_ids
            let newAllIds = [action.payload.goalCard.id, ...oldAllIds]
            let oldById = newState[parsedNum].by_id
            let newById = { ...oldById, [action.payload.goalCard.id]: action.payload.goalCard }
            oldNumberInstance.all_ids = newAllIds;
            oldNumberInstance.by_id = newById

            return { ...newState, [parsedNum]: oldNumberInstance }
        case UPDATE_GOALS:
            return { ...state, personalGoals: action.payload.Personal, groupGoals: action.payload.Group }
        case SET_GOALS:
            newState = { ...state }
            // newState.personalGoals = action.payload.personalGoal;
            // newState.groupGoals = action.payload.groupGoal
            // groupToPersonal: {}, personalToGroup: {}
            newState.personalGoals = action.payload.personalGoal || {};
            newState.groupGoals = action.payload.groupGoal || {};
            return newState
        case GET_GOALS:
            newState = { ...state }
            // newState.personalGoals = action.payload.personalGoal;
            // newState.groupGoals = action.payload.groupGoal
            // groupToPersonal: {}, personalToGroup: {}
            newState.personalGoals = action.payload.personalGoal || {};
            newState.groupGoals = action.payload.groupGoal || {};
            newState.personalToGroup= action.payload.personalToGroup || {}
            newState.groupToPersonal = action.payload.groupToPersonal || {}
            return newState
        case ADD_GOAL:
            if (action.payload.typeGoals === 'Personal') {
                return { ...state, personalGoals: action.payload }
            }
            else {
                return { ...state, groupGoals: action.payload }
            }

        case CLEAR_GOALS:
            return { ...initialState }
        default:
            return state;
    }
}
