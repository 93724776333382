import React, { useState } from "react";
import { login } from "../../store/session";
import { useDispatch } from "react-redux";
import { useModal } from "../../context/Modal";
import { MakeButton } from "../utils";
// import GoogleAuthButton from "../GoogleAuthButton";
import "./detailsModal.css";
import DOMPurify from "dompurify";

function DetailsModal({ toggleDetailsMobile, setToggleDetailsMobile }) {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState([]);
  const { closeModal } = useModal();
  const [waiting, setWaiting] = useState(false);
  const sanitizedDescription = DOMPurify.sanitize(toggleDetailsMobile.description);

  //   const handleSubmit = async (e) => {
  //     e.preventDefault();
  //     const data = await dispatch(login(email, password));
  //     if (data) {
  //       setErrors(data);
  //     } else {
  //       closeModal();
  //     }
  //   };

  //   const handleDemoUserClick = async () => {
  //     setEmail("demo@aa.io");
  //     setPassword("password");

  //     setWaiting(true);
  //     const data = await dispatch(login("demo@aa.io", "password"));
  //     setWaiting(false);
  //     if (data) {
  //       setErrors(data);
  //     } else {
  //       closeModal();
  //     }
  //   };

  return (
    <>
      <div id='titleOnly'>{toggleDetailsMobile.title}</div>

      <div id={toggleDetailsMobile.site === 'indeed' ? 'showDescriptionIndeed' : 'showDescriptionLinked'} dangerouslySetInnerHTML={{ __html: sanitizedDescription }}></div>
      <div id='wrapperWrapperCloseButton'>
      <div id='wrapperCloseButton'>

        <MakeButton
          onClick={(e) => {
            setToggleDetailsMobile(false);
            closeModal()
          }}
          text='Close'
          fontSize='1.6vw'
        />
      </div>
      <div id='wrapperCloseButton'>

        <MakeButton
          onClick={(e) => {
            window.open(toggleDetailsMobile.link, '_blank', 'noopener,noreferrer')
            setToggleDetailsMobile(false);
            closeModal()
          }}
          text='Apply'
          fontSize='1.6vw'
        />
      </div>
      </div>
    </>
  );
}

export default DetailsModal;
