import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDrop } from 'react-dnd';
import { MakeButton } from "../utils";
import AppliedCard from "../AppliedCard";
import isMobile from "is-mobile";
// import { ThunkGetWaitings as ThunkGetRejecteds } from "../../store/waiting";
import { ThunkGetRejecteds } from "../../store/rejected";
const Rejected = ({ setToggleDetailsMobile, changeCardFromTo }) => {
    const RejectedBase = useSelector(state => state.rejecteds);
    const [update, setUpdate] = useState(false);
    const dispatch = useDispatch();
    const actual = useSelector(state => state.rejecteds.rejecteds);
    const [, dropRef] = useDrop({
        accept: 'JOB_CARD',
        drop: async (item, monitor) => {
            await changeCardFromTo(item, 'Rejected');
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    });

    useEffect(() => {
        const fetchData = async () => {
          await dispatch(ThunkGetRejecteds());
        };
        fetchData();
      }, [dispatch]);

    useEffect(() => {

    }, [update]);

    console.log('INSIDE OF WAITING APPLIED.  IN HERE APPLIED HIGHQUALJOBS IS', RejectedBase);


{/* <div className='absFolderText'>
              High-Qual
            </div>
                <div id={isMobile() ? 'hone' : 'honeMain'}>
                <img
          id='thirdTab' src={`${process.env.PUBLIC_URL}/images/GreenTabLighter2.png`}/>

                    {/* High Quality */}

                    // </div> */}



    return (
        <>
            <div className='headerEachCat7'>
                <div id={isMobile() ? 'hone' : 'honeMain6'}>

                <div className='absFolderText'>
              Rejected
            </div>
                <img
          id='fourthTab' src={`${process.env.PUBLIC_URL}/images/turqoiseTab4.png`}/>


                    {/* Rejected */}
                    </div>
            </div>

            <div id='wrapperNewJobs2' className='scrollable-without-scrollbar'>
                <div ref={dropRef} className="drop-area">
                    {RejectedBase.all_rejected.length > 0 ? (
                        <ul id='allOurJobs2'>
                            {RejectedBase.all_rejected.map((job, index) => (
                                <AppliedCard key={job} actual={actual} job={job} setToggleDetailsMobile={setToggleDetailsMobile} source='Rejected' />
                            ))}
                        </ul>
                    ) : (
                        <p>No jobs found</p>
                    )}
                </div>
            </div>
        </>
    );
};

export default Rejected;
