// constants
const SET_USER = "session/SET_USER";
const REMOVE_USER = "session/REMOVE_USER";
const REMOVE_FIRST_TIME = 'session/REMOVE_FIRST_TIME'
const CHANGE_USER_NAME = 'session/CHANGE_USER_NAME'
const SECOND_TIMER = 'session/SECOND_TIMER'

const setUser = (user) => ({
	type: SET_USER,
	payload: user,
});
const actionChangeUserName = (data) => ({
	type: CHANGE_USER_NAME,
	payload: data,
});

const actionSecondTimer = () => ({
	type: SECOND_TIMER,
});

const removeUser = () => ({
	type: REMOVE_USER,
});

const initialState = { user: null };

export const authenticate = () => async (dispatch) => {
	const response = await fetch("/api/auth/", {
		headers: {
			"Content-Type": "application/json",
		},
	});
	if (response.ok) {
		const data = await response.json();
		if (data.errors) {
			return data
		}

		dispatch(setUser(data));
		return data
	}
};

export const ThunkSecondTimer = (data) => async (dispatch) => {
	const response = await fetch("/api/auth/second-timer", {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		}
	});

	if (response.ok) {
		const data = await response.json();
		dispatch(actionSecondTimer(data));
		return data;
	} else if (response.status < 500) {
		const data = await response.json();
		if (data.errors) {
			return data;
		}
	} else {
		return ["An error occurred. Please try again."];
	}
};
export const ThunkChangeUserName = (data) => async (dispatch) => {
	const response = await fetch("/api/auth/change-user-name", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			data
		}),
	});

	if (response.ok) {
		const data = await response.json();
		dispatch(actionChangeUserName(data));
		return {"success":true};
	} else if (response.status < 500) {
		const data = await response.json();
		if (data.errors) {
			return data;
		}
	} else {
		return ["An error occurred. Please try again."];
	}
};

export const login = (email, password) => async (dispatch) => {
    const response = await fetch("/api/auth/login", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            email,
            password,
        }),
    });

    const data = await response.json(); // Parse JSON outside of the conditional block

    if (response.ok) {
        dispatch(setUser(data));
    } else {
        // Optionally, handle error case or dispatch an error action
        // For example: dispatch(setError(data));
    }

    return data; // Return data regardless of response status
};

export const logout = () => async (dispatch) => {
	const response = await fetch("/api/auth/logout", {
		headers: {
			"Content-Type": "application/json",
		},
	});

	if (response.ok) {
		dispatch(removeUser());
	}
};

export const signUp = (username, email, password) => async (dispatch) => {
    let data;
    const response = await fetch("/api/auth/signup", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            username,
            email,
            password,
        }),
    });

    // Attempt to parse the JSON response regardless of the response status
    try {
        data = await response.json();
    } catch (error) {
        console.error("Error parsing JSON:", error);
        data = { errors: ["An error occurred while parsing the response."] };
    }

    // Dispatch setUser action if the response was OK
    if (response.ok) {
        dispatch(setUser(data));
    }

    // Always return data; data will include either the user object, error messages, or a parsing error message
    return data;
};

export default function reducer(state = initialState, action) {
	let newState={...state}
	switch (action.type) {
		case SET_USER:
			return { user: action.payload };
		case REMOVE_USER:
			return { user: null };
		case SECOND_TIMER:
			newState.user.first_time=false
			return newState
		case CHANGE_USER_NAME:
			newState.user.username=action.payload.username;

			return newState

		default:
			return state;
	}
}
