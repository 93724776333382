// constants
const SET_JOBS = 'indeeds/SET_INDEEDS';
const MOVE_JOB = 'indeeds/MOVE_JOB';
const MAKE_JOB = 'indeeds/MAKE_JOB_STANDARD';
const CLEAR_JOBS = 'indeeds/CLEAR_JOBS';

// Action creators
export const clearJobs = () => ({
    type: CLEAR_JOBS,
});
const setJobs = (indeeds) => {
    const allIds = Object.keys(indeeds).map(key => parseInt(key, 10));



    return {
    type: SET_JOBS,
    payload: {allIds,indeeds},
}};

export const makeJob = (standard)=>{
    console.log('WEVE HIT NEW JOB reducer action BUDDY.  WE ARE SENDING and also tyring to extract .id and .job',standard)

    return {
        type: MAKE_JOB,
        payload: {id: standard.id,job:standard}
    }

}
export const actionMoveJob = (jobData) => {

    return {
    type: MOVE_JOB,
    payload: parseInt(jobData.id),
}};

const initialState = { allJobs: [],jobs: {} };

// Thunk action

// export const getInspirationQuote = () => async (dispatch) => {

// fetch('https://zenquotes.io/api/today')
// .then(response => {
//   if (!response.ok) {
//     throw new Error('Network response was not ok');
//   }
//   return response.json();
// })
// .then(data => {
//   console.log(data); // Process the data here
// })
// .catch(error => {
//   console.error('There was a problem with your fetch operation:', error);
// });
// }
export const ThunkGetJobs = () => async (dispatch) => {
    const response = await fetch('/api/interact/get-jobs', {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    if (response.ok) {
        const data = await response.json();
        dispatch(setJobs(data.allJobs));
    } else {
        // Handle errors or empty data as needed
        console.error('Failed to fetch Indeed jobs');
    }
};

// Reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_JOBS:
            return { ...state, allJobs: action.payload.allIds, jobs: action.payload.indeeds };
        case MOVE_JOB:
            let newState={...state}
            let newVar= newState.allJobs.filter((ele)=>ele!=parseInt(action.payload))
            newState.allJobs=newVar
            let newObj= {}

            for (let ele of newState.allJobs){
                newObj[ele]=newState.jobs[ele]
            }
            newState.jobs=newObj

            return newState
        case MAKE_JOB:
            let newState2={...state}
            let copy={...newState2}
            if (!newState2.allJobs.includes(action.payload.id)) {
                copy.allJobs = [action.payload.id,...newState2.allJobs];
            }
            copy.jobs= {...newState2.jobs, [action.payload.id]:action.payload.job}
            return copy
        case CLEAR_JOBS:
            return { ...initialState };


        default:
            return state;
    }
}
