
import React, { useState, useEffect } from "react";
import { login } from "../../store/session";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from "../../context/Modal";
import GoogleAuthButton from "../GoogleAuthButton";
import DOMPurify from "dompurify";
import { MakeButton, changeFromTo } from "../utils";
import { ThunkGetJobs, actionMoveJob } from "../../store/jobs";
import Applied from "../StandardApplied";
import isMobile from "is-mobile";
import io from 'socket.io-client';
import { makeJob } from "../../store/jobs";
import Rejected from "../Rejected";
import DetailsModal from "../showDetailsModal";
import Offer from "../Offer";
import GoalApplied3 from "../GoalApplied3";
// import Next_Step from "../Next_Step";

// import "./LoginForm.css";
import { ThunkGetAllGoalCards } from "../../store/goals";
import "../Homepage/homepage.css"
import '../Homepage/mobileHomepage.css'



function TopRightBrain({ selectedGoals, setSelectedGoals }) {
    const getFormattedDateWithoutYear = (date) => {
        return `${date.getMonth() + 1}/${date.getDate()}`;
    }
    const custSelector3 = useSelector((state) => state.goals?.[3]?.all_ids);
    const custSelector4 = useSelector((state) => state.goals?.[4]?.all_ids);
    const custSelector5 = useSelector((state) => state.goals?.[5]?.all_ids);
    const personalGoals= useSelector((state) => state.goals.personalGoals);
    const groupGoals= useSelector((state) => state.goals.groupGoals);
    const goalType = selectedGoals === 'Personal' ? personalGoals : groupGoals;
    const dispatch=useDispatch()
    // const custSelector3ById= custSelector3.by_id
    const getWidthAndColor = (current, total) => {
        const percentage = total > 0 ? (current / total) * 100 : 0; // Ensure no division by zero
        let color;

        if (percentage < 30) {
            color = 'red';
        } else if (percentage >= 30 && percentage < 55) {
            color = 'orange';
        } else if (percentage >= 55 && percentage < 85) {
            color = 'yellow';
        } else {
            color = 'green';
        }

        return { width: `${percentage}%`, backgroundColor: color };
    }
    const getStartOfWeek = (date) => {
        const day = date.getDay();
        const diff = date.getDate() - day + (day === 0 ? -6 : 1);
        return new Date(date.setDate(diff));
    }
    const startOfWeek = getStartOfWeek(new Date());
    const endOfWeek = new Date(startOfWeek.getTime() + 6 * 24 * 60 * 60 * 1000);
    const formattedWeekRange = `${getFormattedDateWithoutYear(startOfWeek)}-${getFormattedDateWithoutYear(endOfWeek)}`;

    useEffect(() => {
        const fetchAll=async()=>{
            await dispatch(ThunkGetAllGoalCards(selectedGoals))
        }
        fetchAll()

    }, [dispatch,selectedGoals]);

    return (
    <div id='tbiAll'>
        <div id='tbiImage'>
            <img src={`${process.env.PUBLIC_URL}/images/waitingJob.gif`} alt="waiting office" />
        </div>
        <div id='tbiGoalsWrapper'>
            <div id='tbiGoalsSelection'>
                <div className={`tbiGoalsSelectionPersonal ${selectedGoals === 'Personal' ? 'selectedGoalHighlight' : ''}`} onClick={(e) => setSelectedGoals('Personal')}>
                    Personal
                </div>


                <div className={`tbiGoalsSelectionGroup ${selectedGoals === 'Group' ? 'selectedGoalHighlight' : ''}`} onClick={(e) => setSelectedGoals('Group')}>
                    Group
                </div>



            </div>
            {goalType?.standard !== undefined? (


            <div id='tbiGoals'>
                <div className='wklyGoals'>
                    {`Weekly Goals ${formattedWeekRange}`}
                </div>
                <div className='weeklyGoalsFit'>
                    <div className='weeklyGoalsLabel'>Standard</div>
                    <div className='weeklyGoalsBar'>
                        <div className='floaterHealthBar'>
                            {`${goalType?.standard} / ${goalType?.standardLimit}`}
                        </div>
                        <div className='actualHealthBar' style={getWidthAndColor(goalType?.standard, goalType?.standardLimit)}>
                        </div>
                    </div>
                </div>
                <div className='weeklyGoalsFit'>
                    <div className='weeklyGoalsLabel'>High-Qual</div>
                    <div className='weeklyGoalsBar'>
                        <div className='floaterHealthBar'>
                            {`${goalType.highQual} / ${goalType.highQualLimit}`}
                        </div>
                        <div className='actualHealthBar' style={getWidthAndColor(goalType?.highQual, goalType?.highQualLimit)}>
                        </div>
                    </div>
                </div>
                <div className='weeklyGoalsFit'>
                    <div className='weeklyGoalsLabel'>{goalType.customGoal3Name}</div>
                    <div className='weeklyGoalsBar'>
                        <div className='floaterHealthBar'>
                            {`${goalType.customGoal3} / ${goalType.customGoal3Limit}`}
                        </div>
                        <div className='actualHealthBar' style={getWidthAndColor(goalType?.customGoal3, goalType?.customGoal3Limit)}>
                        </div>
                    </div>
                </div>
                <div className='weeklyGoalsFit'>
                    <div className='weeklyGoalsLabel'>{goalType.customGoal4Name}</div>
                    <div className='weeklyGoalsBar'>
                        <div className='floaterHealthBar'>
                            {`${goalType.customGoal4} / ${goalType.customGoal4Limit}`}
                        </div>
                        <div className='actualHealthBar' style={getWidthAndColor(goalType?.customGoal4, goalType?.customGoal4Limit)}>
                        </div>
                    </div>
                </div>
                <div className='weeklyGoalsFit'>
                    <div className='weeklyGoalsLabel'>{goalType.customGoal5Name}</div>
                    <div className='weeklyGoalsBar'>
                        <div className='floaterHealthBar'>
                            {`${goalType.customGoal5} / ${goalType.customGoal5Limit}`}
                        </div>
                        <div className='actualHealthBar' style={getWidthAndColor(goalType?.customGoal5, goalType?.customGoal5Limit)}>
                        </div>
                    </div>
                </div>
            </div>):(
            <div className='w-full h-full flex justify-center items-center'> "No goal set" </div>)}

        </div>
    </div>)

}

export default TopRightBrain
