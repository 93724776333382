

import React, { useState, useEffect } from "react";
import { login } from "../../store/session";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from "../../context/Modal";
import GoogleAuthButton from "../GoogleAuthButton";
import DOMPurify from "dompurify";
import { MakeButton, changeFromTo } from "../utils";
import { ThunkGetJobs, actionMoveJob } from "../../store/jobs";
import Applied from "../StandardApplied";
import isMobile from "is-mobile";
import io from 'socket.io-client';
import { makeJob } from "../../store/jobs";
import Rejected from "../Rejected";
import DetailsModal from "../showDetailsModal";
import Offer from "../Offer";
import GoalApplied3 from "../GoalApplied3";
import { ThunkNewGoal,ThunkGetGoalCardsNumber } from "../../store/goals";


// import Next_Step from "../Next_Step";

// import "./LoginForm.css";
import "../Homepage/homepage.css"
import '../Homepage/mobileHomepage.css'


function CompletedGoalSubmit({ selectedGoals, goalType }) {
    const [selectedGoalForFormInput, setSelectedGoalForFormInput] = useState('');
  const [textInput, setTextInput] = useState('');
  const dispatch=useDispatch()
  const personalGoals= useSelector((state) => state.goals.personalGoals);
    const handleSubmitForGoalForm = async(e) => {
        e.preventDefault();


        if (personalGoals.id){

        // console.log(`Goal: ${selectedGoalForFormInput}, Text: ${textInput}`);
        await dispatch(ThunkNewGoal({type: selectedGoalForFormInput,description: textInput,goalType:selectedGoals}))}
        else {
          alert('Hey!  You have to set atleast your personal Goals first.  You can do so from the dropdown in the top right.')
        }

        // const sendPackage={"number": parseInt(goalObject.number),"selectedGoals": selectedGoals}
        //   await dispatch(ThunkGetGoalCardsNumber(sendPackage));
        // const ourNumberPull= goalType.customGoal3Name===selectedGoalForFormInput? 3 :goalType.customGoal4Name===selectedGoalForFormInput? 4 : goalType.customGoal5Name===selectedGoalForFormInput? 5 : null

        // console.log('OUR NUMBER PULL DAWG IS GOING TO BE',ourNumberPull)
        // Implement the submission logic here.
      };

      const handleClear = () => {
        setSelectedGoalForFormInput('');
        setTextInput('');
      };



return (<form id='goalForm' onSubmit={handleSubmitForGoalForm}>
  <div className="form-group">
    <label htmlFor="goalSelect">
      Which Goal Did you Complete?
    </label>
    <select
      id="goalSelect"
      value={selectedGoalForFormInput}
      onChange={(e) => setSelectedGoalForFormInput(e.target.value)}
    >
      <option value="">Select a goal</option>
      <option value={goalType.customGoal3Name}>{goalType.customGoal3Name}</option>
      <option value={goalType.customGoal4Name}>{goalType.customGoal4Name}</option>
      <option value={goalType.customGoal5Name}>{goalType.customGoal5Name}</option>
    </select>
  </div>
  <div className="form-group">
    <label htmlFor="goalInput">
      Details (Optional):
    </label>
    <textarea
      id="goalInput"
      value={textInput}
      onChange={(e)=> setTextInput(e.target.value)}
      // value and onChange handler here
    />
  </div>
  <div className="form-actions">
    <div className='w-[50%] h-full flex justify-center items-center'>
      <div className='formHoldButtonz'>
  <MakeButton
                                                        onClick={handleClear}
                                                        text='Clear'
                                                        fontSize='1vw'
                                                    /></div>
                                                    </div>
  {/* <button type="button" onClick={handleClear}>Clear</button> */}
  <div className='w-[50%] h-full flex justify-center items-center'>
    {/* <button type="submit">Submit</button> */}
    <div className='formHoldButtonz'>
    <MakeButton
                                                        type='Submit'
                                                        // onClick={(e)=>{handleSubmitForGoalForm()}}
                                                        text='Submit'
                                                        fontSize='1vw'
                                                    /></div>
    </div>

  </div>
</form>)

}

export default CompletedGoalSubmit
