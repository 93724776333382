import React, { useState,useEffect } from 'react';
import { useDispatch } from 'react-redux'; // Assume you have an action creator for saving goals
import { useModal } from '../../context/Modal';
import { ThunkSetGoals } from '../../store/goals';
import { MakeButton } from '../utils';
import { useSharedSetters } from '../../context/SharedSetters';
import ErrorsModal from '../ErrorsModal';
import './setGoalsModal.css'

function GoalSettingModal({formType,setOrEdit}) {
  const dispatch = useDispatch();
  const { closeModal } = useModal();
  const [typeGoals, setTypeGoals] = useState(formType==='Personal'?'Personal':'Group');
  const [standardLimit, setStandardLimit] = useState(0);
  const [highQualLimit, setHighQualLimit] = useState(0);
  const [customGoal3Name, setCustomGoal3Name] = useState('');
  const [customGoal3Limit, setCustomGoal3Limit] = useState(0);
  const [customGoal4Name, setCustomGoal4Name] = useState('');
  const [customGoal4Limit, setCustomGoal4Limit] = useState(0);
  const [customGoal5Name, setCustomGoal5Name] = useState('');
  const [customGoal5Limit, setCustomGoal5Limit] = useState(0);
  const [passwordSharing,setPasswordSharing] = useState('')
  const [selectedPrivacy,setSelectedPrivacy] = useState('Private')
  const [namePublicGroup,setNamePublicGroup] = useState('')
  const [descriptionPublic,setDescriptionPublic]=useState('')
  const {updateNewGroupMade}=useSharedSetters()
  const [errors, setErrors] = useState({});
  const { setModalContent } = useModal();

  const validateForm = () => {
    let newErrors = {};

    // Check each goal limit for valid input
    const goals = [
      { value: standardLimit, name: 'standardLimit' },
      { value: highQualLimit, name: 'highQualLimit' },
      { value: customGoal3Limit, name: 'customGoal3Limit' },
      { value: customGoal4Limit, name: 'customGoal4Limit' },
      { value: customGoal5Limit, name: 'customGoal5Limit' },
    ];

    goals.forEach(goal => {
      if (!goal.value || goal.value <= 0) {
        newErrors[goal.name] = `The field must be greater than 0.`;
      }
    });

    // Validate custom goal names to ensure they are not empty
    const goalNames = [
      { value: customGoal3Name, name: 'customGoal3Name' },
      { value: customGoal4Name, name: 'customGoal4Name' },
      { value: customGoal5Name, name: 'customGoal5Name' },
    ];

    goalNames.forEach(goal => {
      if (!goal.value.trim()) {
        newErrors[goal.name] = `Custom Goal Name cannot be empty.`;
      } else if (goal.value.length > 20) {
        newErrors[goal.name] = `Custom Goal Name must be 20 characters or less.`;
      }
    });

    if (typeGoals === 'Group' && selectedPrivacy==='Private' && !passwordSharing.trim()) {
      newErrors['passwordSharing'] = "Password for sharing is required for Group goals.";
    } else if (typeGoals === 'Group' && selectedPrivacy==='Private' && (passwordSharing.length < 6 || passwordSharing.length > 64)) {
      // New validation rule for password length
      newErrors['passwordSharing'] = "FE Password must be between 6 and 64 characters long.";
    }

    if (selectedPrivacy === 'Public') {
      if (!namePublicGroup.trim() || namePublicGroup.length < 3 || namePublicGroup.length > 30) {
        newErrors['namePublicGroup'] = "Name of Group must be between 3 and 30 characters.";
      }
      if (!descriptionPublic.trim() || descriptionPublic.length < 6 || descriptionPublic.length > 64) {
        newErrors['descriptionPublic'] = "Description of Group must be between 6 and 64 characters.";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const handleSubmit = async (e) => {
    // e.preventDefault();

    if (!validateForm()) return;

    const returnObj= {
        selectedPrivacy,typeGoals,standardLimit,highQualLimit,customGoal3Name,customGoal3Limit,customGoal4Name,customGoal4Limit,customGoal5Name,customGoal5Limit,passwordSharing
    }

    if (selectedPrivacy==='Public'){
      returnObj['namePublicGroup']=namePublicGroup
      returnObj['descriptionPublic']=descriptionPublic
    }

    const test=await dispatch(ThunkSetGoals(returnObj))

    if (test.errors){
      setErrors(test.errors)
    }
    else {
    if (typeGoals==='Group' && formType==='Group'){
      updateNewGroupMade(true)
    }

    closeModal();
  }
    // Assume you have a function to dispatch goal setting action
    // dispatch(setGoals({ typeGoals, standardLimit, highQualLimit, customGoal3Name, customGoal3Limit, customGoal4Name, customGoal4Limit, customGoal5Name, customGoal5Limit }));

  };

  const setSoftware= ()=> {
    setStandardLimit(10);
    setHighQualLimit(15)
    setCustomGoal3Name('OutReach')
    setCustomGoal3Limit(15)
    setCustomGoal4Name('DSA')
    setCustomGoal4Limit(5)
    setCustomGoal5Name('Events')
    setCustomGoal5Limit(2)
  }

  const clearFields = () => {
    setTypeGoals(formType === 'Personal' ? 'Personal' : 'Group'); // Resetting to initial formType
    setStandardLimit(0);
    setHighQualLimit(0);
    setCustomGoal3Name('');
    setCustomGoal3Limit(0);
    setCustomGoal4Name('');
    setCustomGoal4Limit(0);
    setCustomGoal5Name('');
    setCustomGoal5Limit(0);
    setPasswordSharing('');
  };

  // useEffect(() => {
  //   if (Object.keys(errors).length >= 1) {
  //     setModalContent(<ErrorsModal errors={errors} modalStyle={{ width: '20%', height: '53%', overflow: 'auto', borderRadius: '2em', zIndex:20000000000000 }} />);
  //   }
  // }, [errors, setModalContent]);

  console.log('setOrEdit equals',setOrEdit)
  return (
    <>
    {Object.keys(errors).length >= 1 && (
      <ErrorsModal errors={errors} setErrors={setErrors}   />)}
    <div id='headerSetGoals'>
    {setOrEdit==='Set'?'Set':'Edit'} Your Goals
      </div>
      <div id='explanationEngineer'>
      <div id='sweetSpotEngineerTop'>

          <div id='sspot1'>
            <p>If you are an aspiring Software Engineer,</p>
            <p>Please feel free to click this button</p>
            <p>To auto-fill all fields.</p>
            </div>
            <div id='sspot2'>
            <div id='customButton'>
          <MakeButton
                                                        onClick={(e)=>setSoftware()}
                                                        text='Software'
                                                        fontSize='1.4vw'
                                                    />
                                                    </div>
                                                    </div>

          </div>
          {/* <div id='sweetSpotEngineerBottom'>
            <div id='customButton'>
          <MakeButton
                                                        onClick={(e)=>setSoftware()}
                                                        text='Software'
                                                        fontSize='1.4vw'
                                                    />
                                                    </div>
          </div> */}
        {/* <div id='sweetSpotEngineer'>


        </div> */}
      </div>
      {/* <button type='button' onClick={(e)=>setSoftware()}> Click here for Software Engineer Special</button> */}
      <div id='wrapFormAndPic'>

        <div id='picForm'>
        <img id='dogCup' src={`${process.env.PUBLIC_URL}/images/dogTail.gif`}/>
        </div>
      <form id='ourForm' onSubmit={handleSubmit}>
  <div className='taylorForm'>
    <label>Amount of Standard Applications Per week:</label>
    <input type="number" value={standardLimit} onChange={(e) => setStandardLimit(parseInt(e.target.value))} />
  </div>
  <div className='taylorForm'>
    <label>Amount of High Quality Applications per Week:</label>
    <input type="number" value={highQualLimit} onChange={(e) => setHighQualLimit(parseInt(e.target.value))} />
  </div>
  <div className='taylorForm'>
    <label>Customizable Goal Name:</label>
    <input type="text" value={customGoal3Name} onChange={(e) => setCustomGoal3Name(e.target.value)} placeholder='20 characters or less' />
  </div>
  <div className='taylorForm'>
    <label>Number to strive for:</label>
    <input type="number" value={customGoal3Limit} onChange={(e) => setCustomGoal3Limit(parseInt(e.target.value))} />
  </div>
  <div className='taylorForm'>
    <label>Customizable Goal Name:</label>
    <input type="text" value={customGoal4Name} onChange={(e) => setCustomGoal4Name(e.target.value)} placeholder='20 characters or less' />
  </div>
  <div className='taylorForm'>
    <label>Number to strive for:</label>
    <input type="number" value={customGoal4Limit} onChange={(e) => setCustomGoal4Limit(parseInt(e.target.value))} />
  </div>
  <div className='taylorForm'>
    <label>Customizable Goal Name:</label>
    <input type="text" value={customGoal5Name} onChange={(e) => setCustomGoal5Name(e.target.value)} placeholder='20 characters or less'/>
  </div>
  <div className='taylorForm'>
    <label>Number to Strive for:</label>
    <input type="number" value={customGoal5Limit} onChange={(e) => setCustomGoal5Limit(parseInt(e.target.value))} />
  </div>

    {typeGoals === 'Group' && (
      <>
      <div id='persOrGroupSG'>
      <div id='smallerPersOrGroupSG'>
          <label id='toggleLabelSG' className={`flex cursor-pointer select-none items-center `}>
              <div id='toggleLeftSideSG' className={selectedPrivacy === "Private" ? 'personalToggleSG' : 'notSelectedToggleSG'}>
                  Private
              </div>

              <div className='relative' id='daddyDivSG'>
                  <input
                      type='checkbox'
                      checked={selectedPrivacy === 'Private'}
                      onChange={() => setSelectedPrivacy(selectedPrivacy === "Private" ? "Public" : "Private")}
                      className='sr-only'
                  />
                  <div id='toggleCenterSG'
                      className={`box block h-20 w-14 rounded-full ${selectedPrivacy === "Public" ? 'bg-primaryz' : 'bg-darkz'}`}
                  ></div>
                  <div
                      id='ourMoverSG' className={`rounded-full bg-white transition ${selectedPrivacy === "Public" ? "translate-x-[135%]" : ''}`}
                  ></div>
              </div>
              <div id='toggleLeftSideSG' className={selectedPrivacy === "Public" ? 'personalToggleSG' : 'notSelectedToggleSG'}>
                  Public
              </div>
          </label>
      </div>
  </div>
  {selectedPrivacy==='Private' && (
    <div className='taylorForm'>
        <label>Password For Sharing:</label>
        <input type="text" value={passwordSharing} onChange={(e) => setPasswordSharing(e.target.value)} placeholder='(between 6-64 characters)'/>
        </div>
  )}
  {selectedPrivacy==='Public' && (
    <>
    <div className='taylorForm'>
        <label>Name of Group:</label>
        <input type="text" value={namePublicGroup} onChange={(e) => setNamePublicGroup(e.target.value)} placeholder='(between 3-30 characters)'/>
        </div>
        <div className='taylorForm'>
        <label>Description of Group:</label>
        <input type="text" value={descriptionPublic} onChange={(e) => setDescriptionPublic(e.target.value)} placeholder='(between 6-64 characters)'/>
        </div>
        </>
  )}

        </> )}




</form>
</div>

<div id='bottleOfForm'>

    <div id='customButton2'>

      <MakeButton
                                                          onClick={clearFields}
                                                          text='clear'
                                                          fontSize='1.4vw'
                                                      />
      </div>

    <div id='customButton2'>

    <MakeButton
                                                        onClick={(e)=>{
                                                          handleSubmit()
                                                        }}
                                                        text='Submit'
                                                        fontSize='1.4vw'
                                                    />
    </div>
    {/* <button type="submit">Set Goals</button> */}
    </div>

    </>
  );
}

export default GoalSettingModal;
