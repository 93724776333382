import React from 'react';
import { useModal } from '../../context/Modal';

export const OpenModalWithStyledButton = ({
    buttonText, // text of the button that opens the modal
    modalComponent, // component to render inside the modal
    modalStyle, // custom style for the modal
    ...buttonProps // additional props for the button
}) => {
    const { setModalContent, setModalStyle } = useModal();

    const onClick = () => {
        if (modalStyle) setModalStyle(modalStyle); // Set the modal's custom style
        setModalContent(modalComponent);
        if (buttonProps.onClick) buttonProps.onClick(); // Call the onClick function from additional buttonProps
    };

    let imageSrc = `${process.env.PUBLIC_URL}/images/Blue-Button.png`;

    return (
        <button
            type="button"
            className="realButtonz"
            onClick={onClick}
            {...buttonProps} // Spread any additional buttonProps
        >
            <img
                src={imageSrc}
                alt={buttonText}
                className="object-contain w-full h-[auto]" // Adjust the width and height as needed
            />
            {buttonText === "loading" ? (
                <img
                    src={`${process.env.PUBLIC_URL}/images/loading.gif`}
                    className="absolute"
                    style={{
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        fontSize: buttonProps.fontSize ? buttonProps.fontSize : '3.5vw'
                    }}
                />
            ) : (
                <p className="buttonMaster" style={{ fontSize: buttonProps.fontSize }}>{buttonText}</p>
            )}
        </button>
    );
};
