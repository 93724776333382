
import React, { useState, useEffect } from "react";
import { login } from "../../store/session";
import { useDispatch, useSelector } from "react-redux";

import DOMPurify from 'dompurify';
import { MakeButton } from "../utils";

import '../Homepage/homepage.css'
// import './appliedCard.css'
// import './MobileappliedCard.css'
import { useDrag } from "react-dnd";
import isMobile from "is-mobile";


function AppliedGoal({actual,job,setToggleDetailsMobile,source}) {


    const [{ isDragging }, dragRef] = useDrag(() => ({
        type: 'GOAL_CARD',
        item: { id: job,source,target:"GOAL_CARD" },
        end: (item, monitor) => {
            const didDrop = monitor.didDrop();
        if (!didDrop) {
            return
        }
        },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));

    // const cleanHtml = DOMPurify.sanitize(actual[job]?.['description'])
    // const actual=useSelector(state => state.standards.standards)

    const handleScroll = () => {
        let categoriesContainer
        if (isMobile()) {

            categoriesContainer = document.querySelector('#lower3');
        } else {
            categoriesContainer = document.querySelector('#allCats2');
        }
            const mobileHomeScrollbar = document.querySelector('.mobileHomeScrollbar');

            const scrollbarContainer = document.querySelector('.mobileHomeScrollbar-container');
            const scrollWidth = categoriesContainer.scrollWidth - categoriesContainer.clientWidth;
            const scrollFraction = categoriesContainer.scrollLeft / scrollWidth;
            const scrollbarWidthFraction = mobileHomeScrollbar.offsetWidth / scrollbarContainer.offsetWidth;
            const scrollbarPosition = scrollFraction * (scrollbarContainer.offsetWidth - mobileHomeScrollbar.offsetWidth);
            mobileHomeScrollbar.style.transform = `translateX(${scrollbarPosition}px)`;
        };

    // useEffect(() => {
    //     let categoriesContainer
    //     if (isMobile()) {
    //         categoriesContainer = document.querySelector('#lower3');
    //     }
    //     else {
    //         categoriesContainer = document.querySelector('#allCats2');
    //     }


    //         const mobileHomeScrollbar = document.querySelector('.mobileHomeScrollbar');

    //         const scrollbarContainer = document.querySelector('.mobileHomeScrollbar-container');
    //         let isDown = false;
    //         let startX;
    //         let scrollLeft;

    //         const startDrag = (e) => {
    //             isDown = true;
    //             startX = (e.pageX || e.touches[0].pageX) - mobileHomeScrollbar.offsetLeft;
    //             scrollLeft = categoriesContainer.scrollLeft;
    //         };

    //         const endDrag = () => {
    //             isDown = false;
    //         };

    //         const doDrag = (e) => {
    //             if (!isDown) return;
    //             e.preventDefault();
    //             const x = (e.pageX || e.touches[0].pageX) - mobileHomeScrollbar.offsetLeft;
    //             const walk = (x - startX) * 1; // Adjust the scroll speed as needed
    //             categoriesContainer.scrollLeft = scrollLeft + walk;
    //         };

    //         mobileHomeScrollbar.addEventListener('mousedown', startDrag);
    //         mobileHomeScrollbar.addEventListener('touchstart', startDrag);
    //         document.addEventListener('mouseup', endDrag);
    //         document.addEventListener('touchend', endDrag);
    //         document.addEventListener('mousemove', doDrag);
    //         document.addEventListener('touchmove', doDrag);

    //         categoriesContainer.addEventListener('scroll', handleScroll);

    //         // Cleanup function to remove event listeners
    //         return () => {
    //             mobileHomeScrollbar.removeEventListener('mousedown', startDrag);
    //             mobileHomeScrollbar.removeEventListener('touchstart', startDrag);
    //             document.removeEventListener('mouseup', endDrag);
    //             document.removeEventListener('touchend', endDrag);
    //             document.removeEventListener('mousemove', doDrag);
    //             document.removeEventListener('touchmove', doDrag);
    //             categoriesContainer.removeEventListener('scroll', handleScroll);
    //         };
    //     }
    // , []);


console.log('WE ARE INSIDE OF APPLIEDGOAL.  IN HERE ACTUAL IS',actual)
console.log('WE ARE INSIDE OF APPLIEDGOAL.  IN HERE JOB IS',job)
console.log('WE ARE INSIDE APPLIED GOAL.  IN HERE ACTUAL AT JOB IS',actual[job])

    return (

<div ref={dragRef} key={job}  className={`newGoalCard newGoalCard-${job} ${isDragging ? 'dragging' : ''}`}
     onDoubleClick={(e) => {
         e.preventDefault();
         setToggleDetailsMobile(actual[job]);
     }}>
                                        {/* KEEP ALL COMMENTED TEXT OUT BELOW.  IN CASE WE WANT TO ADD FIELDS */}
                                        {/* <li>Ident: {displayRelative(job)}</li> */}

                                        {/* <li><div className='payClass'>{actual[job]?.['pay']}</div></li> */}
                                        {/* <li>
                                            <div className='wrapperButtonz'>
                                                <div className='wrapperButtonz1'>
                                                    <MakeButton
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            window.open(actual[job]['link'], '_blank', 'noopener,noreferrer');
                                                        }}
                                                        text='Apply'
                                                    />
                                                    {/* <a href={actual[job]['link']} target="_blank" rel="noopener noreferrer">Apply</a> */}
                                                {/* </div>
                                                <div className='wrapperButtonz2'>


                                                    <MakeButton
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setToggleDetailsMobile(job)
                                                        }}
                                                        text='Details'
                                                    />




                                                </div></div>

                                                </li> */}

                                            <div className='descriptionGoal'>
                                                {actual[job]?.description}
                                            </div>
                                            {/* <div className='buttonsClose'>
                                                <div className='buttonsClose1'>
                                                    <MakeButton
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setToggleDetailsMobile(false)
                                                        }}
                                                        text='Close'
                                                    /></div>
                                            </div> */}

                                    </div>
                                )
                                                    }

                                                    export default AppliedGoal
