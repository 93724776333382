
import React, { createContext, useContext } from 'react';
import io from 'socket.io-client';


// export const socket = io('wss://seekersfriend.com', { transports: ['websocket'] });
//below is good for production 410
export const socket = io('wss://tracker-hog5.onrender.com', { transports: ['websocket'] });
// export const socket= io('http://localhost:5000', { transports: ['websocket'] });
// export const socket = io(process.env.REACT_APP_SOCKET_URL, { transports: ['websocket'] });
const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    );
};



// Custom hook to use the socket
export const useSocket = () => useContext(SocketContext);
