import React, {useState,useEffect} from 'react';
import { NavLink,useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ProfileButton from './ProfileButton';


import './Navigation.css';
import './mobileNavigation.css'
import { useSocket } from '../../context/SocketContext';
import DOMPurify from 'dompurify'
import { useSharedSetters } from '../../context/SharedSetters';
import SlotMachine from '../SlotMachine';

function Navigation({ isLoaded }){
	const history=useHistory()
	const socket = useSocket()
	const sessionUser = useSelector(state => state.session.user);
	const [quote,setQuote]=useState('')
	const [author,setAuthor]=useState('')
	const { viewOfSite, updateViewOfSite } = useSharedSetters();
	console.log('**************************************Socket in Navigation:', socket);
	// const options = ["Site", "Counter", "Calendar"];
	const options = sessionUser?.id?["Counter", "Race","Calendar"]:['Log In Then Click me To Navigate!']
	// useEffect(() => {
	// 	fetch("https://type.fit/api/quotes")
	// 		.then(function(response) {
	// 			return response.json();
	// 		})
	// 		.then(function(data) {
	// 			const randomIndex = Math.floor(Math.random() * data.length);

	// 			const randomQuote = data[randomIndex];
	// 			console.log(randomQuote);
	// 			setQuote(randomQuote.text);
	// 			setAuthor(randomQuote.author);
	// 		});
	// }, []);
	return (
		<div id='Navigation'>

			<div id='leftHome'>
				{/* <NavLink exact to="/">Home</NavLink> */}
				<div id='navImgHolder' >
				<img id='navImg' onClick={(e)=>{history.push('/')}}
          src ={`${process.env.PUBLIC_URL}/images/FLATTENEDOUT.png`} />
		  </div>
				</div>
				{/* <div id='realSpacer'>

				</div> */}
				<div id='blockSpacer'>
                <SlotMachine options={options} />
            </div>


			{isLoaded && (
				<div id='navHolder'>
					<ProfileButton user={sessionUser} />
				</div>
			)}

		</div>
	);
}

export default Navigation;
