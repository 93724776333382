import React from 'react';
import { MakeButton } from '../utils';
import './errorsModal.css'

const ErrorsModal = ({ errors, setErrors, formType }) => {
  return (
    <div
  id={formType === 'Login' ? 'errorsModalLogin' : 'errorsModal'}
  style={formType !== 'Login' ? { borderRadius: '2em' } : {}}
>
<div
  id='errorsModal1'
  style={formType !== 'Login' ? { borderTopRightRadius: '.7em', borderTopLeftRadius: '.7em' } : {}}
>
  Errors
</div>
      <div id='errorsTableContainer'>
        <table>
          {/* <thead>
            <tr>
              <th>Field</th>
              <th>Error Message</th>
            </tr>
          </thead> */}
          <tbody>
            {Object.entries(errors).map(([field, message], index) => (
              <tr key={index}>
                <td id='fieldErr'>{field}</td>
                <td id='messageErr'>{message}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div id='errorsTableBottom' style={formType !== 'Login' ? { borderBottomRightRadius: '1.6em', borderBottomLeftRadius: '1.6em' } : {}}
>
        <div className='errorsButtContainer' >
          <MakeButton
            onClick={(e) => { setErrors({}) }}
            text='Ok'
            fontSize='1vw'
          />
        </div>
      </div>
    </div>
  );
};

export default ErrorsModal;
