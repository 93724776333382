import React, { useState,useEffect } from 'react';
import { useDispatch,useSelector } from 'react-redux'; // Assume you have an action creator for saving goals
import { useModal } from '../../context/Modal';
import { ThunkSetGoals,ThunkEditGoals,ThunkDeleteGoals } from '../../store/goals';
import { MakeButton } from '../utils';
import ErrorsModal from '../ErrorsModal';
import { useSharedSetters } from '../../context/SharedSetters';
import '../SetGoalsModal/setGoalsModal.css'
import './editGoals.css'
import EnemyForRace from '../EnemyForRace';

function EditGoals({formType}) {
  const dispatch = useDispatch();
  const groupGoals=useSelector(state=>state.goals.groupGoals)
  const personalGoals = useSelector(state=>state.goals.personalGoals)
  const { closeModal } = useModal();
  const [typeGoals, setTypeGoals] = useState('');
  const [standardLimit, setStandardLimit] = useState(0);
  const [highQualLimit, setHighQualLimit] = useState(0);
  const [customGoal3Name, setCustomGoal3Name] = useState('');
  const [customGoal3Limit, setCustomGoal3Limit] = useState(0);
  const [customGoal4Name, setCustomGoal4Name] = useState('');
  const [customGoal4Limit, setCustomGoal4Limit] = useState(0);
  const [customGoal5Name, setCustomGoal5Name] = useState('');
  const [customGoal5Limit, setCustomGoal5Limit] = useState(0);
  const [passwordSharing,setPasswordSharing] = useState('')
  const [phase,setPhase]=useState('phase1')
  const [errors, setErrors] = useState({});
  const [confirmDelete,setConfirmDelete] =useState(false)

  const {updateNewGroupMade}=useSharedSetters()

  const validateForm = () => {
    let newErrors = {};

    // Check each goal limit for valid input
    const goals = [
      { value: standardLimit, name: 'standardLimit' },
      { value: highQualLimit, name: 'highQualLimit' },
      { value: customGoal3Limit, name: 'customGoal3Limit' },
      { value: customGoal4Limit, name: 'customGoal4Limit' },
      { value: customGoal5Limit, name: 'customGoal5Limit' },
    ];

    goals.forEach(goal => {
      if (!goal.value || goal.value <= 0) {
        newErrors[goal.name] = `The field must be greater than 0.`;
      }
    });

    // Validate custom goal names to ensure they are not empty
    const goalNames = [
      { value: customGoal3Name, name: 'customGoal3Name' },
      { value: customGoal4Name, name: 'customGoal4Name' },
      { value: customGoal5Name, name: 'customGoal5Name' },
    ];

    goalNames.forEach(goal => {
      if (!goal.value.trim()) {
        newErrors[goal.name] = `Custom Goal Name cannot be empty.`;
      } else if (goal.value.length > 20) {
        newErrors[goal.name] = `Custom Goal Name must be 20 characters or less.`; // New validation rule
      }
    });

    if (typeGoals === 'Group' && !passwordSharing.trim()) {
      newErrors['passwordSharing'] = "Password for sharing is required for Group goals.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleDeleteGoal = async (typeOfGoal) => {
    // e.preventDefault();

    const returnObj= {
        typeGoals: typeOfGoal
    }

    await dispatch(ThunkDeleteGoals(returnObj))
    setTypeGoals('')
    setConfirmDelete(false)


    // if (typeGoals==='Group' && formType==='Group'){
    //   updateNewGroupMade(true)
    // }
    // Assume you have a function to dispatch goal setting action
    // dispatch(setGoals({ typeGoals, standardLimit, highQualLimit, customGoal3Name, customGoal3Limit, customGoal4Name, customGoal4Limit, customGoal5Name, customGoal5Limit }));
    closeModal();
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();
    if (!validateForm()) return;
    const returnObj= {
        typeGoals,standardLimit,highQualLimit,customGoal3Name,customGoal3Limit,customGoal4Name,customGoal4Limit,customGoal5Name,customGoal5Limit,passwordSharing
    }

    let test=await dispatch(ThunkEditGoals(returnObj))

    if(test.errors){
      setErrors(test.errors)
    }
    else {

    // if (typeGoals==='Group' && formType==='Group'){
    //   updateNewGroupMade(true)
    // }
    // Assume you have a function to dispatch goal setting action
    // dispatch(setGoals({ typeGoals, standardLimit, highQualLimit, customGoal3Name, customGoal3Limit, customGoal4Name, customGoal4Limit, customGoal5Name, customGoal5Limit }));
    closeModal();
    }
  };

  const setSoftware= ()=> {
    setStandardLimit(10);
    setHighQualLimit(15)
    setCustomGoal3Name('OutReach')
    setCustomGoal3Limit(15)
    setCustomGoal4Name('DSA')
    setCustomGoal4Limit(5)
    setCustomGoal5Name('Events')
    setCustomGoal5Limit(2)
  }

  const clearFields = () => {
    setTypeGoals(formType === 'Personal' ? 'Personal' : 'Group'); // Resetting to initial formType
    setStandardLimit(0);
    setHighQualLimit(0);
    setCustomGoal3Name('');
    setCustomGoal3Limit(0);
    setCustomGoal4Name('');
    setCustomGoal4Limit(0);
    setCustomGoal5Name('');
    setCustomGoal5Limit(0);
    setPasswordSharing('');
  };

  useEffect(() => {
    // Conditional setting of state based on typeGoals
    if (typeGoals === 'Personal') {
      setStandardLimit(personalGoals?.standardLimit);
      setHighQualLimit(personalGoals?.highQualLimit);
      setCustomGoal3Name(personalGoals?.customGoal3Name);
      setCustomGoal3Limit(personalGoals?.customGoal3Limit);
      setCustomGoal4Name(personalGoals?.customGoal4Name);
      setCustomGoal4Limit(personalGoals?.customGoal4Limit);
      setCustomGoal5Name(personalGoals?.customGoal5Name);
      setCustomGoal5Limit(personalGoals?.customGoal5Limit);
    } else if (typeGoals === 'Group') {
      setStandardLimit(groupGoals?.standardLimit);
      setHighQualLimit(groupGoals?.highQualLimit);
      setCustomGoal3Name(groupGoals?.customGoal3Name);
      setCustomGoal3Limit(groupGoals?.customGoal3Limit);
      setCustomGoal4Name(groupGoals?.customGoal4Name);
      setCustomGoal4Limit(groupGoals?.customGoal4Limit);
      setCustomGoal5Name(groupGoals?.customGoal5Name);
      setCustomGoal5Limit(groupGoals?.customGoal5Limit);
    } else {
      // If typeGoals is neither Personal nor Group, set all to initial values or keep them blank
      setStandardLimit('');
      setHighQualLimit(0);
      setCustomGoal3Name('');
      setCustomGoal3Limit(0);
      setCustomGoal4Name('');
      setCustomGoal4Limit(0);
      setCustomGoal5Name('');
      setCustomGoal5Limit(0);
    }
  }, [typeGoals, personalGoals, groupGoals]);
  if (phase==='phase1') return (
    <div id='mainPhase1'>
        <div id='mp1top'>
            <div id='sweetSpotmp1'>
                <p>Select which goal</p>
                <p>To edit/delete</p>
            </div>
        </div>
        <div id='mp1top2'>
            <div id='explainmp1'>
                <p>Please note that if you change any names</p>
                <p>or delete and recreate goals </p>
                <p>You will have to re-link your goals.</p>
            </div>


        </div>
        {/* <div id='sevenUp'>
        <div class='myDog2'>Personal</div>
        <div class='myDog2'>Group</div>
        </div> */}
        <div id='bothGoalsMp'>
          {confirmDelete && (
            <div id='confirmDeletez'>
              <div id='cdHeader'> </div>
              <div id='cdBody'>
                <div id='cdBodySweet'>
                <p>Are you Sure you want to delete</p><p> your {confirmDelete} Goals?</p>
                </div>
                </div>
              <div id='cdHeader'>
                <div id='cdh1'>
                <MakeButton
                                                        onClick={(e)=>{
                                                          setConfirmDelete(false)
                                                        }}
                                                        text='Cancel'
                                                        fontSize='1vw'
                                                    />
                  </div>
                  <div id='cdh1'>
                  <MakeButton
                                                        onClick={(e)=>{
                                                          handleDeleteGoal(confirmDelete)
                                                          // setTypeGoals("Personal");
                                                          // setPhase("editGoal")
                                                        }}
                                                        text='Confirm'
                                                        fontSize='1vw'
                                                    />

                  </div>
                 </div>
              </div>
          )}
            <div className='myDog'>
              {personalGoals?.id? (<EnemyForRace Instance={personalGoals} exception={"My Goals"}/>):
              (
              <div id='needPersonal'>
                <div id='needPersonalSweet'>
                  <p>No</p>
                  <p>Personal</p>
                  <p>Goals</p>
                  </div>

               </div>
              )}
              </div>
            <div className='myDog'>
              {groupGoals?.id? (<EnemyForRace Instance={groupGoals} exception={"Group Goals"}/>):(
              <div id='needPersonal'>
                <div id='needPersonalSweet'>
                  <p>No</p>
                  <p>Group</p>
                  <p>Goals</p>
                  </div>

               </div>
              )}


              </div>
        </div>
        <div id='lastPiece'>
            <div className  ='myDog3'>
              {personalGoals?.id && (

                <>
                <div className='myDog3Button'>
              <MakeButton
                                                        onClick={(e)=>{
                                                          setTypeGoals("Personal");
                                                          setPhase("editGoal")
                                                        }}
                                                        text='Edit'
                                                        fontSize='1vw'
                                                    />
              </div>
              <div className='myDog3Button'>
              <MakeButton
                                                        onClick={(e)=>{
                                                          // setTypeGoals("Personal")
                                                          setConfirmDelete("Personal")}}
                                                        text='Delete'
                                                        fontSize='1vw'
                                                    />
              </div>


                </>
              )}


            </div>
            <div className  ='myDog3'>
            {groupGoals?.id && (<>
              <div className='myDog3Button'>

              <MakeButton
                                                        onClick={(e)=>{
                                                          setTypeGoals("Group");
                                                          setPhase("editGoal")
                                                        }}
                                                        text='Edit'
                                                        fontSize='1vw'
                                                    />
              </div>
              <div className='myDog3Button'>
              <MakeButton
                                                        onClick={(e)=>{
                                                          setTypeGoals('Group')
                                                          setConfirmDelete("Group")
                                                          // handleDeleteGoal("Group")

                                                        }}
                                                        text='Delete'
                                                        fontSize='1vw'
                                                    />
              </div>
              </>)}

            </div>
        </div>
        </div>
  )

  if (phase==='editGoal')return (
    <>
    {Object.keys(errors).length >= 1 && (
      <ErrorsModal errors={errors} setErrors={setErrors}   />)}
    <div id='headerSetGoals'>
    Edit Your Goals
      </div>
      <div id='explanationEngineer'>
      <div id='sweetSpotEngineerTop'>

          <div id='sspot1'>
            <p>If you are an aspiring Software Engineer,</p>
            <p>Please feel free to click this button</p>
            <p>To auto-fill all fields.</p>
            </div>
            <div id='sspot2'>
            <div id='customButton'>
          <MakeButton
                                                        onClick={(e)=>setSoftware()}
                                                        text='Software'
                                                        fontSize='1.4vw'
                                                    />
                                                    </div>
                                                    </div>

          </div>
          {/* <div id='sweetSpotEngineerBottom'>
            <div id='customButton'>
          <MakeButton
                                                        onClick={(e)=>setSoftware()}
                                                        text='Software'
                                                        fontSize='1.4vw'
                                                    />
                                                    </div>
          </div> */}
        {/* <div id='sweetSpotEngineer'>


        </div> */}
      </div>
      {/* <button type='button' onClick={(e)=>setSoftware()}> Click here for Software Engineer Special</button> */}
      <div id='wrapFormAndPic'>

        <div id='picForm'>
        <img id='dogCup' src={`${process.env.PUBLIC_URL}/images/dogTail.gif`}/>
        </div>
      <form id='ourForm' onSubmit={handleSubmit}>
  <div className='taylorForm'>
    <label>Amount of Standard Applications Per week:</label>
    <input type="number" value={standardLimit} onChange={(e) => setStandardLimit(parseInt(e.target.value))} />
  </div>
  <div className='taylorForm'>
    <label>Amount of High Quality Applications per Week:</label>
    <input type="number" value={highQualLimit} onChange={(e) => setHighQualLimit(parseInt(e.target.value))} />
  </div>
  <div className='taylorForm'>
    <label>Customizable Goal Name:</label>
    <input type="text" value={customGoal3Name} onChange={(e) => setCustomGoal3Name(e.target.value)} />
  </div>
  <div className='taylorForm'>
    <label>Number to strive for:</label>
    <input type="number" value={customGoal3Limit} onChange={(e) => setCustomGoal3Limit(parseInt(e.target.value))} />
  </div>
  <div className='taylorForm'>
    <label>Customizable Goal Name:</label>
    <input type="text" value={customGoal4Name} onChange={(e) => setCustomGoal4Name(e.target.value)} />
  </div>
  <div className='taylorForm'>
    <label>Number to strive for:</label>
    <input type="number" value={customGoal4Limit} onChange={(e) => setCustomGoal4Limit(parseInt(e.target.value))} />
  </div>
  <div className='taylorForm'>
    <label>Customizable Goal Name:</label>
    <input type="text" value={customGoal5Name} onChange={(e) => setCustomGoal5Name(e.target.value)} />
  </div>
  <div className='taylorForm'>
    <label>Number to Strive for:</label>
    <input type="number" value={customGoal5Limit} onChange={(e) => setCustomGoal5Limit(parseInt(e.target.value))} />
  </div>

    {typeGoals === 'Group' && (
      <div className='taylorForm'>
        <label>Password For Sharing:</label>
        <input type="text" value={passwordSharing} onChange={(e) => setPasswordSharing(e.target.value)} />
        </div>
    )}




</form>
</div>

<div id='bottleOfForm'>

    <div id='customButton2'>

      <MakeButton
                                                          onClick={clearFields}
                                                          text='clear'
                                                          fontSize='1.4vw'
                                                      />
      </div>

    <div id='customButton2'>

    <MakeButton
                                                        onClick={(e)=>{
                                                          handleSubmit()
                                                        }}
                                                        text='Submit'
                                                        fontSize='1.4vw'
                                                    />
    </div>
    {/* <button type="submit">Set Goals</button> */}
    </div>

    </>
  );
}

export default EditGoals;
