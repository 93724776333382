import React from 'react';
import './privacy.css'

const PrivacyPolicy = () => {
  return (
    <div id='privacyWrapper'>
        <div id='privacySweet'>
      <h1>Privacy Policy</h1>
      <p>Last updated: 4/8/2024</p>

      <p>At Seeker's Friend, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines our practices regarding the collection, use, and disclosure of your information when you use our service and the choices you have associated with that information.</p>

      <p>This policy is effective as of 4/8/2024 and was last updated on 4/8/2024.</p>

      <h2>Information Collection And Use</h2>
      <p>We collect the following types of information for various purposes to provide and improve our service to you:</p>

      <h3>Types of Data Collected</h3>
      <h4>Personal Data</h4>
      <p>While using our service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:</p>
      <ul>

        <li>Email address</li>
        <li>First name and last name</li>
        <li>Google profile information (including your profile picture)</li>
      </ul>

      <h4>Google Calendar Access</h4>
      <p>To enhance your experience, our application requests access to your Google Calendar. This access enables you to edit and upload events directly within our platform. Please note that we do not store your calendar information on our servers. The integration is designed to streamline your event management by leveraging your existing Google Calendar.</p>

      <h2>Use of Data</h2>
      <p>Seeker's Friend uses the collected data for various purposes:</p>
      <ul>
        <li>To provide and maintain our service</li>
        <li>To notify you about changes to our service</li>
        <li>To allow you to participate in interactive features of our service when you choose to do so</li>
        <li>To provide customer support</li>
        <li>To gather analysis or valuable information so that we can improve our service</li>
        <li>To monitor the usage of our service</li>
        <li>To detect, prevent, and address technical issues</li>
      </ul>

      <p>All of this information we have henceforth mentioned....it will be shared with our backend onrender.com.   tracker-hog5.onrender.com.  we are not doing anything bad....we are just trying to provide you with a service.  once again, none of your private calendar information will ever hit our database or be stored.. </p>

      <h2>Changes to This Privacy Policy</h2>
      <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>

      <p>If you have any questions about this Privacy Policy, please contact us.</p>
    </div>
    </div>
  );
};

export default PrivacyPolicy;
