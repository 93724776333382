import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThunkGetPublicGoals } from '../../store/goals';
import { ThunkGetFriendsGoals } from '../../store/goals';
import { MakeButton } from '../utils';
import { useModal } from "../../context/Modal";
import { useSharedSetters } from "../../context/SharedSetters";

import './publicGoals.css'

const PublicGoals = () => {
    const [state, setState] = useState(''); // Initial state
    const dispatch = useDispatch();         // For dispatching actions
    const history = useHistory();           // For navigating to different routes
    const public_goals= useSelector((state) => state.goals.public_goals);
    const [viewIndepth,setViewIndepth] =useState(false)
    const { closeModal } = useModal();
    const {updateNewGroupMade}= useSharedSetters()
    useEffect(() => {

        const getPublic=async()=>{
            let test=await dispatch(ThunkGetPublicGoals())


        }
        // return () => {
        //     // Cleanup code here
        // };
        getPublic()
    }, []);  // Dependency array

    const handleChange = (event) => {
        setState(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch({ type: 'ACTION_TYPE', payload: state }); // Dispatch action
        history.push('/some-route');  // Navigate to another route
    };

    console.log(public_goals)

    return (
        <div id='publicContainer'>
            <div id='slicePublic'>
                <div id='purrfect'>
            Public Groups
            </div>
            <div id='purrfect2'>
                Select a group to join
            </div>
            </div>
            <div id='publicInner'>

        {viewIndepth && (
            <div id='overlayDetails'>
            <table className="detailsTable">
        <tbody>
            <tr>
                <td className="category">Standard</td>
                <td className="value">{public_goals[viewIndepth-1]?.goal?.standardLimit}</td>
            </tr>
            <tr>
                <td className="category">High-Qual:</td>
                <td className="value">{public_goals[viewIndepth-1]?.goal?.highQualLimit}</td>
            </tr>
            <tr>
                <td className="category">{public_goals[viewIndepth-1]?.goal?.customGoal3Name}</td>
                <td className="value">{public_goals[viewIndepth-1]?.goal?.customGoal3Limit}</td>
            </tr>
            <tr>
                <td className="category">{public_goals[viewIndepth-1]?.goal?.customGoal4Name}</td>
                <td className="value">{public_goals[viewIndepth-1]?.goal?.customGoal4Limit}</td>
            </tr>
            <tr>
                <td className="category">{public_goals[viewIndepth-1]?.goal?.customGoal5Name}</td>
                <td className="value">{public_goals[viewIndepth-1]?.goal?.customGoal5Limit}</td>
            </tr>
        </tbody>
    </table>
    <div id='baseButtonz'>
        <div id='bbButton'>
        <MakeButton
                                                        onClick={(e)=>{
                                                       setViewIndepth(false)
                                                        }}
                                                        text='Close'
                                                        fontSize='1.2vw'
                                                    />

             </div>

         </div>

            </div>
        )}

                <div id='piSweet'>
                {public_goals.map((ele,index)=>{

console.log(ele);
return (
    <div key={index} id='pgCard'>

        <div id='infoOrAction'>

            <button className='squareButton' onClick={(e)=>{
                setViewIndepth(index+1)
            }}>see Goals</button>

        </div>

        <div id='pgCardInner'>
            <div id='pgciName'>
            {ele.name}
            </div>
            <div id='pgciDescription'>
                {ele.description}
                </div>



        </div>

        <div id='infoOrAction'>
        <button className='squareButton' onClick={async(e)=>{

            console.log(ele?.goal_id)
            let temper= ele?.goal_id
            const returnObj= {
                goalId:temper,password:'specialpass9876543210'
            }

            let test=await dispatch(ThunkGetFriendsGoals(returnObj))

            if (test){
                console.log('WHAT THE HECK IS TEST',test)
              closeModal();
              updateNewGroupMade(true)
              }
              else {
              alert('Something unexpected happened')
              }
        }}>Enroll</button> </div>

        </div>
)
})}
                </div>


            </div>

            <div id='slicePublic'>

                </div>
            </div>
    );
};

export default PublicGoals;
