import React, { useState, useEffect,useRef,useCallback } from "react";
import { login } from "../../store/session";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from "../../context/Modal";
import GoogleAuthButton from "../GoogleAuthButton";
import DOMPurify from "dompurify";
import { MakeButton, changeFromTo,changeGoalFromToUtil } from "../utils";
import { ThunkGetJobs, actionMoveJob } from "../../store/jobs";
import Applied from "../StandardApplied";
import isMobile from "is-mobile";
import io from 'socket.io-client';
import { makeJob } from "../../store/jobs";
import Rejected from "../Rejected";
import DetailsModal from "../showDetailsModal";
import Offer from "../Offer";
import GoalApplied3 from "../GoalApplied3";
import TopRightBrain from "../TopRightBrain";
import OpenModalButton from "../OpenModalButton";
import { OpenModalWithStyledButton } from "../OpenModalWithStyle";
import { SocketProvider, useSocket } from "../../context/SocketContext";
// import Next_Step from "../Next_Step";
import { useSharedSetters } from "../../context/SharedSetters";
import LoginFormModal from "../LoginFormModal";
import SignupFormModal from "../SignupFormModal";
// import "./LoginForm.css";
import "./homepage.css";
import './mobileHomepage.css'

import { showDetails, restoreNormal,showDetailsDesktop } from "./mobileScript";
import { useDrag, useDrop } from "react-dnd";
import JobCard from "../JobCard";
import { ThunkGetStandards } from "../../store/standardApps";
import HighQualApplied from "../HighQualApplied";
import { ThunkGetHighQuals } from "../../store/highqualApps";
import { ThunkGetWaitings } from "../../store/waiting";
import WaitingApplied from "../WaitingApplied";
import Next_Step from "../Next_Step";
import { ThunkGetGoals, ThunkNewGoal, actionDestroyGroup } from "../../store/goals";
import CompletedGoalSubmit from "../CompletedGoalSubmit";
import GoalApplied4 from "../GoalApplied4";
import GoalApplied5 from "../GoalApplied5";
import { actionUpdateSpecificLive } from "../../store/goals";
import LinkGoalsModal from "../LinkGoalsModal";
import CounterHome from "../CounterHome";
import MyCalendar from "../Calendar";
import Overlay from "../Overlay";
import FirstLogin from "../FirstLogin";
import RaceToFinish from "../RaceToFinish";
// import '../../../public/Scrollbar'


function Homepage() {
  const dispatch = useDispatch();
  const jobs = useSelector((state) => state.jobs);
  const jobsAll = useSelector((state)=>state.jobs.allJobs)
  const user = useSelector((state) => state.session.user);
  //need to update the prop here for all of them
  const [forceRender, setForceRender] = useState(0);
  const actual = useSelector((state) => state.jobs.jobs);
  const firstTime= useSelector((state) => state.session.user?.first_time);
  // const standards= useSelector(state=>state.standards.standards)
  const personalGoals= useSelector((state) => state.goals.personalGoals);
  const groupGoals= useSelector((state) => state.goals.groupGoals);
  const {newGroupMade,updateNewGroupMade,viewOfSite,updateViewOfSite,raceView,updateRaceView,audio,updateAudio}=useSharedSetters()
  // const [newGroupHasMade,setNewGroupHasMade]=useState(newGroupMade)
  const { setModalContent } = useModal();
  const [toggleDetailsMobile, setToggleDetailsMobile] = useState(false);
  const {selectedGoals,setSelectedGoals,calendarView,updateCalendarView}=useSharedSetters()
  const {overlay,updateOverlay}=useSharedSetters()
  const [selectedSelector,setSelectedSelector]=useState('Queue')
  const [sounds, setSounds] = useState({});

  const socket=useSocket()
  const playSound = useCallback((soundName) => {
    const sound = sounds[soundName];
    if (audio && sound) {
      sound.play().catch(error => console.error(`Error playing sound: ${error}`));
    }
  }, [audio, sounds]);

  const [, dropRef] = useDrop({
    accept: ['JOB_CARD','GOAL_CARD'],
    drop: async (item, monitor) => {
      console.log("Dropped item:", item);
      if (item.target === 'JOB_CARD') {
        console.log('^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ deleting a job card')
          console.log("Dropping a JOB_CARD");
          await changeCardFromTo(item, 'Delete');
      } else if (item.target === 'GOAL_CARD') {
          console.log("Dropping a GOAL_CARD");
          await changeGoalFromTo(item, 'Delete');
      }
  },
    collect: (monitor) => ({
        isOver: !!monitor.isOver(),
    }),
});
useEffect(() => {
  Object.values(sounds).forEach(sound => {
      sound.volume = !audio ? 0 : 1;
  });

  if (audio) playSound('unMute')



}, [audio, sounds,playSound]);

useEffect(() => {
  if (user) {
      setSounds({
          unMute: new Audio(`${process.env.PUBLIC_URL}/sounds/unmuted.mp3`),
          Message: new Audio(`${process.env.PUBLIC_URL}/sounds/message.mp3`),
          newGoal: new Audio(`${process.env.PUBLIC_URL}/sounds/register.mp3`),
          friendsGoal: new Audio(`${process.env.PUBLIC_URL}/sounds/dog_bark.mp3`)
      });
  }
}, [user]);

useEffect(() => {
  // Check if socket and group ID are available to setup message listener
  if (socket && groupGoals.group_goal_id) {
      // Define a consistent reference for the event handler function
      const handleMessageReceived = (message) => {
          console.log('Message received:', message);
          playSound('Message'); // Call playSound with the message type or any identifier
      };

      // Setup listener for messages received
      socket.on('message_received', handleMessageReceived);

      // Cleanup function to remove the message listener
      return () => {
          // Use the same reference for the event handler function here
          socket.off('message_received', handleMessageReceived);
      };
  }
}, [socket, groupGoals.group_goal_id,playSound]);


useEffect(() => {
  console.log(selectedGoals); // To check if updates are reflected
}, [selectedGoals]);

useEffect(() => {
  // This is just a placeholder for when you want to force re-render.
  // You could tie this effect to specific conditions or actions.
}, [forceRender]);

// useEffect(() => {
//   // Directly check user.first_time; assuming user is already fetched and available
//   if (user?.id && user?.first_time) {
//     setModalContent(<FirstLogin />);
//     // Consider setting user.first_time to false here or wherever appropriate
//     // to prevent the modal from showing again on subsequent renders.
//     // You might need to dispatch an action to update this in your global state.
//   }
// }, [user, setModalContent]);

// useEffect(() => {
//   if (raceView){
//     setModalContent(<RaceToFinish modalStyle={{ width: '77%', height: '94%', overflow: 'auto', borderRadius: '2em' }} />)
//   }
// }, [raceView,setModalContent]);




  //BELOW ARE ALL THE FUNCTIONS TO CHANGE FROM ONE TO ANOTHER

  const changeCardFromTo = async (item, destination) => {
    if (personalGoals?.id){
    await changeFromTo(dispatch, item.id, item.source, destination)}
    else {
      alert('Hey!  You have to set atleast your personal Goals first.  You can do so from the dropdown in the top right.')
    }
  };

  const changeGoalFromTo = async (item, destination) => {
    if (personalGoals?.id){
    await changeGoalFromToUtil(dispatch, item.id, item.source, destination,selectedGoals)}
    else {
      alert('Hey!  You have to set atleast your personal Goals first.  You can do so from the dropdown in the top right.')
    }
  };








  const goalType = selectedGoals === 'Personal' ? personalGoals : groupGoals;
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "JOB_CARD",
    drop: async (item, monitor) => {
      // Logic to handle item drop
      console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^HOMEPAGE 57 THIS RIGHT DROP?");
      console.log("RIGHT NOW ITEM LOOKS LIKE", item);
      await changeCardFromTo(item, "Kwey");
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  useEffect(() => {
    // Function to handle the "force_update_goals" event
    const handleForceUpdateGoals = async () => {
      console.log('Received force update goals event. Fetching latest goals...');
      // Call your thunk or action creator to fetch the latest goals data
      await dispatch(ThunkGetGoals());
    };

    // Check if the socket is connected before setting up the listener
    if (socket) {
      socket.on('force_update_goals', handleForceUpdateGoals);
    }

    // Cleanup: Although you specified not to include a cleanup, it's still good practice
    // to consider whether you might need to remove the listener under certain conditions
    // to prevent memory leaks or unintended behavior.
    // Here's how you would remove the listener if you decide it's necessary:
    // return () => {
    //   if (socket) {
    //     socket.off('force_update_goals', handleForceUpdateGoals);
    //   }
    // };
  }, [dispatch, socket]);

  useEffect(() => {
    if (toggleDetailsMobile && isMobile()) {
      showDetails(toggleDetailsMobile);
    } else if (toggleDetailsMobile && !isMobile()) {
      setModalContent(<DetailsModal toggleDetailsMobile={toggleDetailsMobile} setToggleDetailsMobile={setToggleDetailsMobile} />);
    } else {
      restoreNormal();
    }
  }, [toggleDetailsMobile, setModalContent]);


  useEffect(() => {
    const fetchData = async () => {
      await dispatch(ThunkGetJobs());
      await dispatch(ThunkGetGoals())
    };
    fetchData();
  }, [dispatch,user]);

  useEffect(() => {
    if (newGroupMade){
    setModalContent(<LinkGoalsModal />)
    updateNewGroupMade(false)
      };
  }, [newGroupMade]);

  useEffect(() => {}, [user]);
  //

  // useEffect(() => {
  //   // Determine the container based on the platform
  //   const containerSelector = isMobile() ? '#lower3' : '#allCats2';
  //   const categoriesContainer = document.querySelector(containerSelector);
  //   const mobileHomeScrollbar = document.querySelector('.mobileHomeScrollbar');
  //   const scrollbarContainer = document.querySelector('.mobileHomeScrollbar-container');

  //   // Check if essential elements are present
  //   if (!categoriesContainer || !mobileHomeScrollbar || !scrollbarContainer) {
  //     console.log('Essential scrollbar elements not found!');
  //     return; // Exit if any essential element is not present
  //   }

  //   let isDown = false;
  //   let startX;
  //   let scrollLeft;

  //   const startDrag = (e) => {
  //     isDown = true;
  //     startX = (e.touches ? e.touches[0].pageX : e.pageX) - mobileHomeScrollbar.offsetLeft;
  //     scrollLeft = categoriesContainer.scrollLeft;
  //   };

  //   const endDrag = () => {
  //     isDown = false;
  //   };

  //   const doDrag = (e) => {
  //     if (!isDown) return;
  //     e.preventDefault();
  //     const x = (e.touches ? e.touches[0].pageX : e.pageX) - mobileHomeScrollbar.offsetLeft;
  //     const walk = (x - startX) * 1; // Adjust scroll speed as needed
  //     categoriesContainer.scrollLeft = scrollLeft + walk;
  //   };

  //   const handleScroll = () => {
  //     const scrollWidth = categoriesContainer.scrollWidth - categoriesContainer.clientWidth;
  //     const scrollFraction = categoriesContainer.scrollLeft / scrollWidth;
  //     const scrollbarWidthFraction = mobileHomeScrollbar.offsetWidth / scrollbarContainer.offsetWidth;
  //     const scrollbarPosition = scrollFraction * (scrollbarContainer.offsetWidth - mobileHomeScrollbar.offsetWidth);
  //     mobileHomeScrollbar.style.transform = `translateX(${scrollbarPosition}px)`;
  //   };

  //   // Attach event listeners for both touch and mouse
  //   mobileHomeScrollbar.addEventListener('touchstart', startDrag);
  //   mobileHomeScrollbar.addEventListener('mousedown', startDrag);
  //   document.addEventListener('touchend', endDrag);
  //   document.addEventListener('mouseup', endDrag);
  //   document.addEventListener('touchmove', doDrag);
  //   document.addEventListener('mousemove', doDrag);
  //   categoriesContainer.addEventListener('scroll', handleScroll);

  //   // Cleanup function to remove event listeners
  //   return () => {
  //     mobileHomeScrollbar.removeEventListener('touchstart', startDrag);
  //     mobileHomeScrollbar.removeEventListener('mousedown', startDrag);
  //     document.removeEventListener('touchend', endDrag);
  //     document.removeEventListener('mouseup', endDrag);
  //     document.removeEventListener('touchmove', doDrag);
  //     document.removeEventListener('mousemove', doDrag);
  //     categoriesContainer.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  // useEffect(() => {
  //   // Initialize the socket connection once when the component mounts

  //   // socketRef.current = io('wss://tracker-hog5.onrender.com', { transports: ['websocket'] });
  //   // socketRef.current = io('http://localhost:5000', { transports: ['websocket'] });

  //   // Cleanup function to disconnect the socket when the component unmounts
  //   return () => {
  //     if (socket) {
  //       socket.disconnect();
  //     }
  //   };
  // }, []); // Empty dependency array ensures this runs once on mount


  useEffect(() => {
    // Ensure the socket is connected and groupGoals.group_goal_id is defined before emitting
    if (socket && user) {
      socket.emit('join_get_jobs_room', { id: user.id});
    }
  }, [user,socket]);
  useEffect(() => {
    // Ensure the socket is connected and groupGoals.group_goal_id is defined before emitting
    if (socket && groupGoals?.group_goal_id) {
      socket.emit('join_group_room', { groupId: groupGoals?.group_goal_id });
    }
  }, [groupGoals,socket]); // Depend on groupGoals to re-emit when it changes


  useEffect(() => {
    // Function to handle the "self_destruct" event
    const handleSelfDestruct = (data) => {
      console.log('Received self destruct event for goal:', data);
      dispatch(actionDestroyGroup())
      // Handle the event, e.g., by updating the UI or dispatching a Redux action
    };

    if (socket) {
      // Set up the listener
      socket.on('self_destruct', handleSelfDestruct);
    }

    // Note: No cleanup function is provided. This listener will remain for the lifetime of the component.
  }, [socket]);

//   useEffect(() => {
//     console.log('**************************HITTING OUR RACE TO FINISH USEEFFECT')
//     const ourAsync2 =  () => {
//         console.log('**********************WE ARE INSIDE OUR USE EFFECT FOR GETTING LIVE INFORMATION.  ')
//         // const socket = socketRef.current; // Use the socketRef.current reference

//         // Ensure socket is connected
//         if (socket) {
//             console.log('*******************************WE SUCCESSFULLY SET OUR THINGER')
//             // Listen for 'goal_update' event
//             socket.on('goal_update', async (data) => {
//                 // Use 'data.user_id' to identify the user
//                 console.log('**********************************************CONGRATS ALEX WE HIT OUR SOCKET EMIT IN HERE DATA IS', data)
//                 if (data.user_id === user.id) {
//                     console.log('***********************we got a socket that we updated something.  we will do nothing because we should have a listener to our redux state here')
//                     // Update the current user's goal UI
//                 } else {
//                     console.log('*******************************************WE SENSED SOMEONE ELSE DO SOMETHING.')
//                     console.log('*************************************ARE WE GETTING THEIR USER INFO?', data.user_id)
//                     await dispatch(actionUpdateSpecificLive({ user_id: data.user_id, goal: data.goal }))
//                     // Update a friend's goal UI, assuming you have a function to check friendships by email
//                 }
//                 // Update UI accordingly
//             });

//             // Clean up function to close WebSocket connection on component unmount

//         }
//     };

//     ourAsync2();
// }, [dispatch, user]);

useEffect(() => {
  const setupSocketListeners = () => {
      socket.on('goal_update', (data) => {

          console.log('Goal update received:', data);
          if (data.user_id === user.id) {
            playSound('newGoal')
              console.log('Received a goal update for the current user.');
              // Handle update for the current user
          } else {
            playSound('friendsGoal')
              console.log('Received a goal update for another user.');
              // Handle update for other users
              dispatch(actionUpdateSpecificLive({user_id: data.user_id, goal: data.goal}));
          }
      });
  };

  if (socket) {
      setupSocketListeners();
  }

  // Clean up on component unmount or if socket changes
  return () => {
      if (socket) {
          socket.off('goal_update');
      }
  };
  // Include socket in the dependency array if it's expected to change
}, [dispatch, user,  socket,playSound]);


  useEffect(() => {
    // Function to handle the event when a new job is added
    const handleNewJobAdded = async (data) => {
      try {
        console.log('New job added:', data);
        const sendPackage = { id: data.job.id, job: data.job };
        await dispatch(makeJob(sendPackage)); // Assuming 'makeJob' is your Redux action creator
        await dispatch(ThunkGetJobs());
      } catch (error) {
        console.error('Error in dispatching Redux action:', error);
      }
    };

    // Only setup the listener if socketRef.current is initialized
    if (socket) {
      socket.on('new_job_added', handleNewJobAdded);
    }

    // Cleanup: Remove the event listener when the component unmounts or dependencies change
    return () => {
      if (socket) {
        socket.off('new_job_added', handleNewJobAdded);
      }
    };
  }, [dispatch]); //

  console.log('************************************** your group id thing is',groupGoals?.id)

  if (user) {

    // if (!isMobile()) return (
      if (raceView) return (
        <div id='newRaceWrapper'>
        <RaceToFinish />
        </div>
      )
      else if (!calendarView) return (
      <>

      <div id='homeWrapperMain' className='tryThisHome'>
      <Overlay isVisible={overlay} />
        <div id='wrapperMainCats'>

          {viewOfSite==='site'? (

            <div id="leftSection">
            {/* <img
            id='bgFile' src={`${process.env.PUBLIC_URL}/images/fileBgOutline2.png`}/> */}

  <div id="backgroundContainer">

      <img id='bgFile' src={`${process.env.PUBLIC_URL}/images/fileBgOutline2.png`} />
    </div>

          <div className='wrapperEachCatLeft1'>
            <div className='headerEachCat1 '>

              <div className='absFolderText'>
                Queue
              </div>
              {/* Queue */}
            <img
            id='firstTab' src={`${process.env.PUBLIC_URL}/images/PurpleTabLighter.png`}/>

            </div>
          <div id='wrapperNewJobsMain' className="wrapperNewJobs scrollable-without-scrollbar w-[100%]">
            {jobs.allJobs.length > 0 ? (
              <ul id="allOurJobsMain" ref={drop} className='scrollable-without-scrollbar'>
                {jobs.allJobs.map((job, index) => {
                  return (
                    <JobCard
                      key={job}
                      source={"Kwey"}
                      actual={actual}
                      job={job}
                      setToggleDetailsMobile={setToggleDetailsMobile}
                    />
                  );
                })}
              </ul>
            ) : (
              <p>No jobs found</p>
            )}
          </div>

          </div>


          <div className='wrapperEachCatLeft'>

            <Applied
              changeCardFromTo={changeCardFromTo}
              setToggleDetailsMobile={setToggleDetailsMobile}
              source="Standard"
            />
            <div id='trashCanHolder' ref={dropRef}>
            <img id='trashCan' src={`${process.env.PUBLIC_URL}/images/recycleBin.png`} />
            </div>
            </div>
            <div className='wrapperEachCatLeft'>
            <HighQualApplied
              changeCardFromTo={changeCardFromTo}
              setToggleDetailsMobile={setToggleDetailsMobile}
              source="High-Quality"
            />
            </div>



        </div>
          ): (
            <>{user?.first_time && (
              <>
              <div id='illusion'>
              <FirstLogin />
              </div>
              </>
            )}
            <CounterHome />
            </>
          )}

      <div id='wrapperRightz'>
        <div id='topBlockInfo'>


           <TopRightBrain selectedGoals={selectedGoals} goalType={goalType} setSelectedGoals={setSelectedGoals}/>


            <div id='selectorsTBINFO'>


              <div onClick={(e)=>{return 7;setSelectedSelector('Queue')}} className={`selectorsBro ${selectedSelector==='Queue'? 'selectedSelectorBro':''}`}>
                Queue
              </div>
              <div onClick={(e)=>{return 7;setSelectedSelector('Goals')}} className={`selectorsBro ${selectedSelector==='Goals'? 'selectedSelectorBro':''}`}>
                Goals
              </div>
              <div onClick={(e)=>{return 7;setSelectedSelector('GoalSubmit')}} className={`selectorsBro ${selectedSelector==='GoalSubmit'? 'selectedSelectorBro':''}`}>
                Submit Goal
              </div>
              <div onClick={(e)=>{return 7;setSelectedSelector('Offers')}} className={`selectorsBro ${selectedSelector==='Offers'? 'selectedSelectorBro':''}`}>
                Offers
              </div>



            </div>

        </div>
        {selectedSelector==='Queue'?(
      <div id='allCats2'>
        <div id='comingSoon'>
            Coming soon
             </div>
        <div id="backgroundContainer">



<img id='bgFile' src={`${process.env.PUBLIC_URL}/images/fileBgOutline2.png`} />
</div>
      <div className='wrapperEachCat2'>
          <WaitingApplied
            changeCardFromTo={changeCardFromTo}
            setToggleDetailsMobile={setToggleDetailsMobile}
            source="Waiting"
          />
        </div>
        <div className='wrapperEachCat2'>
          <Rejected
            changeCardFromTo={changeCardFromTo}
            setToggleDetailsMobile={setToggleDetailsMobile}
            source="Rejected"
          />
        </div>
        <div className='wrapperEachCat2'>
          <Next_Step
            changeCardFromTo={changeCardFromTo}
            setToggleDetailsMobile={setToggleDetailsMobile}
            source="Next_Step"
          />
        </div>

        {/* <div className='wrapperEachCat2'>
          <Offer
            changeCardFromTo={changeCardFromTo}
            setToggleDetailsMobile={setToggleDetailsMobile}
            source="Offer"
          />
        </div> */}

        </div>
        ): selectedSelector==='Goals'? (

          <div id='allCats2'>
            <div id="backgroundContainer">

<img id='bgFile' src={`${process.env.PUBLIC_URL}/images/fileBgOutline2.png`} />
</div>
      <div className='wrapperEachCat2'>
          <GoalApplied3
            changeGoalFromTo={changeGoalFromTo}
            setToggleDetailsMobile={setToggleDetailsMobile}
            source="Waiting"
            header={goalType.customGoal3Name}
            selectedGoals={selectedGoals}
          />
        </div>

        <div className='wrapperEachCat2'>
          <GoalApplied4
            changeGoalFromTo={changeGoalFromTo}
            setToggleDetailsMobile={setToggleDetailsMobile}
            source="Waiting"
            header={goalType.customGoal4Name}
            selectedGoals={selectedGoals}
          />
        </div>
        <div className='wrapperEachCat2'>
        <GoalApplied5
            changeGoalFromTo={changeGoalFromTo}
            setToggleDetailsMobile={setToggleDetailsMobile}
            source="Waiting"
            header={goalType.customGoal5Name}
            selectedGoals={selectedGoals}
          />
        </div>
        </div>
        ): selectedSelector==='GoalSubmit'? (

          <CompletedGoalSubmit selectedGoals={selectedGoals} goalType={goalType} />
        ): null}
        {/* <div class="mobileHomeScrollbar-container">
<div class="mobileHomeScrollbar"></div>
</div> */}
        </div>
      </div>
      </div>
    </>
    )
    else if (calendarView) return (
      <MyCalendar />
    )


    else if (isMobile()) return (
      <>

        <div id={"homeWrapper"}>
          <div id="onlyVisibleMobile">Click Any Header To Drag Job</div>
          <div id={"wrapperNewJobs"} className="wrapperNewJobs w-[50%]">
            {jobs.allJobs.length > 0 ? (
              <ul id="allOurJobs" ref={drop}>
                {jobs.allJobs.map((job, index) => {
                  return (
                    <JobCard
                      key={job}
                      source={"Kwey"}
                      actual={actual}
                      job={job}
                      setToggleDetailsMobile={setToggleDetailsMobile}
                    />
                  );
                })}
              </ul>
            ) : (
              <p>No jobs found</p>
            )}
          </div>

          <div id={"lower3"}>
          <div className='wrapperEachCat w-[50%] min-w-[50%] h-[100%]'>
            <Applied
              changeCardFromTo={changeCardFromTo}
              setToggleDetailsMobile={setToggleDetailsMobile}
              source="Standard"
            />
            </div>
            <div className='wrapperEachCat w-[50%] min-w-[50%] h-[100%]'>
            <HighQualApplied
              changeCardFromTo={changeCardFromTo}
              setToggleDetailsMobile={setToggleDetailsMobile}
              source="High-Quality"
            />
            </div>
            <div className='wrapperEachCat w-[50%] min-w-[50%] h-[100%]'>
            <WaitingApplied
              changeCardFromTo={changeCardFromTo}
              setToggleDetailsMobile={setToggleDetailsMobile}
              source="Waiting"
            />
            </div>
          </div>
          <div class="mobileHomeScrollbar-container">
  <div class="mobileHomeScrollbar"></div>
</div>
        </div>
      </>
    );
  } else {
    return (
      <div id='noUser'>
        <div id='absoluteFloatLEFT'>
          <div id='AFLL1'>
            <div className='AFLLBOY'>
            <OpenModalWithStyledButton
                buttonText="Log In"
                fontSize='3.5vh'
                modalComponent={<LoginFormModal />}
                modalStyle={{ width: '44%', height: '53%', overflow: 'auto', borderRadius: '2em' }}
              />
            </div>
            <div className='AFLLBOY'>
            <OpenModalWithStyledButton
                buttonText="Sign Up"
                fontSize='3.5vh'

                modalComponent={<SignupFormModal />}
              />
            </div>
          </div>
        </div>
        <img id='signupDog' src={`${process.env.PUBLIC_URL}/images/loginOrSignUp2.png`} />
        <div id='absoluteFloat'>
        <div id='absFloatZ'>
          <div id='makeBetterz'>This site is a social media experiment in sharing goals</div>
        <div id='absFloat2'>
          <p className='af2'>Job seeking is tough.  Going to the gym is tough.  Self employment is tough.</p>
          <p>Our Goal is to try and make it a little easier by socializing your goals </p>
          <p>With our app you can join groups and see how you are meeting your goals, and compare your progress with friends or strangers</p>
          <p>This app is about self-improvement, not competition.</p>
          </div>
        </div>
        <div id='absFloat1'>
          <div id='absFloat11'>
          <p>What data we request and how we use it:</p></div>
          <div id='absFloat3'>
            <div id='absFloatPink'>
            <p>Logging in with google is the preferred method.</p>
            <p>When you log in with google, we request three things:</p>
            <ul>
            <li>Your Email address</li>
            <li>Your profile (any information you've chosen to make public)</li>
            <li>Read and write access to your google calendar</li>
            </ul>
            </div>
            <div id='absFloatLemon'>
            <p>This last one Is so that you can view your google calendars and make events directly from our website.</p>
            <p>None of your personal calendar information is ever stored on our database- it simply hits redux and is sent to you</p>
            <p>Upon logging in here, you will also be logged into the domain hosting site, which is our less pretty url of:</p>
            <p>'https://tracker-hog5.onrender.com/'.</p>
            <p>If you wish to view our privacy policy, click <a className='standout' href='/Privacy'> Here</a></p>
            </div>
            <div id='absFloatOrange'>
            <p className='makeBold'>
              DISCLOSURE
            </p>
            <p className='makeBold'> “Seekers Friend use and transfer to any other app of information received from Google APIs will adhere to <a className='standout' href='https://developers.google.com/terms/api-services-user-data-policy'>Google API Services User Data Policy </a>, including the Limited Use requirements.” </p>
            </div>
          </div>
        </div>
        </div>
      </div>
    )
  }
}

export default Homepage;
