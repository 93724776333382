import React, { useState, useEffect } from "react";
import { login } from "../../store/session";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from "../../context/Modal";
import GoogleAuthButton from "../GoogleAuthButton";
import DOMPurify from "dompurify";
import { MakeButton, changeFromTo, changeGoalFromToUtil } from "../utils";
import { ThunkGetJobs, actionMoveJob } from "../../store/jobs";
import Applied from "../StandardApplied";
import isMobile from "is-mobile";
import io from 'socket.io-client';
import { makeJob } from "../../store/jobs";
import Rejected from "../Rejected";
import DetailsModal from "../showDetailsModal";
import Offer from "../Offer";
import GoalApplied3 from "../GoalApplied3";
import TopRightBrain from "../TopRightBrain";
import './raceTo.css'
// import Next_Step from "../Next_Step";

// import "./LoginForm.css";
// import "./homepage.css";
// import './mobileHomepage.css'

// import { showDetails, restoreNormal,showDetailsDesktop } from "./mobileScript";
import { useDrag, useDrop } from "react-dnd";
import JobCard from "../JobCard";
import { ThunkGetStandards } from "../../store/standardApps";
import HighQualApplied from "../HighQualApplied";
import { ThunkGetHighQuals } from "../../store/highqualApps";
import { ThunkGetWaitings } from "../../store/waiting";
import WaitingApplied from "../WaitingApplied";
import Next_Step from "../Next_Step";
import { ThunkGetFriendsLiveData, ThunkGetFriendsLiveDataSpecific, ThunkGetGoals, ThunkNewGoal,actionMessageReceived,actionUpdateSpecificLive } from "../../store/goals";
import CompletedGoalSubmit from "../CompletedGoalSubmit";
import GoalApplied4 from "../GoalApplied4";
import GoalApplied5 from "../GoalApplied5";
import EnemyForRace from "../EnemyForRace";
import './raceTo.css'
import ChatComponent from "../ChatComponent";
import { useSocket } from '../../context/SocketContext';
import RaceBarChart from "../RaceBarChart";




function RaceToFinish() {

    const sessionUser = useSelector(state => state.session.user);
    // const myFriendsTables = useSelector(state=>state.friends.friends)
    const friendsInfo = useSelector(state=>state.goals.live)
    const friendsActual = useSelector(state=>state.goals.live.by_id)
    const friendsList = useSelector(state=>state.goals.live.all_ids)
    const Group_goal = useSelector(state => state.goals.groupGoals);
    const [chatOrRace,setChatOrRace]= useState('Chat')
    const dispatch = useDispatch()
    const passIn= [sessionUser.id,...friendsList]
    const [currentIndex, setCurrentIndex] = useState(0);
    // const [chatOrRace,setChatOrRace]=useState('Chat')
    const socket= useSocket()

    const handleLeftChevronClick = () => {
        setCurrentIndex(prevIndex => Math.max(prevIndex - 1, 0));
    };

    const handleRightChevronClick = () => {
        setCurrentIndex(prevIndex => Math.min(prevIndex + 1, friendsList.length - 3));
    };
    useEffect(() => {
        const fetchFriendsLiveData = async () => {
            await dispatch(ThunkGetFriendsLiveData());
        };
        fetchFriendsLiveData();
    }, [dispatch]);

    useEffect(() => {
        console.log('**************************HITTING OUR RACE TO FINISH USEEFFECT')
        const ourAsync2 =  () => {
            console.log('**********************WE ARE INSIDE OUR USE EFFECT FOR GETTING LIVE INFORMATION.  ')
            // const socket = socketRef.current; // Use the socketRef.current reference

            // Ensure socket is connected
            if (socket) {
                console.log('*******************************WE SUCCESSFULLY SET OUR THINGER')
                // Listen for 'goal_update' event
                socket.on('goal_update', async (data) => {
                    // Use 'data.user_id' to identify the user
                    console.log('**********************************************CONGRATS ALEX WE HIT OUR SOCKET EMIT IN HERE DATA IS', data)
                    if (data.user_id === sessionUser.id) {
                        console.log('***********************we got a socket that we updated something.  we will do nothing because we should have a listener to our redux state here')
                        // Update the current user's goal UI
                    } else {
                        console.log('*******************************************WE SENSED SOMEONE ELSE DO SOMETHING.')
                        console.log('*************************************ARE WE GETTING THEIR USER INFO?', data.user_id)
                        await dispatch(actionUpdateSpecificLive({ user_id: data.user_id, goal: data.goal }))
                        // Update a friend's goal UI, assuming you have a function to check friendships by email
                    }
                    // Update UI accordingly
                });

                // Clean up function to close WebSocket connection on component unmount

            }
        };


        ourAsync2();
    }, [dispatch, sessionUser.id,socket]);

    const makeDupes=()=>{
        let amountNeeded
        if (friendsList.length<3){
            amountNeeded=3-friendsList.length
        }

        let arrayToMap= new Array(amountNeeded).fill(0)
        return arrayToMap
    }







    // useEffect(() => {

    //     console.log('**************************HITTING OUR RACE TO FINISH USEEFFECT')
    //     const ourAsync2 =  () => {
    //         console.log('**********************WE ARE INSIDE OUR USE EFFECT FOR GETTING LIVE INFORMATION.  ')
    //         // const socket = io("http://localhost:5000/goals", { transports: ['websocket'] })
    //         const socket = io('wss://tracker-hog5.onrender.com', { transports: ['websocket'] });
    //         console.log('*******************************WE SUCCESSFULLY SET OUR THINGER')
    //         // Replace 'your-server-url' with your actual server URL

    //         // Listen for 'new_job_added' event
    //         socket.on('goal_update', async (data)=> {
    //             // Use 'data.user_email' to identify the user
    //             console.log('**********************************************CONGRATS ALEX WE HIT OUR SOCKET EMIT IN HERE DATA IS',data)
    //             if (data.user_id === sessionUser.id) {
    //                 console.log('***********************we got a socket that we updated something.  we will do nothing b ecause we should have a listener to our redux state here')
    //                 // Update the current user's goal UI
    //             } else {
    //                 console.log('*******************************************WE SENSED SOMEONE ELSE DO SOMETHING.')
    //                 console.log('*************************************ARE WE GETTING THEIR USER INFO?',data.user_id)
    //                 dispatch(actionUpdateSpecificLive({user_id:data.user_id,goal:data.goal}))
    //                 //we have the stranger's goal and id lets update it directly through reducer
    //                 // Update a friend's goal UI, assuming you have a function to check friendships by email
    //             }
    //             // Update UI accordingly
    //         });


    //         // Clean up function to close WebSocket connection on component unmount
    //         return () => {
    //             socket.disconnect();
    //         };
    //     };

    //     ourAsync2();
    // }, [dispatch]);







    return (



        <>
        {friendsList && friendsList.length?(
        <div id='mainWrapperRace'>
            <div id='persOrGroup2'>
                <div id='smallerPersOrGroup2'>
                    <label id='toggleLabel2' className={`flex cursor-pointer select-none items-center `}>
                        <div id='toggleLeftSide2' className={chatOrRace === "Chat" ? 'personalToggle2' : 'notSelectedToggle2'}>
                            Chat
                        </div>

                        <div className='relative' id='daddyDiv2'>
                            <input
                                type='checkbox'
                                checked={chatOrRace === 'Chat'}
                                onChange={() => setChatOrRace(chatOrRace === "Chat" ? "Race" : "Chat")}
                                className='sr-only2'
                            />
                            <div id='toggleCenter2'
                                className={`box block h-20 w-14 rounded-full ${chatOrRace === "Race" ? 'bg-primaryz2' : 'bg-darkz2'}`}
                            ></div>
                            <div
                                id='ourMover2' className={`rounded-full bg-white transition ${chatOrRace === "Race" ? "translate-x-[135%]" : ''}`}
                            ></div>
                        </div>
                        <div id='toggleLeftSide2' className={chatOrRace === "Race" ? 'personalToggle2' : 'notSelectedToggle2'}>
                            Race
                        </div>
                    </label>
                </div>
            </div>
            {chatOrRace==='Chat' && (
                <>
                <div id='wrapperEnemies'>
                <div id="weLeft" onClick={handleLeftChevronClick}>
    <div id="chevronHolder">
        {friendsList.length>3 && (<img id="chevron" src={`${process.env.PUBLIC_URL}/images/chevron_left-matIcon.svg`} alt="Scroll Left" />)}
    </div>
</div>
                     <div id="weCenter">
    {friendsList.length > 0 && friendsList.slice(currentIndex, currentIndex + 3).map((ele) => (
        <div id="enemy3">
            <EnemyForRace Instance={friendsActual[ele]} />
        </div>
    ))}

    {friendsList.length < 3 && makeDupes().map((ele) => (
        <div id="enemy3">
            <div id="enemy3inner"></div>
            <div id="enemy3Lower">
                <div id="enemy3sweetSpot">Invite Others</div>
            </div>
        </div>
    ))}
</div>
<div id="weRight" onClick={handleRightChevronClick}> {/* Make sure you have weRight styled similarly to weLeft */}
    <div id="chevronHolder">
        {friendsList.length>3 && (<img id="chevron" src={`${process.env.PUBLIC_URL}/images/chevron_right-matIcon.svg`} alt="Scroll Right" />)}
    </div>
</div>
                </div>



        <div id='bottomHalf'>
            <div id='bh66'>
                {/* <div id='bh66Top'>
                <div className='h-full w-[50%] flex justify-center items-center'>
                <div onClick={(e)=>{setChatOrRace('Chat')}}className={chatOrRace==='Chat'?'buttonToClickSelected':'buttonToClick'}>
                    Chat
                </div>
                </div>
                <div className='h-full w-[50%] flex justify-center items-center'>
                <div onClick={(e)=>{setChatOrRace('Race')}}className={chatOrRace==='Race'?'buttonToClickSelected':'buttonToClick'}>
                    Race
                </div>
                </div>
                </div> */}
                <div id='bh66leftBottom'>
                <div className={chatOrRace === 'Chat' ? 'visible' : 'hidden'}>
              <ChatComponent />
            </div>
            <div className={chatOrRace === 'Race' ? 'visible' : 'hidden'}>
              <RaceBarChart />
            </div>
                </div>
            </div>
            <div id='ourStats'>
            <div id='enemy2'>
        <EnemyForRace Instance={Group_goal} />
        </div>
            </div>
        </div></>
    )}{chatOrRace==='Race' && (
        <div id='wrapperRace'>
        <div id='bh66leftBottom2'>
                <div className={chatOrRace === 'Chat' ? 'visible' : 'hidden'}>
              {chatOrRace==="Chat" && (<ChatComponent />)}
            </div>
            <div className={chatOrRace === 'Race' ? 'visible' : 'hidden'}>
              <RaceBarChart chatOrRace={chatOrRace}/>
            </div>
                </div>
                <div id='raceWrapperRight'>
                    <div id='rwr1'>
                        <ChatComponent />
                        </div>
                        <div id='rwr1'>
                        <EnemyForRace Instance={Group_goal} />
                            </div>
                    </div>
                </div>
    )}
        </div>):Group_goal?.id && Group_goal.id=== Group_goal?.group_goal_id?(
            <div id='mainWrapperSec'>
                <div id='secHeader'>
                    {Group_goal?.id === Group_goal?.group_goal_id? 'You are the only member in your group':"You are the only active member in this group"}
                </div>
                <div id="secMainz">
                    <div id='secMainzPretty'>
                    {Group_goal?.id === Group_goal?.group_goal_id? (<>
                        <div id='consolation'>First of all....don't feel bad.</div>
                        <div id='consolation2'>
                          <p>Job Hunting has historically been a lonely time for many individuals.</p>
                          <p>It's why this website exists.</p>
                          <p>As the owner of a group you have two options...</p>
                        </div>
                        <div id='consolation9'>
                        <div className='listULH5'>
                    <div className='ULHNUM5'>
                        1
                    </div>
                    <div className='wrapCAPS5'>
                    <div className='ULHTOP5 '>
                        Share your goal.
                    </div>
                    <div className='ULHBOT5'>
If you have friends who are also currently searching, give them your "share id" (Located in your dropdown menu) and give them the password you created.                    </div>
                    </div>
                  </div>
                  <div className='listULH5'>
                    <div className='ULHNUM5'>
                        2
                    </div>
                    <div className='wrapCAPS5'>
                    <div className='ULHTOP5 '>
                        Join another group.
                    </div>
                    <div className='ULHBOT5'>
If you know someone, join their group.<br/>Else, There are plenty of publically listed rooms for you to join!
                   </div>
                    </div>
                  </div>
                        </div>
                    </>):(<></>)}
                    </div>

                </div>
                <div id='secHeader'></div>

            </div>
        ): !Group_goal?.id?(<div id='mainWrapperSec'>
        <div id='secHeader'>
            You currently do not have group goals set!
            <div id='secHeaderSubText'>
                Or, the previous group you were in has been deleted.
            </div>
        </div>
        <div id="secMainz">
            <div id='secMainzPretty'>
            {Group_goal?.id === Group_goal?.group_goal_id? (<>
                <div id='consolation'>First of all....don't feel bad.</div>
                <div id='consolation2'>
                  <p>We know it's hard to get started.</p>
                  <p>It's why this website exists.</p>
                  <p>As of now you have two options:</p>
                </div>
                <div id='consolation9'>
                <div className='listULH5'>
            <div className='ULHNUM5'>
                1
            </div>
            <div className='wrapCAPS5'>
            <div className='ULHTOP5 '>
                "Set Group Goals" and then share them.
            </div>
            <div className='ULHBOT5'>
 If you have friends who are also currently searching, give them your "share id" (Located in your dropdown menu) and give them the password you created.                    </div>
            </div>
          </div>
          <div className='listULH5'>
            <div className='ULHNUM5'>
                2
            </div>
            <div className='wrapCAPS5'>
            <div className='ULHTOP5 '>
                Join another group.
            </div>
            <div className='ULHBOT5'>
If you know someone, join their group.<br/>Else, There are plenty of publically listed rooms for you to join!
           </div>
            </div>
          </div>
                </div>
            </>):(<></>)}
            </div>

        </div>
        <div id='secHeader'></div>

    </div>



        ):null}
        </>

    )

}

export default RaceToFinish
