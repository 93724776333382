
import React, { useState, useEffect } from "react";
import { login } from "../../store/session";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from "../../context/Modal";
import GoogleAuthButton from "../GoogleAuthButton";
import DOMPurify from "dompurify";
import { MakeButton, changeFromTo } from "../utils";
import { ThunkGetJobs, actionMoveJob } from "../../store/jobs";
import Applied from "../StandardApplied";
import isMobile from "is-mobile";
import io from 'socket.io-client';
import { makeJob } from "../../store/jobs";
import Rejected from "../Rejected";
import DetailsModal from "../showDetailsModal";
import Offer from "../Offer";
import GoalApplied3 from "../GoalApplied3";
// import Next_Step from "../Next_Step";

// import "./LoginForm.css";
import { ThunkGetAllGoalCards } from "../../store/goals";
import "../Homepage/homepage.css"
import '../Homepage/mobileHomepage.css'
import './enemyForRace.css'


function EnemyForRace({ selectedGoals, setSelectedGoals,Instance,exception }) {
    const getFormattedDateWithoutYear = (date) => {
        return `${date.getMonth() + 1}/${date.getDate()}`;
    }
    // const custSelector3 = useSelector((state) => state.goals?.[3]?.all_ids);
    // const custSelector4 = useSelector((state) => state.goals?.[4]?.all_ids);
    // const custSelector5 = useSelector((state) => state.goals?.[5]?.all_ids);
    // const personalGoals= useSelector((state) => state.goals.personalGoals);
    const competitorGoals= useSelector((state) => state.goals.groupGoals);
    const friendsList = useSelector(state=>state.goals.live.all_ids)
    // const goalType = selectedGoals === 'Personal' ? personalGoals : competitorGoals;
    const dispatch=useDispatch()
    // const custSelector3ById= custSelector3.by_id
    const getWidthAndColor = (current, total) => {
        const percentage = total > 0 ? (current / total) * 100 : 0; // Ensure no division by zero
        let color;

        if (percentage < 30) {
            color = 'red';
        } else if (percentage >= 30 && percentage < 55) {
            color = 'orange';
        } else if (percentage >= 55 && percentage < 85) {
            color = 'yellow';
        } else {
            color = 'green';
        }

        return { width: `${percentage}%`, backgroundColor: color };
    }
    // const getStartOfWeek = (date) => {
    //     const day = date.getDay();
    //     const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    //     return new Date(date.setDate(diff));
    // }
    // const startOfWeek = getStartOfWeek(new Date());
    // const endOfWeek = new Date(startOfWeek.getTime() + 6 * 24 * 60 * 60 * 1000);
    // const formattedWeekRange = `${getFormattedDateWithoutYear(startOfWeek)}-${getFormattedDateWithoutYear(endOfWeek)}`;

    // useEffect(() => {
    //     const fetchAll=async()=>{
    //         await dispatch(ThunkGetAllGoalCards(selectedGoals))
    //     }
    //     fetchAll()

    // }, [dispatch,selectedGoals]);

    useEffect(() => {
        // Logic to handle updates to friendData
        console.log("Friend data updated:", Instance);
    }, [Instance]);

    return (
    <div id='tbiAll3'>
        {/* <div id='tbiImage'>
            <img src={`${process.env.PUBLIC_URL}/images/waitingJob.gif`} alt="waiting office" />
        </div> */}
        <div id='tbiGoalsWrapper3'>
            {/* <div id='tbiGoalsSelection'>
                <div className={`tbiGoalsSelectionPersonal ${selectedGoals === 'Personal' ? 'selectedGoalHighlight' : ''}`} onClick={(e) => setSelectedGoals('Personal')}>
                    Personal
                </div>


                <div className={`tbiGoalsSelectionGroup ${selectedGoals === 'Group' ? 'selectedGoalHighlight' : ''}`} onClick={(e) => setSelectedGoals('Group')}>
                    Group
                </div>



            </div> */}



            <div id='tbiGoals'>
                {/* <div className='h-[20%] w-full border border-red-500 text-center text-1xl'>
                    {`Weekly Goals ${formattedWeekRange}`}
                </div> */}
                <div className='weeklyGoalsFit3'>
                    <div className='weeklyGoalsLabel'>Standard</div>
                    <div className='weeklyGoalsBar'>
                        <div className='floaterHealthBar'>
                            {`${Instance?.standard} / ${Instance?.standardLimit}`}
                        </div>
                        <div className='actualHealthBar' style={getWidthAndColor(Instance?.standard, Instance?.standardLimit)}>
                        </div>
                    </div>
                </div>
                <div className='weeklyGoalsFit3'>
                    <div className='weeklyGoalsLabel'>High-Qual</div>
                    <div className='weeklyGoalsBar'>
                        <div className='floaterHealthBar'>
                            {`${Instance.highQual} / ${Instance.highQualLimit}`}
                        </div>
                        <div className='actualHealthBar' style={getWidthAndColor(Instance?.highQual, Instance?.highQualLimit)}>
                        </div>
                    </div>
                </div>
                <div className='weeklyGoalsFit3'>
                    <div className='weeklyGoalsLabel'>{Instance.customGoal3Name}</div>
                    <div className='weeklyGoalsBar'>
                        <div className='floaterHealthBar'>
                            {`${Instance.customGoal3} / ${Instance.customGoal3Limit}`}
                        </div>
                        <div className='actualHealthBar' style={getWidthAndColor(Instance?.customGoal3, Instance?.customGoal3Limit)}>
                        </div>
                    </div>
                </div>
                <div className='weeklyGoalsFit3'>
                    <div className='weeklyGoalsLabel'>{Instance.customGoal4Name}</div>
                    <div className='weeklyGoalsBar'>
                        <div className='floaterHealthBar'>
                            {`${Instance.customGoal4} / ${Instance.customGoal4Limit}`}
                        </div>
                        <div className='actualHealthBar' style={getWidthAndColor(Instance?.customGoal4, Instance?.customGoal4Limit)}>
                        </div>
                    </div>
                </div>
                <div className='weeklyGoalsFit3'>
                    <div className='weeklyGoalsLabel'>{Instance.customGoal5Name}</div>
                    <div className='weeklyGoalsBar'>
                        <div className='floaterHealthBar'>
                            {`${Instance.customGoal5} / ${Instance.customGoal5Limit}`}
                        </div>
                        <div className='actualHealthBar' style={getWidthAndColor(Instance?.customGoal5, Instance?.customGoal5Limit)}>
                        </div>
                    </div>
                </div>
            </div>

            <div id='emailName'>
            {exception? exception: Instance?.username?Instance.username:'My Progress'}
        </div>
        </div>

    </div>)


}

export default EnemyForRace
