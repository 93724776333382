import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './calendar.css';
import { ThunkGetAllCalendars } from '../../store/calendar';
import { MakeButton } from '../utils';
import NewEvent from '../MakeEvent';
import { useModal } from "../../context/Modal";
import Overlay from '../Overlay';
import { useSharedSetters } from '../../context/SharedSetters';



const localizer = momentLocalizer(moment);

const MyCalendar = props => {
    const dispatch = useDispatch();
    const all_calendars = useSelector(state => state.calendars.all_calendars);
    const actual_selected_calendars= useSelector(state => state.calendars.selected_calendars);
    const [selected_calendars,setSelectedCalendars] = useState([])
    const calendar_events = useSelector(state => state.calendars.calendar_events);
    const name_to_id = useSelector(state => state.calendars.summary_to_id);
    const { setModalContent } = useModal();
    const {overlay,updateOverlay}=useSharedSetters()
    const sessionUser = useSelector(state => state.session.user);
    const [loading, setIsLoading]=useState(false)
    // Prepare events for the calendar
    const events = selected_calendars.reduce((acc, calendarName) => {
        const calendarEvents = calendar_events[calendarName] || [];
        return acc.concat(calendarEvents.map(event => ({
            ...event,
            start: new Date(event.start),
            end: new Date(event.end),
        })));
    }, []);

    useEffect(() => {
        setSelectedCalendars(actual_selected_calendars);
    }, [actual_selected_calendars]);

    // Fetch calendars on component mount
    useEffect(() => {
        const fetchCally = async () => {
            setIsLoading(true)
            await dispatch(ThunkGetAllCalendars());
            setIsLoading(false)
        };
        fetchCally();
    }, [dispatch]);

    // useEffect(() => {
    //     if (sessionUser.email && !sessionUser.email.includes('gmail.com')) {
    //         alert('Calendar only works with active gmail account. Please re-sign up using gmail to use this feature.');
    //     }
    // }, [sessionUser.email]);

    return (
        <div id='wrapperCalendar'>
            <Overlay isVisible={overlay} />
            {loading && (
                <img id='isLoading' src={`${process.env.PUBLIC_URL}/images/loading.gif`} />
            )}
            <div id='calendarLeftSide'>
            {sessionUser.email.includes('gmail.com')? (

<>
                <div id='availableCalendarsHeader'>Calendars</div>
                <div id='availableCalendars'>
    {all_calendars.length && all_calendars.map((calendar, index) => (
        <div
            key={index}
            onClick={(e) => {
                if (selected_calendars?.includes(calendar)) {
                    let newArray = selected_calendars.filter((ele) => ele !== calendar);
                    setSelectedCalendars(newArray);
                } else {
                    setSelectedCalendars([calendar, ...selected_calendars]);
                }
            }}
            className={selected_calendars?.includes(calendar) ? 'all_calleys_selected' : 'all_calleys'}
            style={all_calendars.length < 10 ? {height: `${100 / all_calendars.length}%`} : {}}
        >
            {calendar}
        </div>
    ))}
</div>
<div id='leftBottomCalendar'>
    <div className='lbc'>
    <MakeButton
                                                        onClick={(e)=>{ return 7}}
                                                        text='Make Calendar'
                                                        fontSize='1vw'
                                                    />
    </div>
    <div className='lbc'>
    <MakeButton
                                                        onClick={(e)=>{
                                                            setModalContent(<NewEvent  all_calendars={all_calendars} name_to_id={name_to_id}/>)
                                                        }}
                                                        text='Make Event'
                                                        fontSize='1.2vw'
                                                    />
    </div>
</div>
</>

            ): (<>

            <div id='needGmail'>
                <div id='needGmail1'>
            <h1>You need a gmail account</h1>
            <p>In order to use our calendar,</p>
            <p>A gmail account is required</p>
            <p>and must be tied to the login</p>
            </div>
            </div>

            </>)}
            </div>
            <div id='calendarRightSide'>
                <div id='calendarWrapper'>
                    <Calendar
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        // Removed min and max to show the entire month
                        // style={{ height: 700 }} // Adjust height as needed
                    />
                </div>
            </div>
        </div>
    );
};

export default MyCalendar;
