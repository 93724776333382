import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import session from './session'
import jobs from './jobs'
import standards from './standardApps'
import highQuals from './highqualApps'
import waitings from './waiting'
import rejecteds from './rejected'
import next_steps from './next_step'
import offers from './offer'
import goals from './goals'
import calendars from './calendar'
const rootReducer = combineReducers({
  session,
  jobs,
  standards,
  highQuals,
  waitings,
  rejecteds,
  next_steps,
  offers,
  goals,
  calendars
});



let enhancer;

if (process.env.NODE_ENV === 'production') {
  enhancer = applyMiddleware(thunk);
} else {
  const logger = require('redux-logger').default;
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  enhancer = composeEnhancers(applyMiddleware(thunk, logger));
}

const configureStore = (preloadedState) => {
  return createStore(rootReducer, preloadedState, enhancer);
};

export default configureStore;
