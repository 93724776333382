import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDrop } from 'react-dnd';
import { MakeButton } from "../utils";
import AppliedCard from "../AppliedCard";
import isMobile from "is-mobile";
import { ThunkGetStandards } from "../../store/standardApps";

const Applied = ({ setToggleDetailsMobile, changeCardFromTo }) => {
    const appliedJobs = useSelector(state => state.standards);
    const [update, setUpdate] = useState(false);
    const dispatch = useDispatch();
    const actual = useSelector(state => state.standards.standards);
    const [, dropRef] = useDrop({
        accept: 'JOB_CARD',
        drop: async (item, monitor) => {
            await changeCardFromTo(item, 'Standard');
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    });

    useEffect(() => {

    }, [update]);

    useEffect(() => {
        const fetchData = async () => {
          await dispatch(ThunkGetStandards());

        };
        fetchData();
      }, [dispatch]);

    return (
        <>
            <div className='headerEachCat'>
            <div className='absFolderText'>
              Standard
            </div>
                <div id={isMobile() ? 'hone' : 'honeMain'}>

                    {/* Standard */}
                    <img
          id='secondTab' src={`${process.env.PUBLIC_URL}/images/BlueTabLighter2.png`}/>

                </div>
            </div>
            <div id='wrapperNewJobsShortened' className='scrollable-without-scrollbar'>
                <div ref={dropRef} className="drop-area">
                    {appliedJobs.allStandards.length > 0 ? (
                        <ul id='allOurJobs2'>
                            {appliedJobs.allStandards.map((job, index) => (
                                <AppliedCard key={job} actual={actual} job={job} setToggleDetailsMobile={setToggleDetailsMobile} source='Standard' />
                            ))}
                        </ul>
                    ) : (
                        <p>No jobs found</p>
                    )}
                </div>
            </div>
        </>
    );
};

export default Applied;
