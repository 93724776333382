import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDrop } from 'react-dnd';
import { MakeButton } from "../utils";
import AppliedCard from "../AppliedCard";
import isMobile from "is-mobile";
import MakeWaiting from "../MakeWaiting";
import { ThunkGetWaitings } from "../../store/waiting";
import { useModal } from '../../context/Modal';


const WaitingApplied = ({ setToggleDetailsMobile, changeCardFromTo }) => {
    const waitingAppliedJobs = useSelector(state => state.waitings);
    const [update, setUpdate] = useState(false);
    const dispatch = useDispatch();
    const { setModalContent } = useModal();
    const actual = useSelector(state => state.waitings.waitings);
    const [, dropRef] = useDrop({
        accept: 'JOB_CARD',
        drop: async (item, monitor) => {
            await changeCardFromTo(item, 'Waiting');
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    });

    useEffect(() => {
        const fetchData = async () => {
          await dispatch(ThunkGetWaitings());
        };
        fetchData();
      }, [dispatch]);

    useEffect(() => {

    }, [update]);

    console.log('INSIDE OF WAITING APPLIED.  IN HERE APPLIED HIGHQUALJOBS IS', waitingAppliedJobs);

    return (
        <>
            <div className='headerEachCat7' onClick={(e)=>{
                 setModalContent(<MakeWaiting setModalContent={setModalContent} modalStyle={{ width: '44%', height: '60%', overflow: 'auto', borderRadius: '2em' }}/>)
            }}style={{ cursor: 'pointer',color: 'purple' }}>
                <div id={isMobile() ? 'hone' : 'honeMain'}   >

                    Waiting

                    </div>
            </div>
            <div id='wrapperNewJobs2' className='scrollable-without-scrollbar'>
                <div ref={dropRef} className="drop-area">
                    {waitingAppliedJobs.all_waiting.length > 0 ? (
                        <ul id='allOurJobs2'>
                            {waitingAppliedJobs.all_waiting.map((job, index) => (
                                <AppliedCard key={job} actual={actual} job={job} setToggleDetailsMobile={setToggleDetailsMobile} source='Waiting' />
                            ))}
                        </ul>
                    ) : (
                        <p>No jobs found</p>
                    )}
                </div>
            </div>
        </>
    );
};

export default WaitingApplied;
