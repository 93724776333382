
import React, { useState, useEffect } from "react";
import { login } from "../../store/session";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from "../../context/Modal";
import GoogleAuthButton from '../GoogleAuthButton'
import DOMPurify from 'dompurify';
import { MakeButton } from "../utils";
import { ThunkGetJobs } from "../../store/jobs";


import '../Homepage/homepage.css'

import { useDrag } from "react-dnd";



function JobCard({actual,job,setToggleDetailsMobile,source}) {


    const [{ isDragging }, dragRef] = useDrag(() => ({
        type: 'JOB_CARD',
        item: { id: job, target:'JOB_CARD',source },
        end: (item, monitor) => {
            const didDrop = monitor.didDrop();
            // console.log(`Drop result for job ID ${item.id}:`, didDrop ? 'Dropped over a target' : 'Dropped outside any target');
        if (!didDrop) {
            return
        }
        },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));

    const cleanHtml = DOMPurify.sanitize(actual[job]?.['description'])





    return (

<div ref={dragRef} key={job}  className={`newJobCard newJobCard-${job} ${isDragging ? 'dragging' : ''}`} onDoubleClick={(e) => {
         e.preventDefault();
         setToggleDetailsMobile(actual[job]);
     }}>
                                        {/* <li>Ident: {displayRelative(job)}</li> */}
                                        <li className='titleJob'> {actual[job]?.['title']}</li>
                                        <li className='fifteenLi'>
                                            <div className='companyName'>
                                                <div className='companyName1'>Company:</div> <div className='companyName2'>{actual[job]?.['companyName']}</div>
                                            </div></li>
                                        <li className='fifteenLi2'><div className='payClass'>

                                            <div id='top1payclass'>
                                                Pay
                                            </div>
                                            <div id='bott1payclass'>



                                            {actual[job]?.['pay']}</div></div></li>
                                        {/* <li className='fortyLi'>
                                            <div className='wrapperButtonz'>
                                                <div className='wrapperButtonz1'>
                                                    <MakeButton
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            window.open(actual[job]['link'], '_blank', 'noopener,noreferrer');
                                                        }}
                                                        text='Apply'
                                                        fontSize='1vw'
                                                    />

                                                </div>
                                                <div className='wrapperButtonz2'>


                                                    <MakeButton
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setToggleDetailsMobile(actual[job])
                                                        }}
                                                        text='Details'
                                                        fontSize='1vw'
                                                    />




                                                </div></div>

                                                </li> */}
                                        <div className={`detailsNew detailsNew-${job}`}>
                                            <div className={`pretty1`} dangerouslySetInnerHTML={{ __html: cleanHtml }}>
                                            </div>
                                            <div className='buttonsClose'>
                                                <div className='buttonsClose1'>
                                                    <MakeButton
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setToggleDetailsMobile(false)
                                                        }}
                                                        text='Close'
                                                    /></div>
                                            </div>
                                        </div>
                                    </div>
                                )
                                                    }

                                                    export default JobCard
