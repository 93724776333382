const SET_CALENDAR = `calendars/SET_CALENDAR`;
const ADD_CALENDAR = `calendars/ADD_CALENDAR`;
const REMOVE_CALENDAR = `calendars/REMOVE_CALENDAR`;
const GET_ALL_CALENDARS = 'calendars/GET_ALL_CALENDARS'
const MAKE_NEW_EVENT = 'calendars/MAKE_NEW_EVENT'
const MAKE_NEW_EVENT_REDUX = 'calendars/MAKE_NEW_EVENT_REDUX'
const CLEAR_CALENDAR = 'calendars/CLEAR_CALENDAR';


// Action creators
export const clearCalendar = () => ({
    type: CLEAR_CALENDAR,
});
export const setCalendar = () => {
    return {
        type: SET_CALENDAR,
        payload: {},
    };
};

export const addCalendar = () => {
    return {
        type: ADD_CALENDAR,
        payload: {},
    };
};

export const removeCalendar = () => {
    return {
        type: REMOVE_CALENDAR,
        payload: {},
    };
};

export const actionGetAllCalendars = (data) => {
    return {
        type: GET_ALL_CALENDARS,
        payload: data,
    };
};
export const actionMakeNewEvent = (data) => {
    return {
        type: MAKE_NEW_EVENT,
        payload: data,
    };
};

export const actionMakeNewEventLocalRedux = (data) => {
    console.log('INSIDE OUR ACTION MAKE EVENT LOCAL')
    console.log('inside here data is',data.reduxEvent)
    console.log('inside here selected calendar event is',data.selectedCalendar)
    return {
        type: MAKE_NEW_EVENT_REDUX,
        payload: data,
    };
};

export const ThunkNewCalendarEvent = (event) => async (dispatch) => {
    try {
        const response = await fetch('/api/google_auth/make-new-event', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(event)
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();

        // Assuming you have an action creator similar to actionGetGoalsNumber for handling all goal cards
        dispatch(actionMakeNewEvent(data)); // Make sure to define actionGetAllGoalCards action creator
    } catch (error) {
        console.error("Error fetching all goal cards:", error);
    }
};

export const ThunkGetAllCalendars = (selectedGoals) => async (dispatch) => {
    try {
        const response = await fetch('/api/google_auth/list-calendars2', {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();

        // Assuming you have an action creator similar to actionGetGoalsNumber for handling all goal cards
        dispatch(actionGetAllCalendars(data)); // Make sure to define actionGetAllGoalCards action creator
    } catch (error) {
        console.error("Error fetching all goal cards:", error);
    }
};

const initialState = { all_calendars: [], selected_calendars:[],calendar_events: {},summary_to_id: {},id_to_summary:{} };

// Reducer
export default function reducer(state = initialState, action) {
    let newState={...state}
    switch (action.type) {

        case MAKE_NEW_EVENT:
            let {reduxEvent,selectedCalendar}=action.payload
            let tempNew=[...newState.calendar_events[selectedCalendar]]
            tempNew.push(reduxEvent)
            newState.calendar_events[selectedCalendar]=tempNew
            return newState

        case GET_ALL_CALENDARS:
            newState.all_calendars=action.payload.calendars
            newState.selected_calendars=action.payload.selected_calendars
            newState.calendar_events= action.payload.calendar_events
            newState.summary_to_id = action.payload.summary_to_id
            newState.id_to_summary = action.payload.id_to_summary
            return newState
        case SET_CALENDAR:
            return { ...state };

        case ADD_CALENDAR:
            return { ...state };

        case REMOVE_CALENDAR:
            return { ...state };
        case CLEAR_CALENDAR:
            return { ...initialState };
        default:
            return state;
    }
}
