import React, { useState, useEffect,useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Assume you have some actions defined somewhere in your application, e.g., actions.js or a Redux slice
import ChartRace from 'react-chart-race';
import './raceBarChart.css'
const RaceBarChart = ({chatOrRace}) => {
  // Local state management
  const [localState, setLocalState] = useState('');
  const [containerWidth, setContainerWidth] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);
  const innerChartWrapperRef = useRef(null);

  // Accessing the Redux store
  const friendsInfo = useSelector(state=>state.goals.live)
  const friendsActual = useSelector(state=>state.goals.live.by_id)
  const friendsList = useSelector(state=>state.goals.live.all_ids)
  const Group_goal = useSelector(state => state.goals.groupGoals);
  const numDivideBy= friendsList.length+2

  // Dispatching actions
  const dispatch = useDispatch();

  const funcDIH=()=>{
    const ourReply=containerHeight/numDivideBy

    const ourReplyWithPadding= Math.floor(ourReply*.85)

    return ourReplyWithPadding
  }

  function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  useEffect(() => {
    const updateDimensions = debounce(() => {
      if (chatOrRace === 'Race' && innerChartWrapperRef.current) {
        setContainerWidth(innerChartWrapperRef.current.offsetWidth);
        setContainerHeight(innerChartWrapperRef.current.offsetHeight);
      }
    }, 100); // Debounce with a 100ms delay

    const resizeObserver = new ResizeObserver(() => {
      updateDimensions();
    });

    if (innerChartWrapperRef.current) {
      resizeObserver.observe(innerChartWrapperRef.current);
    }

    window.addEventListener('resize', updateDimensions);

    return () => {
      resizeObserver.disconnect();
      window.removeEventListener('resize', updateDimensions);
    };
  }, [chatOrRace]);
  useEffect(()=>{

},[Group_goal])
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Dispatch an action to fetch data when the component mounts
    // dispatch(fetchData()); // Uncomment and use actual action
  }, [dispatch]); // Dependencies array, ensures effect runs only once on mount

  // Handler for local state, e.g., input field changes
  const handleInputChange = (e) => {
    setLocalState(e.target.value);
  };

  const ETG=(goals)=>{
    const total=goals.customGoal3+goals.customGoal4+goals.customGoal5+goals.standard+goals.highQual
    return total
  }
  const ETGTOTALS=(goals)=>{
    const total=goals.customGoal3Limit+goals.customGoal4Limit+goals.customGoal5Limit+goals.standardLimit+goals.highQualLimit
    return total
  }

  // Handler for dispatching an action, e.g., on button click
  const handleSubmit = () => {
    // Dispatch an action with the local state as payload
    // dispatch(yourAction(localState)); // Uncomment and use actual action and payload
    setLocalState(''); // Reset local state
  };

  const chartData = [
  { id: 'finish', title: "Finish Line", value: ETGTOTALS(Group_goal), color: 'red' },
    { id: 'me', title: "Me", value: ETG(Group_goal), color: '#3fc42d' }, // Example static entry for "Me"
    ...friendsList.map((friendId, index) => ({
      id: friendId,
      title: friendsActual[friendId]?.username || `Friend ${index + 1}`,
      value: ETG(friendsActual[friendId]), // Replace with actual value
      color: `#${Math.floor(Math.random()*16777215).toString(16)}`, // Random color for demonstration
      width: ETGTOTALS(Group_goal)
    }))
  ];

  console.log('CONTAINER  WIDTH IS',containerWidth)

  return (
    <div id='chartWrapper'>
      {/* <h1>Example Component</h1> */}
      {/* Display data from the Redux store */}
      <div id='innerChartWrapper' ref={innerChartWrapperRef}>

      <ChartRace data={chartData} width={containerWidth} backgroundColor="#fff" padding={12} itemHeight={funcDIH()} gap={4} />
      </div>


    </div>
  );
};

export default RaceBarChart;
