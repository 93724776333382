import { makeStandard, actionMoveStandard } from "../store/standardApps";
import { actionMoveJob, makeJob } from "../store/jobs";
import { makeHighQual, actionMoveHighQual } from "../store/highqualApps";
import { actionMoveWaiting, makeWaiting } from "../store/waiting";
import { actionMoveRejected, makeRejected } from "../store/rejected";
import { actionMoveNext_Step, makeNext_Step } from "../store/next_step";
import { actionMoveOffer, makeOffer } from "../store/offer";
import { actionUpdateGoals,actionSetGoalFromTo, actionDeleteSingleGoal } from "../store/goals";


export const MakeButton = ({ type = "button", onClick, text, buttonId,fontSize }) => {
  let imageSrc = `${process.env.PUBLIC_URL}/images/Blue-Button.png`;
  return (
    <button
      type={type}
      className="realButtonz"
      onClick={onClick}
      {...(buttonId && { id: buttonId })} // Conditionally apply the id attribute
    >
      <img
        src={imageSrc}
        alt={text}
        className="object-contain w-full h-[auto]" // Adjust the width and height as needed
      />
      {text === "loading" ? (
        <img
          src={`${process.env.PUBLIC_URL}/images/loading.gif`}
          className="absolute"
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            fontSize: fontSize ? fontSize : '3.5vw'
          }}
        />
      ) : (
        <p className="buttonMaster" style={{ fontSize: fontSize }}>{text}</p>
      )}
    </button>
  );
};

//IMPORTANT.  WE HAVE TO ADD TO THIS AS OUR MODELS GROW
const dictionaryActionsMain = {
  //always make then move order.
  "Standard": [makeStandard, actionMoveStandard],
  "Kwey": [makeJob, actionMoveJob],
  "High-Quality": [makeHighQual, actionMoveHighQual],
  "Waiting": [makeWaiting, actionMoveWaiting],
  "Rejected": [makeRejected,actionMoveRejected],
  'Next_Step': [makeNext_Step,actionMoveNext_Step],
  'Offer': [makeOffer,actionMoveOffer]
};

export const changeFromTo = async (dispatch, ourId, base, transform) => {
  console.log("IN CHANGE FROM TO BASE IS", base);
  console.log("IN CHANGE FROM TO IN UTILS TRANSFORM IS", transform);
  const response = await fetch("/api/jobs/set-from-to", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ jobId: ourId, base: base, transform: transform }),
  })
    .then((res) => res.json())
    .then(async (data) => {
      console.log('WE ARE IN OUR UTILZ ALEX*******************DATA.TARGET IT',data.target)

      if (data.delete){
        await dispatch(dictionaryActionsMain[base][1](data.target));
        dispatch(actionUpdateGoals(data.goals))
      }
      else {
      await dispatch(dictionaryActionsMain[base][1](data.target));

      await dispatch(dictionaryActionsMain[transform][0](data.target))
      dispatch(actionUpdateGoals(data.goals))}



    })

    .catch((error) => {
      console.error("Error fetching data:", error);
    });
  return response;
};


export const changeGoalFromToUtil = async (dispatch, ourId, base, transform,selectedGoals) => {
  console.log("IN CHANGE FROM TO BASE IS", base);
  console.log("IN CHANGE FROM TO IN UTILS TRANSFORM IS", transform);
  const response = await fetch("/api/goals/set-goal-from-to", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ jobId: ourId, base: base, transform: transform,selectedGoals:selectedGoals }),
  })
    .then((res) => res.json())
    .then(async (data) => {
      // await dispatch(actionSetGoalFromTo(data));

      // await dispatch(dictionaryActionsMain[transform][0](data.target));


      if (data.delete){
        await dispatch(actionDeleteSingleGoal(data));
        dispatch(actionUpdateGoals(data.goals))
      }
      else {
      await dispatch(actionSetGoalFromTo(data))
      dispatch(actionUpdateGoals(data.goals))}

    })







    .catch((error) => {
      console.error("Error fetching data:", error);
    });
  return response;
};
